/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from 'react';

import { useQueryParams } from '@lumapps/router';

import { sanitizeHTML } from '../string/sanitizeHtml';

interface UseInjectHTMLWithJSOptions {
    html?: string | null;
}

export function sanitizeHTMLWithDebugScriptsAndStyles(dirty?: string) {
    return sanitizeHTML(dirty, {
        // Disable dangerous HTML elements and attributes
        useProfiles: { html: true },
        // Also disable <style> elements
        forbidTags: ['style'],
        // Show <script> & <style> as <pre><code>
        debugScriptsAndStyles: true,
    });
}

/**
 * Returns a element ref that should be added to the component that will hold the HTML with JS.
 * IMPORTANT!! Please use this method only if you know what you are doing since this can
 * potentially create a security breach. Normally you should not need to do this.
 *
 * Using ?safe in the URL will sanitize the HTML.
 */
export const useInjectHTMLWithJS = ({ html }: UseInjectHTMLWithJSOptions) => {
    const ref = useRef<HTMLElement>(null);
    const [isInjected, setIsInjected] = useState(false);
    const queryParams = useQueryParams();

    /**
     * HEAD'S UP! This component does not use dangerouslySetInnerHTML because if we use that, any JS code added
     * to the HTML will not be executed. This is due to how the browser works when changing the innerHTML of an element.
     * JS is added, it will be on the DOM, but it won't be executed. That is why we are using a useEffect that
     * creates a fragment where the code can be executed and then adds it to the element.
     *
     */
    useEffect(() => {
        const { current: element } = ref;
        if (!html || !element) {
            return;
        }

        let fragment: any = html;
        // ?safe is enabled
        if (queryParams.safe) {
            // Sanitize basic HTML tags + removing `style` tags.
            fragment = sanitizeHTMLWithDebugScriptsAndStyles(fragment);
        }

        // Create a 'tiny' document and parse the html string.
        const slotHtml = document.createRange().createContextualFragment(fragment);
        const scripts = slotHtml.querySelectorAll('script');

        if (scripts.length > 0) {
            scripts.forEach((script) => {
                // Move the script into a blob URL to better identify errors coming from them
                const blob = new Blob([script.innerHTML], { type: 'text/javascript' });
                script.src = URL.createObjectURL(blob);
                script.innerHTML = '';
            });
        }
        // Clear the container.
        element.innerHTML = '';
        // Append the new content.
        element.appendChild(slotHtml);
        setIsInjected(true);
    }, [html, queryParams.safe]);

    return { ref, isInjected };
};
