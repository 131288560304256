export enum ROLES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244404
     */
    ROLES = 'ADMIN.NAV.ROLES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452340
     */
    AUTHORIZATIONS = 'ADMIN_ROLE_TAB_AUTHORIZATIONS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452334
     */
    DELETE_ROLE = 'ADMIN_ROLE_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452336
     */
    DELETE_ROLE_SUCCESS = 'ADMIN_ROLE_DELETE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452339
     */
    ROLE_SAVE_SUCCESS = 'ADMIN_ROLE_SAVE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452330
     */
    CAN_EDIT_ONLY_OWN_CONTENT = 'ADMIN_ROLE_AUTHORIZATION_CAN_EDIT_ONLY_OWN_CONTENT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452333
     */
    NAVIGATION_ITEMS = 'ADMIN_ROLE_AUTHORIZATION_NAVIGATION_PICKER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=549186567
     */
    NEW_AUTHORIZATION = 'ROLES.NEW_AUTHORIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=549186563
     */
    NO_AUTHORIZATIONS_HELPER = 'ROLES.NO_AUTHORIZATIONS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=549186556
     */
    NO_AUTHORIZATIONS = 'ROLES.NO_AUTHORIZATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=550859839
     */
    DELETE_AUTHORIZATION = 'ROLES.DELETE_AUTHORIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=549495538
     */
    NAVIGATION_PARTIAL_EDIT_HELPER = 'ROLES.NAVIGATION_PARTIAL_EDIT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=553869291
     */
    NO_NAV_YET = 'ROLES.NO_NAV_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=554992950
     */
    ADD_RESTRICTIONS = 'ROLES.ADD_RESTRICTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=555015271
     */
    USER_CONTENT_ONLY = 'ROLES.USER_CONTENT_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=556383923
     */
    NO_RESTRICTIONS_YET = 'ROLES.NO_RESTRICTIONS_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=556385196
     */
    RESTRICTIONS_HELPER = 'ROLES.RESTRICTIONS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561837296
     */
    DELETE_CONFIRMATION_MESSAGE = 'ROLES.DELETE_CONFIRMATION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561839259
     */
    ASSIGN_ROLE_TO_GROUPS = 'ROLES.ASSIGN_ROLE_TO_GROUPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561859485
     */
    ASSIGN_ROLES_DESCRIPTION = 'ROLES.ASSIGN_ROLES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561860805
     */
    AUTHORIZATIONS_HELPER = 'ROLES.AUTHORIZATIONS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=548207148
     */
    CONTRIBUTION_MODE = 'ADMIN_ROLE_AUTHORIZATION.CONTRIBUTION_MODE_SECTION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=548207054
     */
    WRITER = 'ADMIN_ROLE_AUTHORIZATION.WRITER_MODE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=548207090
     */
    WRITER_MODE_DESCRIPTION = 'ADMIN_ROLE_AUTHORIZATION.WRITER_MODE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=548212951
     */
    DESIGNER = 'ADMIN_ROLE_AUTHORIZATION.DESIGNER_MODE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=548212515
     */
    DESIGNER_MODE_DESCRIPTION = 'ADMIN_ROLE_AUTHORIZATION.DESIGNER_MODE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561943276
     */
    CONTRIBUTION_MODE_DESCRIPTION = 'ROLES.CONTRIBUTION_MODE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561960348
     */
    EDIT_NAVIGATION_ITEMS = 'ROLES.EDIT_NAVIGATION_ITEMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561961646
     */
    EDIT_RESTRICTIONS = 'ROLES.EDIT_RESTRICTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561964332
     */
    OWNERSHIP_DESCRIPTION = 'ROLES.OWNERSHIP_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561970719
     */
    VISIBILITY_DESCRIPTION = 'ROLES.VISIBILITY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561970962
     */
    METADATA_DESCRIPTION = 'ROLES.METADATA_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561990193
     */
    NEW_ROLE = 'ROLES.NEW_ROLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561990789
     */
    NO_ROLES_YET = 'ROLES.NO_ROLES_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561990801
     */
    EMPTY_ROLES_DESCRIPTION = 'ROLES.EMPTY_ROLES_DESCRIPTION',
}
