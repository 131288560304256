export enum EVENTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109549609
     */
    DELETED = 'EVENTS.DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101357845
     * TODO: rename as "DELETE_EVENT" ?
     */
    DELETE_CONFIRM_DIALOG_TITLE = 'FRONT.EVENTS.DELETE.CONFIRM_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104736198
     */
    DELETE_CONFIRM_DIALOG_CANCEL = 'FRONT.EVENTS.DELETE.CONFIRM_DIALOG.CANCEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104736714
     * TODO: rename as "DELETE_EVENT"?
     */
    DELETE_CONFIRM_DIALOG_OK = 'FRONT.EVENTS.DELETE.CONFIRM_DIALOG.OK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101357851
     */
    DELETE_CONFIRM_DIALOG_DESCRIPTION = 'FRONT.EVENTS.DELETE.CONFIRM_DIALOG.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101357889
     */
    DELETE_SUCCESS_NOTIFICATION = 'FRONT.EVENTS.DELETE.SUCCESS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226682837
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226685168
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_HELPER = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231652920
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_HELPER_WITHOUT_NB = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_HELPER_WITHOUT_NB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226692744
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_LABEL = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226693814
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_PLACEHOLDER = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231654120
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_PLACEHOLDER_WITHOUT_NB = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_PLACEHOLDER_WITHOUT_NB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226855622
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_CAPACITY_ERROR = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_CAPACITY_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232018574
     */
    DIALOG_CAPACITY_LIMITED_ATTENDEES_MIN_CAPACITY_ERROR = 'EVENTS.DIALOG_CAPACITY_LIMITED_ATTENDEES_MIN_CAPACITY_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226682676
     */
    DIALOG_CAPACITY_UNLIMITED_ATTENDEES = 'EVENTS.DIALOG_CAPACITY_UNLIMITED_ATTENDEES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98447009
     */
    DIALOG_CONTENT_PLACEHOLDER = 'FRONT.EVENT.DIALOG.CONTENT_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96015256
     */
    DIALOG_TITLE_PLACEHOLDER = 'FRONT.EVENT.DIALOG.TITLE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97648163
     */
    DIALOG_LOCATION_LABEL = 'FRONT.EVENT.DIALOG.LOCATION_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97648517
     */
    DIALOG_LOCATION_PLACEHOLDER = 'FRONT.EVENT.DIALOG.LOCATION_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142766243
     */
    DIALOG_LINK = 'FRONT.EVENT.DIALOG.LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97648615
     */
    DIALOG_LINK_LABEL = 'FRONT.EVENT.DIALOG.LINK_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97648844
     */
    DIALOG_LINK_PLACEHOLDER = 'FRONT.EVENT.DIALOG.LINK_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142766327
     */
    DIALOG_LINK_LABEL_PLACEHOLDER = 'FRONT.EVENT.DIALOG.LINK_LABEL_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=201055718
     */
    FILTERS_NO_RESULTS = 'EVENTS.FILTERS_NO_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142766443
     */
    REGISTRATION_LABEL = 'FRONT.EVENT.DIALOG.REGISTRATION_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142771830
     */
    VIDEO_CONFERENCE_LABEL = 'FRONT.EVENT.DIALOG.VIDEO_CONFERENCE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142771876
     */
    RECORDING_LABEL = 'FRONT.EVENT.DIALOG.RECORDING_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142771987
     */
    ORIGINAL_LINK_LABEL = 'FRONT.EVENT.DIALOG.ORIGINAL_LINK_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105440090
     */
    DIALOG_TITLE_UPLOADER = 'FRONT.EVENT.DIALOG.TITLE_UPLOADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96016567
     */
    DIALOG_STARTING_DAY = 'FRONT.EVENT.DIALOG.STARTING_DAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96021514
     */
    DIALOG_STARTING_HOUR = 'FRONT.EVENT.DIALOG.STARTING_HOUR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96021793
     */
    DIALOG_ENDING_DAY = 'FRONT.EVENT.DIALOG.ENDING_DAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96018596
     */
    DIALOG_ENDING_HOUR = 'FRONT.EVENT.DIALOG.ENDING_HOUR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97878482
     */
    DIALOG_ORGANIZERS = 'FRONT.EVENT.DIALOG.ORGANIZERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97917803
     */
    DIALOG_ORGANIZERS_INFO = 'FRONT.EVENT.DIALOG.ORGANIZERS_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189240764
     */
    DIALOG_HIDE_PEOPLE_LIST_HELPER = 'EVENTS.DIALOG.HIDE_PEOPLE_LIST_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189240772
     */
    DIALOG_HIDE_PEOPLE_LIST = 'EVENTS.DIALOG.HIDE_PEOPLE_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203076100
     */
    FILTERING_ALL_EVENTS = 'EVENTS.FILTERING_ALL_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203076361
     */
    FILTERING_FUTURE_EVENTS = 'EVENTS.FILTERING_FUTURE_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203077549
     */
    FILTERING_ONGOING_AND_FUTURE_EVENTS = 'EVENTS.FILTERING_ONGOING_AND_FUTURE_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203077616
     */
    FILTERING_ONGOING_EVENTS = 'EVENTS.FILTERING_ONGOING_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203083609
     */
    FILTERING_PAST_AND_ONGOING_EVENTS = 'EVENTS.FILTERING_PAST_AND_ONGOING_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203087112
     */
    FILTERING_PAST_EVENTS = 'EVENTS.FILTERING_PAST_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96006365
     * TODO: rename key to remove mention of the dialog?
     */
    NEW_EVENT = 'FRONT.EVENT.DIALOG.NEW_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=143105907
     */
    NO_LONGER_PARTICIPATE = 'FRONT.EVENT_BUTTON.NO_LONGER_PARTICIPATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=143105896
     */
    PARTICIPATE = 'FRONT.EVENT_BUTTON.PARTICIPATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=349855110
     */
    PUBLISHED_AN_EVENT = 'STREAMS.PUBLISHED_AN_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300257302
     */
    PUBLISHED_AN_EVENT_IN_CONTAINER = 'STREAMS.PUBLISHED_AN_EVENT_IN_CONTAINER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102121716
     */
    REGISTER = 'FRONT.EVENT_BUTTON.REGISTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102155190
     */
    REGISTERED = 'FRONT.EVENT_BUTTON.REGISTERED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227779715
     */
    REGISTRATION_FULLY_BOOKED = 'EVENTS.REGISTRATION_FULLY_BOOKED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227535148
     */
    REGISTRATION_FULLY_BOOKED_TOOLTIP = 'EVENTS.REGISTRATION_FULLY_BOOKED_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227529660
     */
    REGISTRATION_SPOT_LEFT = 'EVENTS.REGISTRATION_SPOT_LEFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=234822937
     */
    REGISTRATION_NO_SPOT_LEFT = 'EVENTS.REGISTRATION_NO_SPOT_LEFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231654388
     */
    REGISTRATION_NOT_BOOKED_SPOT_WARNING = 'EVENTS.REGISTRATION_NOT_BOOKED_SPOT_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227603657
     */
    REGISTRATION_VACATED_SPOT_WARNING = 'EVENTS.REGISTRATION_VACATED_SPOT_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103571715
     */
    UNREGISTER = 'FRONT.EVENT_BUTTON.UNREGISTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193770664
     */
    EDIT_EVENT = 'FRONT.EVENT.DIALOG.EDIT_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=374431951
     */
    DELETE_EVENT = 'FRONT.EVENT.DIALOG.DELETE_EVENT',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186781994
     */
    ATTEND = 'EVENTS.EVENT_BUTTON.ATTEND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186782023
     */
    GOING = 'EVENTS.EVENT_BUTTON.GOING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186782025
     */
    NOT_GOING = 'EVENTS.EVENT_BUTTON.NOT_GOING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186782032
     */
    MAYBE = 'EVENTS.EVENT_BUTTON.MAYBE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189226985
     */
    UNANSWERED = 'EVENTS.EVENT_BUTTON.UNANSWERED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189238913
     */
    NO_GOING_PEOPLE_DESCRIPTION = 'WIDGET_EVENT_METADATA.NO_GOING_PEOPLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189238953
     */
    NO_NOT_GOING_PEOPLE_DESCRIPTION = 'WIDGET_EVENT_METADATA.NO_NOT_GOING_PEOPLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189238959
     */
    NO_MAYBE_PEOPLE_DESCRIPTION = 'WIDGET_EVENT_METADATA.NO_MAYBE_PEOPLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189238967
     */
    NO_UNANSWERED_PEOPLE_DESCRIPTION = 'WIDGET_EVENT_METADATA.NO_UNANSWERED_PEOPLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=188135938
     */
    PEOPLE_NUMBER = 'WIDGET_EVENT_METADATA.PEOPLE_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189226997
     */
    DOWNLOAD_PEOPLE_LIST = 'WIDGET_EVENT_METADATA.DOWNLOAD_PEOPLE_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101618520
     */
    ATTENDEES = 'FRONT.WIDGET_EVENT_METADATA.ATTENDEES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189238878
     */
    NO_PEOPLE_TITLE = 'WIDGET_EVENT_METADATA.NO_PEOPLE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=215371037
     */
    COPY_LINK = 'EVENTS.COPY_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203087505
     */
    SELECT_EVENTS = 'EVENTS.SELECT_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=357755014
     */
    SELECTED_EVENTS_COUNT = 'EVENTS.SELECTED_EVENTS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=215369313
     */
    SHARE_IN_POST = 'EVENTS.SHARE_IN_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200053153
     */
    CREATE_EVENT = 'EVENTS.CREATE_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200053201
     */
    EMPTY_STATE_TITLE = 'EVENTS.EMPTY_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=200053242
     */
    EMPTY_STATE_DESCRIPTION = 'EVENTS.EMPTY_STATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218713611
     */
    SEARCH_FOR_EVENT = 'EVENTS.SEARCH_FOR_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239864523
     */
    NOTIFICATION_DIALOG_TITLE = 'EVENTS.NOTIFICATION_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239864535
     */
    DO_NOT_SEND_INVITATION = 'EVENTS.DO_NOT_SEND_INVITATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239864544
     */
    NOTIFICATION_DIALOG_CONTENT_TITLE = 'EVENTS.NOTIFICATION_DIALOG_CONTENT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239864573
     */
    INVITATION_DIALOG_CONTENT_DESCRIPTION = 'EVENTS.INVITATION_DIALOG_CONTENT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239597921
     */
    INVITATION_DIALOG_TITLE = 'EVENTS.INVITATION_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239597964
     */
    INVITATION_DIALOG_SEND_TOOLTIP = 'EVENTS.INVITATION_DIALOG_SEND_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239597979
     */
    INVITATION_DIALOG_SEARCH_FIELD_LABEL = 'EVENTS.INVITATION_DIALOG_SEARCH_FIELD_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239612107
     */
    INVITATION_DIALOG_SEARCH_FIELD_PLACEHOLDER = 'EVENTS.INVITATION_DIALOG_SEARCH_FIELD_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239612116
     */
    INVITATION_DIALOG_EMPTY_TITLE = 'EVENTS.INVITATION_DIALOG_EMPTY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239612119
     */
    INVITATION_DIALOG_EMPTY_DESCRIPTION = 'EVENTS.INVITATION_DIALOG_EMPTY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239612221
     */
    GUEST_LIST_LABEL = 'EVENTS.GUEST_LIST_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239612185
     */
    INVITATION_DIALOG_ALREADY_ADDED_GUEST = 'EVENTS.INVITATION_DIALOG_ALREADY_ADDED_GUEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239900990
     */
    SEND_INVITATION = 'EVENTS.SEND_INVITATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241745783
     */
    SEND_INVITATION_SUCCESS_NOTIFICATION = 'EVENTS.SEND_INVITATION_SUCCESS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=258085776
     */
    ATTENDEES_NOTIFICATION_SUCCESS = 'EVENTS.ATTENDEES_NOTIFICATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=258085816
     */
    ATTENDEES_NOTIFICATION_ERROR = 'EVENTS.ATTENDEES_NOTIFICATION_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261239163
     */
    REQUIRED_TIME = 'EVENTS.REQUIRED_TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261233013
     */
    INCORRECT_TIME_FORMAT = 'EVENTS.INCORRECT_TIME_FORMAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=396848693
     */
    EVENT_ANALYTICS = 'EVENTS.EVENT_ANALYTICS',
}
