import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { useSelector } from '@lumapps/redux/react';
import { STRUCTURED_CONTENT_FORMAT } from '@lumapps/structured-content/types';
import { TranslateObject } from '@lumapps/translations';
import { inputLocaleSelector } from '@lumapps/translations/selectors';
import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { WidgetContentProps } from '@lumapps/widget-base/components/WidgetContent';
import { LegacyWidget } from '@lumapps/widget-base/types';
import { fromDITA } from '@lumapps/wrex/serialization/dita/fromDITA/fromDITA';
import { Topic } from '@lumapps/wrex/serialization/dita/types';
import type { Document } from '@lumapps/wrex/types/core';
import { createEmptyContent } from '@lumapps/wrex/utils/createEmptyContent';
import { normalizeForSlate } from '@lumapps/wrex/utils/normalizeForSlate';

import { useEditableWidgetContribution } from '../../hooks/useEditableWidgetContribution';
import { LegacyWidgetContributionProps } from '../../type';
import { EditableWidgetContribution, EditableWidgetContributionProps } from '../EditableWidgetContribution';

import './index.scss';

export interface WidgetContributionWrapperProps extends Partial<WidgetContentProps> {
    /** The Legacy widget to be rendered. */
    legacyWidget: LegacyWidget<LegacyWidgetContributionProps>;
    /** The callBack to switch to reading mode on click outside the editor (legacy) */
    switchToReadMode: () => void;
    /** The callBack to save the content in widget properties (legacy) */
    saveTextualContentInWidgetProperties: (
        textualContent?: TranslateObject<Topic | Document | undefined>,
        format?: STRUCTURED_CONTENT_FORMAT,
    ) => void;
    /** the status of the widget: edition or reading mode */
    editingContent?: boolean;
    /** wether the widget is empty or not (no dita) */
    isWidgetEmpty?: () => boolean;
}

export interface EditableWidgetBlockProperties {
    slate?: Node[];
}

export const WidgetContributionWrapper: React.FC<WidgetContributionWrapperProps> = ({
    legacyWidget: initLegacyWidget,
    switchToReadMode,
    saveTextualContentInWidgetProperties,
    editingContent,
    isWidgetEmpty,
    ...props
}: WidgetContributionWrapperProps) => {
    /**
     * Since <react-element> don't watch the object entierly, but watch it's 'properties' propertie
     * (to avoid rerendering and make first double click to edit work), we need to destructure the object
     * (since only the 'properties' propertie is watched).
     */
    const legacyWidget = { ...initLegacyWidget };

    const currentInputLang = useSelector(inputLocaleSelector);
    const { wrapperRef, childrenRefs, exitEditionMode, onFileUpload, initialContent } = useEditableWidgetContribution({
        legacyWidget,
        switchToReadMode,
        saveTextualContentInWidgetProperties,
    });

    const [content, onChange] = React.useState(initialContent);

    /** manage language switch */
    useEffect(() => {
        if (!!legacyWidget?.properties?.richText && !isEmpty(legacyWidget?.properties?.richText)) {
            /** if the content for selected language has already been set use it */
            const slateContent = normalizeForSlate(legacyWidget.properties.richText[currentInputLang]?.children);
            if (!isEmpty(slateContent)) {
                onChange(slateContent);
            } else {
                /** if not, create a new content for this language */
                onChange(createEmptyContent());
            }
        } else if (legacyWidget?.properties?.dita) {
            /** if the content for selected language has already been set use it */
            const { content: slateContent } = fromDITA(legacyWidget?.properties?.dita[currentInputLang]);
            if (!isEmpty(slateContent)) {
                onChange(slateContent);
            } else {
                /** if not, create a new content for this language */
                onChange(createEmptyContent());
            }
        }
    }, [currentInputLang, legacyWidget.properties?.dita, legacyWidget.properties?.richText, onChange]);

    const theme = legacyWidget?.properties?.style?.content?.theme;

    return (
        <BackendRecomputedWidget<
            LegacyWidgetContributionProps,
            EditableWidgetBlockProperties,
            EditableWidgetContributionProps
        >
            // Destructuring the object, because <react-element> don't watch it
            // entierly, but watch it's 'properties' propertie (to avoid rerendering)
            legacyWidget={{ ...legacyWidget }}
            editableWidgetRenderer={EditableWidgetContribution}
            editableWidgetProps={{
                legacyWidget,
                wrapperRef,
                childrenRefs,
                exitEditionMode,
                onFileUpload,
                content,
                onChange,
                theme,
            }}
            isWidgetEmpty={isWidgetEmpty}
            blockProperties={{ slate: content }}
            editingContent={editingContent}
            {...props}
        />
    );
};
