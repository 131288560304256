/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import { useCustomizations, MODES } from '@lumapps/customizations';
import { PLACEMENT, Targets, computeCustomizationTargets } from '@lumapps/customizations/api';
import { CustomizationComponent } from '@lumapps/customizations/api/components/CustomizationComponent';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Navigation } from '@lumapps/lumx-navigation/components/Navigation';
import { NavigationItem, NavigationItemProps } from '@lumapps/lumx-navigation/components/NavigationItem';
import { ViewMode } from '@lumapps/lumx-navigation/constants';
import { useResponsive } from '@lumapps/responsive';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { useCurrentPageEffect } from '@lumapps/router/hooks/useCurrentPageEffect';
import { usePageTitle } from '@lumapps/utils/hooks/usePageTitle';

import { MAIN_CONTENT_ID } from '../../constants';
import { PageHeader, PageHeaderProps } from '../PageHeader';

import './index.scss';

export enum PAGE_WIDTH {
    /**
     * until tablet => max-width: calc(100vw - #{$lumx-spacing-unit-big * 2});
     * from tablet to desktop => width: 552px;
     * from desktop => width: 744px;
     */
    NARROW = 'narrow',
    /**
     * until tablet => max-width: calc(100vw - #{$lumx-spacing-unit-big * 2});
     * from tablet to desktop => width: 552px;
     * from desktop => width: 936px; Corresponds to 10/12
     */
    COMPACT = 'compact',
    /**
     * until desktop => max-width: calc(100vw - #{$lumx-spacing-unit-big * 2});
     * from desktop to wide => width: 960px;
     * from wide => width: 1128px; Corresponds to 12/12
     */
    WIDE = 'wide',
    /**
     * no restrictions whatsoever. HEAD'S UP: if you use this, you will need to take care
     * of max sizes and responsive yourself!
     */
    FULL = 'full',
}

export interface PageProps {
    /** Classname to append to component's classnames. */
    className?: string;
    /** The components to show in the body area of the page */
    children?: React.ReactNode;
    /** The id for this page, usually used for tracking purposes */
    id: string;
    /** Whether the page is loading or not */
    isLoading?: boolean;
    /** Page title */
    title: string;
    /**
     * whether the default title for the page should be used or not
     */
    useDefaultTitle?: boolean;
    /**
     * Whether the default title is visually hidden or not. If true, then the title
     * will be visually hidden from the page, but still displayed
     */
    isTitleVisuallyHidden?: boolean;
    /**
     * Whether the page has a white background color or not. Note that this only
     * changes the background color of the page, but not the entire application. If this is true,
     * then the component will be displayed with a white background color, while the
     * background color of the entire app will remain the same. To change the background color
     * of the app, use the configuration.ts file located under apps/front-office/components/Pages/configuration.
     */
    hasBackground?: boolean;
    /** the width of the page displayed */
    width?: PAGE_WIDTH;
    /**
     * When the currently displayed page has different types, passing in this argument will create
     * an additional Customizations Target on the page, specifically for that type. For example, if a Content has
     * the type user_directory, an additional customization target and placement will be added to the page, so if
     * customers want to add a customization to all user directories they can easily do so.
     */
    type?: string;
    /** custom props for the label displayed on the header */
    headerLabelProps?: Partial<PageHeaderProps>;
    /**
     * If you want to code your page header in a separate component, you can do so by coding the PageHeader
     * component in another component, and passing it as a prop here.
     */
    pageHeader?: React.ReactElement<PageHeaderProps>;
    /**
     * if the page is displaying multiple subpages, this prop should be passed in in order to show that
     * determine which is the current subpage displayed. This should go hand in hand with the `subpages` prop.
     * This should be the id of your current subpage
     */
    currentSubpage?: string;
    /**
     * An object that has the information needed in order to display the subpages of your current parent view.
     */
    subpages?: Record<
        string,
        {
            /** label that will be displayed on the UI for the subpage link */
            label: string;
            /** view id. This should correlate with the currentSubpage id */
            view: string;
            /** props needed for rendering the link that the navigation will redirect to */
            linkProps: NavigationItemProps['linkProps'];
            /** subpage icon */
            icon?: NavigationItemProps['icon'];
        }
    >;
    /**
     * DISCLAIMER: You should not hide the navigation in order to use a custom one.
     * Make sure to make use of the integrated navigation in this component.
     * */
    shouldHideNavigation?: boolean;
    /** elements to display before the navigation items */
    beforeNavigationItems?: React.ReactNode;
    /** elements to display after the navigation items */
    afterNavigationItems?: React.ReactNode;
    disablePageTitleUpdate?: boolean;
}

const CLASSNAME = 'page';

const DEFAULT_PROPS: Partial<PageProps> = {
    hasBackground: false,
    isLoading: false,
};

/**
 * The section area of a main front office page. All Front office pages should be wrapped inside
 * this component.
 * @family Front office
 */
export const Page: React.FC<PageProps> = ({
    className,
    children,
    id,
    isLoading,
    title,
    hasBackground = false,
    width = PAGE_WIDTH.WIDE,
    type,
    headerLabelProps = {},
    isTitleVisuallyHidden = false,
    useDefaultTitle = false,
    pageHeader,
    currentSubpage,
    subpages = {},
    beforeNavigationItems,
    afterNavigationItems,
    shouldHideNavigation = false,
    ...restOfProps
}) => {
    const { get } = useDataAttributes('front-office');
    const { isMobile } = useResponsive();
    const { modes } = useCustomizations();
    const dataAttributes = get({
        element: id,
        action: 'page',
        isMobile,
    });

    const classes = classnames(className, CLASSNAME, `${CLASSNAME}--${width.toLowerCase()}`, {
        [`${CLASSNAME}--is-loading`]: isLoading,
        [`${CLASSNAME}--has-background`]: hasBackground,
        headless: modes[MODES.HEADLESS],
    });

    usePageTitle(title, currentSubpage && subpages[currentSubpage] ? subpages[currentSubpage].label : undefined, {
        isPageLoading: isLoading,
    });
    useCurrentPageEffect(id, true);

    const {
        id: computedPageId,
        type: computedPageType,
        shouldAddPageTypeCustomization,
    } = computeCustomizationTargets(id, type);

    return (
        <>
            <CustomizationComponent target={Targets.PAGE} placement={PLACEMENT.ABOVE} />

            <CustomizationComponent target={computedPageId} placement={PLACEMENT.ABOVE} />

            {shouldAddPageTypeCustomization ? (
                <CustomizationComponent target={computedPageType} placement={PLACEMENT.ABOVE} />
            ) : null}

            <main
                /**
                 * Even though it might seem redundant to add a "main" role to the "main" tag,
                 * it's actually a good practice as it has a larger assistive technology compatibility.
                 */
                role="main"
                id={MAIN_CONTENT_ID}
                className={classes}
                {...dataAttributes}
                {...restOfProps}
            >
                {useDefaultTitle && !pageHeader ? (
                    <PageHeader
                        title={title}
                        id={id}
                        keepDefaultTypography
                        {...headerLabelProps}
                        isTitleVisuallyHidden={isTitleVisuallyHidden}
                    />
                ) : null}

                {pageHeader || null}

                {subpages && currentSubpage && !shouldHideNavigation ? (
                    <Navigation viewMode={ViewMode.horizontal} className={margin('vertical', 'huge')}>
                        {beforeNavigationItems}

                        {Object.keys(subpages).map((key) => {
                            const { label, linkProps, view, icon } = subpages[key];

                            return (
                                <NavigationItem
                                    key={view}
                                    element="a"
                                    linkAs={RouterLink}
                                    label={label}
                                    linkProps={linkProps}
                                    isSelected={view === currentSubpage}
                                    icon={icon}
                                />
                            );
                        })}

                        {afterNavigationItems}
                    </Navigation>
                ) : null}

                {children}
            </main>

            {shouldAddPageTypeCustomization ? (
                <CustomizationComponent target={computedPageType} placement={PLACEMENT.UNDER} />
            ) : null}

            <CustomizationComponent target={computedPageId} placement={PLACEMENT.UNDER} />

            <CustomizationComponent target={Targets.PAGE} placement={PLACEMENT.UNDER} />
        </>
    );
};

Page.defaultProps = DEFAULT_PROPS;
