import React from 'react';

import compact from 'lodash/compact';

import { isContentCurrentlyDisplayed as isContentCurrentlyDisplayedSelector } from '@lumapps/contents/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { InsertVideoDialog } from '@lumapps/lumx-preview/components/InsertUrlPreviewDialog';
import { mdiLinkVariant, mdiVideoOutline } from '@lumapps/lumx/icons';
import { getEmbedUrl } from '@lumapps/medias/utils';
import { useGooglePickerButton, useVideoUploadButton } from '@lumapps/play-communities';
import { CommunityVideo } from '@lumapps/play-communities/types';
import { getFullVideoLink } from '@lumapps/play-video/utils/getFullVideoLink';
import { Video } from '@lumapps/play/api/types';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { LinkAttachment, LinkResourceType } from '@lumapps/widget-base/types';
import { usePickPlayVideoButton } from '@lumapps/wrex-play-video';
import { PlayVideoEditor } from '@lumapps/wrex-play-video/types';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { WREX_EMBEDDED_VIDEO } from '../../../keys';
import { EmbeddedVideoEditor } from '../../../types';

export type UseEmbeddedVideoDropdownProps = {
    onInsertLink?(video: LinkAttachment): void;
    otherProps?: any;
};

export const useEmbeddedVideoDropdown = ({ onInsertLink, otherProps }: UseEmbeddedVideoDropdownProps) => {
    const { get } = useDataAttributes('embedded-video-dropdown');
    const editor = useSlateStatic() as ReactEditor & PlayVideoEditor & EmbeddedVideoEditor;

    const isContentCurrentlyDisplayed = useSelector(isContentCurrentlyDisplayedSelector);

    const getFormattedPlayVideoItem = React.useCallback(
        (video: Video | CommunityVideo): LinkAttachment => ({
            resourceId: video.id,
            resourceType: LinkResourceType.PLAY_VIDEO,
            title: video.title.value ?? '',
            description: '',
            url: getFullVideoLink({ videoId: video.id }),
        }),
        [],
    );

    const onInsertVideo = (video: LinkAttachment) => {
        editor.insertEmbeddedVideo({
            url: getEmbedUrl(video.url),
            title: video.title,
            thumbnailUrl: video?.images && video.images[0],
            description: video.description,
        });
    };

    const onInsertPlayVideo = (video: Video | CommunityVideo) => {
        if (video) {
            editor.insertPlayVideo({
                id: video.id,
                title: video.title.value,
                url: getFullVideoLink({ videoId: video.id }),
            });
        }
    };

    const { insertPlayVideoDialog, PickPlayVideoButtonProps } = usePickPlayVideoButton({
        onInsert: (video) => {
            if (onInsertLink) {
                onInsertLink(getFormattedPlayVideoItem(video));
            } else {
                onInsertPlayVideo(video);
            }
        },
        otherProps: {
            ...get({ element: 'button', action: 'pick-video-from-gallery' }),
        },
    });

    const { googlePickerButtonProps, GoogleUploadDialog } = useGooglePickerButton({
        uploadType: isContentCurrentlyDisplayed ? 'corporate' : 'employee',
        onInsert: (video) => {
            if (onInsertLink) {
                onInsertLink(getFormattedPlayVideoItem(video));
            } else {
                onInsertPlayVideo(video);
            }
        },
    });

    const {
        uploadButtonProps,
        UploadInput: videoUploadInput,
        UploadDialog: videoUploadDialog,
    } = useVideoUploadButton({
        uploadType: isContentCurrentlyDisplayed ? 'corporate' : 'employee',
        onInsert: (video) => {
            if (onInsertLink) {
                onInsertLink(getFormattedPlayVideoItem(video));
            } else {
                onInsertPlayVideo(video);
            }
        },
    });

    const { translateKey } = useTranslate();
    const [isVideoLinkDialogOpen, , closeVideoLinkDialog, openVideoLinkDialog] = useBooleanState(false);

    const insertLinkVideoDialog = (
        <InsertVideoDialog
            isOpen={isVideoLinkDialogOpen}
            onInsert={(video) => {
                (onInsertLink || onInsertVideo)(video);
                closeVideoLinkDialog();
            }}
            onClose={closeVideoLinkDialog}
        />
    );

    const fromUrlButton: ToolbarItem = {
        itemKey: 'video-from-url',
        type: 'option',
        onClick: openVideoLinkDialog,
        icon: mdiLinkVariant,
        label: translateKey(WREX_EMBEDDED_VIDEO.ENTER_URL),
        otherProps: { ...get({ element: 'option', action: 'open-insert-url-dialog' }) },
    };

    const embeddedVideoDropdownOptions: ToolbarItem[] = compact([
        uploadButtonProps && {
            itemKey: 'video-from-device',
            type: 'option',
            onClick: () => uploadButtonProps.onSelected(),
            icon: uploadButtonProps.icon,
            label: translateKey(uploadButtonProps.label),
            otherProps: { ...get({ element: 'option', action: 'upload-video-from-device' }) },
        },
        PickPlayVideoButtonProps,
        googlePickerButtonProps && {
            ...googlePickerButtonProps,
            itemKey: 'google-picker',
            type: 'option',
            onClick: () => googlePickerButtonProps.onSelected(),
            otherProps: { ...get({ element: 'option', action: 'upload-video-from-google-drive' }) },
        },
        fromUrlButton,
    ]);

    const buttonProps: Omit<ToolbarItem, 'type'> = {
        itemKey: 'embedded-video',
        icon: mdiVideoOutline,
        tooltipLabel: translateKey(WREX_EMBEDDED_VIDEO.ATTACH_VIDEO_BUTTON_TOOLTIP),
        otherProps,
        verticalModeProps: {
            label: translateKey(GLOBAL.VIDEO),
            tooltipLabel: otherProps?.tooltipLabel,
        },
    };

    const dropdown: ToolbarItem = {
        ...buttonProps,
        type: 'submenu',
        childrenOptions: embeddedVideoDropdownOptions,
    };

    const embeddedVideoButton: ToolbarItem =
        embeddedVideoDropdownOptions.length > 1
            ? dropdown
            : {
                  ...buttonProps,
                  type: 'option',
                  onClick: fromUrlButton.onClick,
              };

    return {
        GoogleUploadDialog,
        embeddedVideoButton,
        videoUploadInput,
        insertPlayVideoDialog,
        videoUploadDialog,
        insertLinkVideoDialog,
    };
};
