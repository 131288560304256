import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

const videoUsageAdminRoute: Route = {
    path: '/admin/video-usage',
    appId: AppId.backOffice,
};

const videoUsageAdmin = (): Route => videoUsageAdminRoute;

export { videoUsageAdmin, videoUsageAdminRoute };

export const admin = (): Route => {
    return videoUsageAdminRoute;
};

/* Paths */
export const VIDEO_USAGE_MANAGER = `/${URL_PREFIX}/play-video-library/video-usage`;

/** Defines the route for video usage manager in the front-office. */
const videoUsageRoute = {
    path: VIDEO_USAGE_MANAGER,
    appId: AppId.frontOffice,
};

const videoUsage = (instanceSlug?: string): Route => ({
    ...videoUsageRoute,
    instanceSlug,
});

export { videoUsage, videoUsageRoute };
