import { mdiSparkles, mdiExclamationThick } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { ASK_AI } from './keys';

/** Token for the AskAI feature flag */
export const ASK_AI_FF = 'ask-ai';

/** Token for the AskAI alpha feature flag */
export const ASK_AI_ALPHA_FF = 'ask-ai-alpha';

/** Token for the AskAI beta feature flag */
export const ASK_AI_BETA_FF = 'ask-ai-beta';

export const ASK_AI_SCOPE = 'ask-ai';

/** All info required for AskAi empty states */
export const EMPTY_STATES = {
    noResults: { icon: mdiSparkles, description: GLOBAL.TRY_ANOTHER_QUERY, subtitle: ASK_AI.NO_RESOURCES_TITLE },
    error: {
        icon: mdiExclamationThick,
        description: ASK_AI.ERROR_DESC,
        subtitle: ASK_AI.ERROR_TITLE,
    },
};

/**
 * The max height the ask-ai banner should have before showing a "show more" button,
 * excluding the height of the "show more" button.
 */
export const ASK_AI_MAX_HEIGHT = 260;

/** Root path of the ask ai backoffice section  */
export const ASK_AI_ADMIN_ROOT_PATH = '/admin/ai-search';

/** AskAi Detailed negative feedback */
export const ASK_AI_REASON_TYPES = {
    INCORRECT: 'incorrect',
    IRRELEVANT: 'irrelevant',
    OUTDATED: 'outdated',
} as const;

/** Feedback reasons */
export const FEEDBACK_REASONS: Record<ObjectValues<typeof ASK_AI_REASON_TYPES>, string> = {
    [ASK_AI_REASON_TYPES.INCORRECT]: ASK_AI.FEEDBACK_REASON_INCORRECT,
    [ASK_AI_REASON_TYPES.IRRELEVANT]: ASK_AI.FEEDBACK_REASON_IRRELEVANT,
    [ASK_AI_REASON_TYPES.OUTDATED]: GLOBAL.OUTDATED,
};
