import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { AppId } from '@lumapps/constants/app';
import { isLearningBetaEnabled } from '@lumapps/learning/ducks/selectors';
import {
    SideNavigationSection as Section,
    SideNavigationItem,
} from '@lumapps/lumx-back-office/components/SideNavigation';
import { learningHatImage } from '@lumapps/lumx/custom-icons';
import { mdiChartLine, mdiSettings } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { Route } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getLearningRoles, getMCLaunchUrl } from '../../api';
import { LEARNING } from '../../keys';
import {
    learningAnalyticsRoute,
    learningCategoriesRoute,
    learningCertificatesRoute,
    learningLearnersRoute,
    learningManagersRoute,
    learningTrainingCoursesRoute,
} from '../../routes';
import { MissionCenterPermitionRoles } from '../../types';

export const LearningAdminSideNavigation: React.FC = () => {
    const { translateKey } = useTranslate();
    const [status, setStatus] = useState(BaseLoadingStatus.initial);
    const [route, setRoute] = useState<Route | undefined>(undefined);
    const [roles, setRoles] = useState<MissionCenterPermitionRoles[]>([]);
    const learningBetaEnabled = useSelector(isLearningBetaEnabled);

    useEffect(() => {
        const fetchLearningRolesData = async () => {
            try {
                setStatus(BaseLoadingStatus.loading);
                const response = await getLearningRoles();
                if (isEmpty(response)) {
                    setStatus(BaseLoadingStatus.empty);
                } else {
                    setRoles(response);
                    setStatus(BaseLoadingStatus.idle);
                }
                if (!learningBetaEnabled) {
                    setStatus(BaseLoadingStatus.loading);
                    const response = await getMCLaunchUrl();
                    setRoute({ appId: AppId.external, path: response.launchUrl });
                    setStatus(BaseLoadingStatus.idle);
                }
            } catch (e) {
                setStatus(BaseLoadingStatus.error);
            }
        };
        if (status === BaseLoadingStatus.initial) {
            fetchLearningRolesData();
        }
    }, [learningBetaEnabled, status, route]);

    if (isEmpty(roles) || (!learningBetaEnabled && isEmpty(route))) {
        return null;
    }

    return (
        <Section header={translateKey(LEARNING.LEARNING)} id="learning">
            {roles.includes(MissionCenterPermitionRoles.Manager) && route && (
                <SideNavigationItem
                    label={translateKey(LEARNING.MISSION_CENTER)}
                    icon={learningHatImage}
                    route={route}
                    openInNewTab
                />
            )}

            {roles.includes(MissionCenterPermitionRoles.Analyst) && learningBetaEnabled && (
                <SideNavigationItem
                    icon={mdiChartLine}
                    label={translateKey(GLOBAL.ANALYTICS)}
                    route={learningAnalyticsRoute}
                    id="learning-analytics"
                />
            )}
            {(roles.includes(MissionCenterPermitionRoles.CatalogManager) ||
                roles.includes(MissionCenterPermitionRoles.TrainingManager) ||
                roles.includes(MissionCenterPermitionRoles.TrainingPublisher)) &&
                learningBetaEnabled && (
                    <SideNavigationItem
                        label={translateKey(GLOBAL.CATALOG)}
                        icon={learningHatImage}
                        id="learning-Catalog"
                    >
                        {roles.includes(MissionCenterPermitionRoles.CatalogManager) && (
                            <SideNavigationItem
                                label={translateKey(GLOBAL.CATEGORIES)}
                                route={learningCategoriesRoute}
                                id="learning-categories"
                            />
                        )}
                        <SideNavigationItem
                            label={translateKey(GLOBAL.TRAINING_COURSES)}
                            route={learningTrainingCoursesRoute}
                            id="learning-training-courses"
                        />
                    </SideNavigationItem>
                )}
            {(roles.includes(MissionCenterPermitionRoles.LearnerManager) ||
                roles.includes(MissionCenterPermitionRoles.AdminManager) ||
                roles.includes(MissionCenterPermitionRoles.SuperAdmin)) &&
                learningBetaEnabled && (
                    <SideNavigationItem
                        label={translateKey(GLOBAL.ROLE_ADMINISTRATOR)}
                        icon={mdiSettings}
                        id="learning-Administration"
                    >
                        {roles.includes(MissionCenterPermitionRoles.CatalogManager) && learningBetaEnabled && (
                            <SideNavigationItem
                                label={translateKey(LEARNING.LEARNING_LEARNERS)}
                                route={learningLearnersRoute}
                                id="learning-learners"
                            />
                        )}
                        {roles.includes(MissionCenterPermitionRoles.AdminManager) && learningBetaEnabled && (
                            <SideNavigationItem
                                label={translateKey(GLOBAL.MANAGERS)}
                                route={learningManagersRoute}
                                id="learning-managers"
                            />
                        )}
                        {roles.includes(MissionCenterPermitionRoles.SuperAdmin) && learningBetaEnabled && (
                            <SideNavigationItem
                                label={translateKey(LEARNING.LEARNING_CERTIFICATES)}
                                route={learningCertificatesRoute}
                                id="learning-certificates"
                            />
                        )}
                    </SideNavigationItem>
                )}
        </Section>
    );
};

LearningAdminSideNavigation.displayName = 'SideNavigationItem';
