export enum MEDIAS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=6975636
     */
    ADD_FILES = 'FRONT.ATTACHMENTS.ADD_FILES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=6975637
     */
    ADD_IMAGES = 'FRONT.ATTACHMENTS.ADD_MEDIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15076956
     */
    FILE_DELETED = 'FRONT.DOCUMENT.DELETE.FILE.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=148984636
     */
    FROM_GOOGLE_DRIVE = 'MEDIAS.PICK_FILE_FROM_GOOGLE_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49149927
     */
    FROM_MEDIA_LIBRARY = 'FRONT.POST_DIALOG.ADD_ATTACHMENTS.FROM_MEDIA_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=148994040
     */
    FROM_MICROSOFT_SHAREPOINT = 'MEDIAS.PICK_FILE_FROM_MICROSOFT_SHAREPOINT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458347178
     */
    MAX_FILES = 'MEDIAS.MAX_FILES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244468
     */
    MEDIA_TAGS = 'ADMIN.NAV.MEDIA_TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7667065
     */
    MEDIA_LIBRARY = 'ADMIN.NAV.MEDIA_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12756245
     */
    MEDIA_TRASH = 'ADMIN.NAV.MEDIA_TRASH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244379
     */
    NAV_MEDIA = 'ADMIN.NAV.MEDIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421915470
     */
    NEXT_IMAGE = 'MEDIAS.NEXT_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421915543
     */
    PREVIOUS_IMAGE = 'MEDIAS.PREVIOUS_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423304601
     */
    REMOVE_IMAGE = 'MEDIAS.REMOVE_IMAGE',
}
