/* istanbul ignore file */

import { toCompatibleLanguage } from './utils';

export enum GLOBAL {
    /**
     *  https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451549
     */
    ABOUT = 'ABOUT',
    /**
     *  https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=450949291
     */
    ADD_FEATURED_IMAGE = 'WREX_AI.ADD_FEATURED_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409754867
     */
    AI_GENERATED_RESULT = 'WREX_AI.GENERATED_RESULT',
    /**
     *  https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28324936
     */
    ACCEPT = 'GLOBAL.ACCEPT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451550
     */
    ACCESS = 'ACCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=369773312
     */
    ACCESS_PREVIOUS_PAGE = 'ADMIN.GROUP.ACCESS_PREVIOUS_PAGE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451551
     */
    ACCESS_REQUEST = 'ACCESS_REQUEST',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451552
     */
    ACCESS_REQUESTS = 'ACCESS_REQUESTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343895575
     */
    ACKNOWLEDGED = 'GLOBAL.ACKNOWLEDGED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=99982372
     */
    ACTIONS = 'GLOBAL.ACTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63098435
     */
    ACTION_NOT_COMPLETED_TRY_AGAIN = 'GLOBAL.ACTION_NOT_COMPLETED_TRY_AGAIN',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451595
     */
    ACTIVE = 'ACTIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107446210
     */
    ACTIVATE = 'GLOBAL.ACTIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=256258822
     */
    ACTIVATED = 'GLOBAL.ACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109565578
     */
    ACTIVATION = 'GLOBAL.ACTIVATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108359279
     */
    ACTIVATIONS = 'GLOBAL.ACTIVATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13646806
     */
    ACTIVITY = 'GLOBAL.ACTIVITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10174456
     */
    ADD = 'GLOBAL.ADD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497071961
     */
    ADD_DESCRIPTION = 'ASK_LUMAPPS_CONCEPTS.DESCRIPTION_FIELD_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471479270
     */
    ADD_NAME = 'CONNECTORS.PLACEHOLDER_ADD_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=258011097
     */
    ADD_HERE = 'GLOBAL.ADD_HERE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109627
     */
    ADD_GROUPS = 'GLOBAL.ADD_GROUPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32622229
     */
    ADD_PHOTOS = 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_PHOTOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30417551
     */
    ADD_PHOTO = 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_PHOTO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191603559
     * */
    ADD_VIDEO = 'GLOBAL.ADD_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=136224028
     */
    ADD_SOURCE = 'SEARCH_SETTINGS.ADD_SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488322639
     */
    ADD_A_MESSAGE = 'GLOBAL.ADD_A_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585129
     */
    ADVANCED = 'GLOBAL.ADVANCED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28320942
     */
    ADVANCED_SETTINGS = 'ADMIN.NAV.CUSTOMER_ADVANCED_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=35146714
     */
    AGREE = 'GLOBAL.AGREE_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194715330
     */
    AFTER = 'GLOBAL.AFTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19846936
     */
    ALL = 'GLOBAL.ALL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453659
     */
    ALL_SITES = 'SOCIAL_PROFILE_FILTER_PLATFORM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=216479916
     */
    ALL_TIME = 'GLOBAL.ALL_TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95229040
     */
    ALREADY_ADDED = 'GLOBAL.ALREADY_ADDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=137368686
     */
    ALREADY_USED = 'GLOBAL.ALREADY_USED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=69217881
     */
    ALPHABETICAL_ORDER = 'ADMIN.FOLKSONOMY.FILTER.ALPHA_ASC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=69217891
     */
    ALPHABETICAL_ORDER_DESC = 'ADMIN.FOLKSONOMY.FILTER.ALPHA_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13283066
     */
    ANALYTICS = 'GLOBAL.ANALYTICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384936255
     */
    ANALYTICS_DASHBOARDS = 'GLOBAL.ANALYTICS_DASHBOARDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262510417
     */
    ANALYZING_FILE = 'GLOBAL.ANALYZING_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=31974938
     */
    AND = 'GLOBAL.AND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464140019
     */
    ANSWER = 'GLOBAL.ANSWER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=380700765
     */
    APP = 'GLOBAL.APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103085494
     */
    APP_LOGO = 'GLOBAL.APP_LOGO',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452417
     */
    APPLY = 'APPLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=106055669
     */
    APPLY_CHANGES = 'GLOBAL.APPLY_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=561964847
     */
    APPLIES_TO = 'GLOBAL.APPLIES_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375728783
     */
    APPROXIMATELY = 'GLOBAL.APPROXIMATELY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=166181058
     */
    ARCHIVE = 'ARCHIVE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452419
     */
    ARCHIVED = 'GLOBAL.ARCHIVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182941929
     */
    ARTICLE = 'GLOBAL.ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556630
     */
    ASCENDING = 'FRONT.SETTINGS.ASCENDING_SORT_ORDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=35000038
     */
    AUTHOR = 'GLOBAL.AUTHOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228031802
     */
    AVAILABLE_IN = 'GLOBAL.AVAILABLE_IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178429094
     */
    AVAILABILITY = 'GLOBAL.AVAILABILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68866457
     */
    BACK = 'GLOBAL.BACK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=168296880
     */
    BACK_TO_DRAFT = 'GLOBAL.BACK_TO_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=67227571
     */
    BACK_TO_HOME = 'GLOBAL.BACK_TO_HOME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24992959
     */
    BASIC = 'GLOBAL.BASIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194715326
     */
    BEFORE = 'GLOBAL.BEFORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=212909970
     */
    BETA = 'GLOBAL.BETA',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452438
     */
    BY = 'BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182507417
     */
    BY_VALUE = 'GLOBAL.BY_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210118134
     */
    BY_VALUE_ON_BEHALF = 'GLOBAL.BY_VALUE_ON_BEHALF',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=91567065
     */
    BOOKMARKED_APPS = 'GLOBAL.BOOKMARKED_APPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150419553
     */
    BREADCRUMB = 'GLOBAL.BREADCRUMB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182505836
     */
    BROADCAST = 'GLOBAL.BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23497429
     */
    CALENDAR = 'GLOBAL.CALENDAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=151743815
     */
    CATEGORIES = 'WIDGET_POST_LIST.CATEGORIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10315279
     */
    CANCEL = 'GLOBAL.CANCEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105079606
     */
    CATEGORY = 'GLOBAL.CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=257181487
     */
    CHANGES_CHANGED = 'GLOBAL.CHANGES_CHANGED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150373645
     */
    CHANGES_NOT_SAVED = 'GLOBAL.CHANGES_NOT_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23445342
     */
    CHAT = 'BOOKMARKED_APPS_CHAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8462591
     */
    CHECK_STATUS = 'ADMIN.CUSTOMER.HOOKS.CHECK_STATUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281825839
     */
    CHOOSE_A_DATE = 'GLOBAL.CHOOSE_A_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10340996
     */
    CLEAR = 'GLOBAL.CLEAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=395426617
     */
    CLEAR_SEARCH = 'GLOBAL.CLEAR_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=51439982
     */
    CLEAR_FILTER = 'GLOBAL.CLEAR_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11477573
     */
    CLOSE = 'GLOBAL.CLOSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10154397
     */
    CLOUDSEARCH = 'GLOBAL.CLOUDSEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660331
     */
    COLOR = 'STYLE.COLOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441899595
     */
    CURRENT_SITE = 'GLOBAL.CURRENT_SITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660363
     */
    BACKGROUND = 'STYLE.BACKGROUND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=424700625
     */
    BACKGROUND_COLOR = 'STYLE.BACKGROUND_COLOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=269057101
     */
    COLLAPSE = 'GLOBAL.COLLAPSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115689330
     */
    COLLAPSED = 'GLOBAL.COLLAPSED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452495
     */
    COMMENT = 'COMMENT_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=294311628
     */
    COMMENT_PLURALIZE = 'GLOBAL.COMMENT_PLURALIZE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452496
     */
    COMMENTS = 'COMMENT_BUTTON_LABEL_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=31978745
     */
    COMMUNITIES = 'GLOBAL.COMMUNITIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7786075
     */
    COMMUNITY = 'GLOBAL.COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206218470
     */
    COMPLETED = 'GLOBAL.COMPLETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=253282694
     */
    COMPLETION = 'GLOBAL.COMPLETION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28905556
     */
    CONFIGURATION = 'GLOBAL.CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94788513
     */
    CONFIRM = 'GLOBAL.CONFIRM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=77921830
     */
    CONFIRM_CLOSE = 'GLOBAL.PICKER_DIALOG.CONFIRM_CLOSE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=77922084
     */
    CONFIRM_CLOSE_DESCRIPTION = 'GLOBAL.PICKER_DIALOG.CONFIRM_CLOSE_DESCRIPTION',
    /**
     * @todo: CHANGE THE KEY TO GLOBAL.CONNECT
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97616590
     */
    CONNECT = 'ADMIN.SEARCH_SETTINGS.CONNECTION_SUBMIT',
    /**
     * @todo: CHANGE THE KEY TO GLOBAL.CONNECTED
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264709
     */
    CONNECTED = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_CONNECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=341925328
     */
    CONNECTED_AS = 'GLOBAL.CONNECTED_AS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36635757
     */
    CONNECTION = 'GLOBAL.CONNECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13713032
     */
    CONNECTIONS = 'GLOBAL.CONNECTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244440
     */
    CONTENT = 'GLOBAL.CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=238591946
     */
    CONTENT_LIST = 'GLOBAL.CONTENT_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502493223
     */
    CONTENT_STREAM = 'MOBILE.HOME_PAGE_SECTION_CONTENT_STREAM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244441
     */
    CONTENT_TYPES = 'GLOBAL.CONTENT_TYPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228465912
     */
    CONTENT_TYPE = 'CONTENT_TYPE_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530991054
     */
    CONTENTS_IN_CONTAINER = 'GLOBAL.CONTENTS_IN_CONTAINER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115047421
     */
    CONTEXTUAL_ACTIONS_MENU = 'GLOBAL.CONTEXTUAL_ACTIONS_MENU',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=26484139
     */
    CONTINUE = 'GLOBAL.CONTINUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179628442
     */
    CONTINUE_EDITING = 'GLOBAL.CONTINUE_EDITING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=125757845
     */
    CONTINUE_IN_BROWSER = 'GLOBAL.CONTINUE_IN_BROWSER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12925530
     */
    COPIED = 'GLOBAL.COPIED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12702468
     */
    COPY = 'GLOBAL.COPY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107647646
     */
    COVEO = 'GLOBAL.COVEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107689025
     */
    COPY_LINK = 'GLOBAL.COPY_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300565698
     */
    CURRENTLY_GENERATING = 'PLAY.GENERATING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=463786887
     */
    CUSTOM = 'GLOBAL.CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7392448
     */
    CUSTOMER = 'GLOBAL.CUSTOMER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=401743965
     */
    CUSTOMER_ID = 'GLOBAL.CUSTOMER_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190407432
     */
    CREATE = 'GLOBAL.CREATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451553
     */
    CREATE_AND_EDIT = 'ACTION_ADD_EDIT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=68749093
     */
    CREATE_ONLY = 'ACTION_CREATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292323188
     */
    CREATE_A_PUBLICATION = 'GLOBAL.CREATE_A_PUBLICATION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452798
     */
    CREATED_AT = 'CREATED_AT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452799
     */
    CREATED_BY = 'CREATED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404285
     */
    CREATED_ON = 'ADMIN.FOLKSONOMY.CREATED_THE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452800
     */
    CREATED_DATE = 'CREATED_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452802
     */
    CREATION_DATE = 'CREATION_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452804
     */
    CROP = 'CROP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262197991
     */
    CSV_ANALYSIS_ERROR_INTRO = 'GLOBAL.CSV_ANALYSIS_ERROR_INTRO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262496352
     */
    CSV_UPLOAD_SUCCESSFULL = 'GLOBAL.CSV_UPLOAD_SUCCESSFULL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=461244166
     */
    DARK = 'GLOBAL.DARK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494814
     */
    DASHBOARD = 'GLOBAL.DASHBOARD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452826
     */
    DATE = 'DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=330918898
     */
    DATE_PICKER = 'GLOBAL.DATE_PICKER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452827
     */
    DATE_FORMAT = 'DATE_FORMAT',
    D_M_YYYY = 'D-M-YYYY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452828
     */
    DATE_FORMAT_COMPLETE = 'DATE_FORMAT_COMPLETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452829
     */
    DATE_FORMAT_SHORT_COMPLETE = 'DATE_FORMAT_SHORT_COMPLETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452830
     */
    DATE_FROM = 'DATE_FROM',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452832
     */
    DATE_TO = 'DATE_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458805866
     */
    DAY = 'GLOBAL.DAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194717163
     */
    DAYS = 'GLOBAL.DAYS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107446259
     */
    DEACTIVATE = 'GLOBAL.DEACTIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=256258825
     */
    DEACTIVATED = 'GLOBAL.DEACTIVATED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452834
     */
    DEFAULT = 'DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=364938948
     */
    DEFAULT_COLOR = 'GLOBAL.DEFAULT_COLOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=256258836
     */
    DEFAULT_VALUE = 'GLOBAL.DEFAULT_VALUE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452443
     */
    DELETABLE = 'CAN_PAGE_DELETE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556589
     */
    DELETE = 'GLOBAL.DELETE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218252974
     */
    DELETED = 'GLOBAL.DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63251489
     */
    DELETE_ALL = 'GLOBAL.DELETE_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7418509
     */
    DELETE_ENTITY = 'GLOBAL.DELETE_ENTITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=14058583
     */
    DELETE_FILE = 'GLOBAL.DELETE_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63244992
     */
    DELETE_FOREVER = 'GLOBAL.DELETE_FOREVER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105655766
     */
    DELETE_NB_TEMPLATES = 'GLOBAL.DELETE_NB_TEMPLATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13451702
     */
    DELETE_SELECTED_ITEMS = 'ADMIN_DELETE_SELECTED_ITEMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13451703
     */
    DELETE_SELECTED_ITEMS_DESCRIPTION = 'ADMIN_DELETE_SELECTED_ITEMS_DESCRIPTION',
    DELETE_SELECTED_ITEMS_ERROR = 'ADMIN_DELETE_SELECTED_ITEMS_ERROR',
    DELETE_SELECTED_ITEMS_SUCCESS = 'ADMIN_DELETE_SELECTED_ITEMS_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227924919
     */
    DELIVERED = 'GLOBAL.DELIVERED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=323630024
     */
    DEPLOYED = 'GLOBAL.DEPLOYED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556631
     */
    DESCENDING = 'FRONT.SETTINGS.DESCENDING_SORT_ORDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7263679
     */
    DESCRIPTION = 'GLOBAL.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=558297167
     */
    DESCRIPTION_WITH_LANG = 'GLOBAL.DESCRIPTION_WITH_LANG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239901008
     */
    DESIGNER = 'GLOBAL.DESIGNER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107550612
     */
    DETAILS = 'GLOBAL.DETAILS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13771775
     */
    DEVICES_DESKTOP = 'ANALYTICS.DEVICES_DESKTOP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498879112
     */
    DEVICES_LAPTOP = 'GLOBAL.DEVICES_LAPTOP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13771780
     */
    DEVICES_MOBILE = 'ANALYTICS.DEVICES_MOBILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13771799
     */
    DEVICES_TABLET = 'ANALYTICS.DEVICES_TABLET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8462586
     */
    DISABLE = 'GLOBAL.DISABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11135095
     */
    DISABLED = 'GLOBAL.DISABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33223449
     */
    DISCARD = 'GLOBAL.DISCARD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150409388
     */
    DISCARD_CHANGES = 'GLOBAL.DISCARD_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185559057
     */
    DISCARD_CHANGES_CONFIRM = 'GLOBAL.DISCARD_CHANGES_CONFIRM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33285609
     */
    DISCARD_DRAFT = 'GLOBAL.DISCARD_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=341304289
     */
    DISCONNECT = 'GLOBAL.DISCONNECT',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=432385376
     */
    DISPLAY = 'GLOBAL.DISPLAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=116739704
     */
    DISPLAY_CONTEXTUAL_ACTIONS_MENU = 'GLOBAL.DISPLAY_CONTEXTUAL_ACTIONS_MENU',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453459
     */
    DISPLAY_MORE_RESULTS = 'SEARCH_PLUS_SHOW_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=198842735
     */
    DISPLAY_SOMETHING = 'GLOBAL.DISPLAY_SOMETHING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210160239
     */
    DIRECTORIES = 'SEARCH_PLUS_DIRECTORY_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8929880
     */
    DIRECTORY = 'GLOBAL.DIRECTORY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451583
     */
    DIRECTORY_ENTRY = 'ACTION_TYPE_DIRECTORY_ENTRY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451584
     */
    DIRECTORY_ENTRY_FAVORITES = 'ACTION_TYPE_DIRECTORY_ENTRY_FAVORITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379971
     */
    DOCUMENTATION = 'GLOBAL.DOCUMENTATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=267512888
     */
    DONE = 'GLOBAL.DONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107583478
     */
    DOWNLOAD = 'GLOBAL.DOWNLOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261856536
     */
    DOWNLOAD_EXAMPLE = 'GLOBAL.DOWNLOAD_EXAMPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37977755
     */
    DOWNLOAD_FILE = 'ADMIN.NANNY.DOWNLOAD_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242953302
     */
    DOWNLOAD_LOG = 'GLOBAL.DOWNLOAD_LOG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=166149829
     */
    DRAFT = 'GLOBAL.DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494811
     */
    DRIVE = 'GLOBAL.DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=167048984
     */
    DUPLICATE = 'GLOBAL.DUPLICATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210363721
     */
    DURATION = 'GLOBAL.DURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23817949
     */
    DURATION_FORMAT_DHMS = 'GLOBAL.DURATION_FORMAT_DHMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23817994
     */
    DURATION_FORMAT_HMS = 'GLOBAL.DURATION_FORMAT_HMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23818010
     */
    DURATION_FORMAT_MS = 'GLOBAL.DURATION_FORMAT_MS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23818014
     */
    DURATION_FORMAT_S = 'GLOBAL.DURATION_FORMAT_S',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203087943
     */
    DYNAMIC = 'GLOBAL.DYNAMIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11691389
     */
    EDIT = 'GLOBAL.EDIT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452444
     */
    EDITABLE = 'CAN_PAGE_EDIT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452874
     */
    EDITORS = 'EDITOR_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457711322
     */
    EDITED_AT_DATE = 'GLOBAL.EDITED_AT_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=483698152
     */
    EDIT_MESSAGE = 'GLOBAL.EDIT_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=526841251
     */
    EDIT_OPTIONS = 'GLOBAL.EDIT_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19167397
     */
    EDIT_PROFILE = 'GLOBAL.EDIT_PROFILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457907378
     */
    EDIT_SOURCE = 'GLOBAL.EDIT_SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10988679
     */
    EMAIL = 'GLOBAL.EMAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170863634
     */
    EMBED = 'GLOBAL.EMBED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8462415
     */
    ENABLE = 'GLOBAL.ENABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11142167
     */
    ENABLED = 'GLOBAL.ENABLED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452919
     */
    END_DATE = 'END_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452920
     */
    END_TIME = 'END_TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29005184
     */
    ENTER_A_URL = 'ADMIN.TERMS_AND_CONDITIONS.REDIRECT_URL.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158527208
     */
    ENTER_URL = 'GLOBAL.ENTER_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115571328
     */
    ENTITY_MOVED = 'GLOBAL.ENTITY_MOVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10315962
     */
    ERROR = 'GLOBAL.ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=257182463
     */
    ERROR_CHANGES_NOT_SAVED = 'GLOBAL.ERROR_CHANGES_NOT_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=120223382
     */
    ERROR_COLOR_CONTRAST_RATIO = 'GLOBAL.ERROR_COLOR_CONTRAST_RATIO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10844217
     */
    ERROR_MANDATORY_FIELD = 'GLOBAL.ERROR_MANDATORY_FIELD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452935
     */
    ERROR_NOT_AUTHORIZED = 'ERROR_NOT_AUTHORIZED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179984243
     */
    ERROR_OCCURRED_TRY_AGAIN = 'GLOBAL.ERROR_OCCURRED_TRY_AGAIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509515146
     */
    ERROR_PAGE_RESTRICTED_ACCESS_TITLE = 'GLOBAL.ERROR_PAGE_RESTRICTED_ACCESS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509515153
     */
    ERROR_PAGE_RESTRICTED_ACCESS_DESCRIPTION = 'GLOBAL.ERROR_PAGE_RESTRICTED_ACCESS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452937
     */
    ERROR_URL_BAD_FORMAT = 'ERROR_URL_BAD_FORMAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19639851
     */
    ERROR_WHILE_FETCHING_DATA = 'SA_ANALYTICS_FETCH_DATA_RETRY_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262513575
     */
    ERRORS_IN_FILE = 'GLOBAL.ERRORS_IN_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178427004
     */
    EVERYONE = 'GLOBAL.EVERYONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=352461983
     */
    EXCERPT = 'GLOBAL.EXCERPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=359712980
     */
    EXECUTE = 'GLOBAL.EXECUTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268982659
     */
    EXPAND = 'GLOBAL.EXPAND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115689345
     */
    EXPANDED = 'GLOBAL.EXPANDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536710228
     */
    EXPIRATION_DATE = 'GLOBAL.EXPIRATION_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=528907392
     */
    EXPIRES_ON = 'GLOBAL.EXPIRES_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42402325
     */
    EXPORT = 'GLOBAL.EXPORT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59990074
     */
    EXPORT_CSV = 'GLOBAL.EXPORT_CSV',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205168357
     */
    EXTERNAL_LINK = 'GLOBAL.EXTERNAL_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7168030
     */
    FAVORITE_SITES = 'GLOBAL.FAVORITE_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98745556
     */
    FEATURED = 'FRONT.SEARCH.RESULT_IS_FEATURED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109139084
     */
    FEATURES = 'GLOBAL.FEATURES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119169930
     */
    FEED = 'GLOBAL.FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7382191
     */
    FEEDS = 'GLOBAL.FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161164189
     */
    FIELD_IS_REQUIRED = 'GLOBAL.FIELD_IS_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438170563
     */
    FILE = 'GLOBAL.FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262496325
     */
    FILE_ANALYZED = 'GLOBAL.FILE_ANALYZED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179654640
     */
    FILE_REPLACED = 'GLOBAL.FILE_REPLACED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=154710836
     */
    FILE_UPLOADED = 'GLOBAL.FILE_UPLOADED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=154715830
     */
    FILES_UPLOADED = 'GLOBAL.FILES_UPLOADED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19853226
     */
    FILTER = 'GLOBAL.FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10666203
     */
    FILTERS = 'GLOBAL.FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19579838
     */
    FILTER_BY = 'GLOBAL.FILTER_BY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452953
     */
    FILTER_BY_NAME = 'FILTER_CONTENT_BY_NAME',
    /**
     * TODO: rework translation ID
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121483044
     */
    FILTER_BY_TITLE = 'WIDGET_PLAY.SETTINGS_PICKER_DIALOG_SEARCH_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203065115
     */
    FILTERING = 'GLOBAL.FILTERING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260538216
     */
    FINISH = 'GLOBAL.FINISH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193219461
     */
    FINISHED = 'GLOBAL.FINISHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9586704
     */
    FIRST_NAME = 'GLOBAL.FIRST_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11416937
     */
    FOLLOW = 'GLOBAL.FOLLOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11416940
     */
    FOLLOWED = 'GLOBAL.FOLLOWED',
    /**
     *  https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240467589
     */
    FORMAT_COLON = 'GLOBAL.FORMAT_COLON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210160226
     */
    FROM = 'GLOBAL.FROM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=450953805
     */
    FROM_COMPUTER = 'GLOBAL.FROM_COMPUTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206446906
     */
    FROM_TO = 'GLOBAL.FROM_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=432948999
     */
    FULLSCREEN_IMAGE_SLIDESHOW = 'GLOBAL.FULLSCREEN_IMAGE_SLIDESHOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=432952014
     */
    FULLSCREEN_IMAGE = 'GLOBAL.FULLSCREEN_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=48063110
     */
    FULL_NAME = 'FRONT.USER_DIRECTORY.FULL_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10285883
     */
    GENERAL = 'GLOBAL.GENERAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21476010
     */
    GENERAL_SETTINGS = 'ADMIN.NAV.MOBILE_GENERAL_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172529223
     */
    GENERATE = 'GLOBAL.GENERATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8544879
     */
    GENERIC_ERROR = 'GLOBAL.ERROR.GENERIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346182070
     */
    GET = 'GLOBAL.GET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585134
     */
    GLOBAL_SETTINGS = 'ADMIN.ADVANCED_SETTINGS.DEFAULT_CATEGORY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451585
     */
    GLOBAL_WIDGET = 'ACTION_TYPE_GLOBAL_WIDGET',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452322
     */
    GOOGLE = 'ADMIN_RESELLER_CONSOLE_TAB_GOOGLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585133
     */
    GOOGLE_ANALYTICS = 'GLOBAL.GOOGLE_ANALYTICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17810571
     */
    GOOGLE_TEAM_DRIVE = 'GLOBAL.GOOGLE_TEAM_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33223448
     */
    GO_BACK = 'GLOBAL.GO_BACK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=47570579
     */
    GO_TO_HOMEPAGE = 'FRONT.GO_HOMEPAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185355715
     */
    GROUP = 'GLOBAL.GROUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109628
     */
    GROUP_NAME = 'GLOBAL.GROUP_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95252098
     */
    GROUPS_COUNT = 'GLOBAL.GROUPS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109630
     */
    ONE_GROUP_LIMIT = 'GLOBAL.ONE_GROUP_LIMIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=435220488
     */
    HELLO_USERNAME = 'GLOBAL.HELLO_USERNAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=539738842
     */
    HELPFUL = 'GLOBAL.HELPFUL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660316
     */
    HEXADECIMAL_COLOR_ERROR = 'GLOBAL.HEXADECIMAL_COLOR_ERROR',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453034
     */
    HIDE = 'HIDE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15644151
     */
    HIDE_CONTEXTUAL_ACTIONS_MENU = 'GLOBAL.HIDE_CONTEXTUAL_ACTIONS_MENU',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=254905511
     */
    HIDE_MESSAGE = 'GLOBAL.HIDE_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301336781
     */
    HIGHLIGHTED_FOR = 'LOOKER_CONTENT_HIGHLIGHTED_FOR',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453662
     */
    HISTORY = 'SOCIAL_PROFILE_HISTORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62180976
     */
    HOME_PAGE = 'GLOBAL.HOME_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470274960
     */
    HOMEPAGE = 'GLOBAL.HOMEPAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10125953
     */
    ICON = 'GLOBAL.ICON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29856705
     */
    ID = 'GLOBAL.ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73551667
     */
    INSIGHTS = 'ADMIN.FOLKSONOMY.INSIGHTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438170578
     */
    IMAGE = 'GLOBAL.IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=154716462
     */
    IMAGE_UPLOADED = 'GLOBAL.IMAGE_UPLOADED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=154716565
     */
    IMAGES_UPLOADED = 'GLOBAL.IMAGES_UPLOADED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182569960
     */
    IMMEDIATELY = 'GLOBAL.IMMEDIATELY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13625576
     */
    IMPORT = 'GLOBAL.IMPORT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15814718
     */
    IN = 'GLOBAL.IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19317375
     */
    INACTIVE = 'SA_SOCIAL_NETWORK_NOT_ACTIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292803365
     */
    IN_VALUE = 'GLOBAL.IN_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=65836465
     */
    INFORMATION = 'GLOBAL.INFORMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12756255
     */
    INSERT = 'GLOBAL.INSERT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355120141
     */
    ATTACH = 'GLOBAL.ATTACH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281835253
     */
    ATTACHMENT = 'JOURNEYS_CORE.ATTACHMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901112
     */
    INSERT_VIDEO = 'FRONT.ATTACHMENTS.ADD_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178429144
     */
    INTEGRATION = 'GLOBAL.INTEGRATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=41841405
     */
    INTEGRATIONS = 'NATIVE_INTEGRATIONS',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=34077994
     */
    INVALID_FIELD = 'GLOBAL.INVALID_FIELD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185356063
     */
    IS = 'GLOBAL.IS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=117068039
     */
    IS_ALREADY_ADDED = 'FRONT.TAGZ.ALREADY_ADDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=329564481
     */
    JUMP_TO = 'GLOBAL.JUMP_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172534601
     */
    KEEP = 'GLOBAL.KEEP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=479459896
     */
    KEEP_AS_IS = 'GLOBAL.KEEP_AS_IS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412461108
     */
    KEEP_EDITING = 'GLOBAL.KEEP_EDITING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268684994
     */
    KEY = 'GLOBAL.KEY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454171
     */
    KEYWORD = 'WIDGET_TYPE_CONTENT-FILTER_QUERY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453094
     */
    KNOW_MORE = 'KNOW_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=16841855
     */
    LABEL = 'GLOBAL.LABEL',
    /**
     * Label (English)
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=160147105
     */
    LABEL_LOCALIZED = 'GLOBAL.LABEL_LOCALIZED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11025100
     */
    LANGUAGE = 'FRONT.PROCESS_MAKER.LANGUAGE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452090
     */
    LANGUAGES = 'ADMIN_INSTANCE_LANGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169581648
     */
    LAST_CALCULATED_ON = 'GLOBAL.LAST_CALCULATED_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10306813
     */
    LAST_MODIFIED_ON = 'GLOBAL.LAST_MODIFIED_ON',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451760
     */
    LAST_NAME = 'ADMIN_FILTER_LAST_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66344024
     */
    LAST_UPDATE = 'GLOBAL.LAST_UPDATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453146
     */
    LAST_UPDATED_ON = 'LAST_UPDATED_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203087998
     */
    LATEST_TO_OLDEST = 'GLOBAL.LATEST_TO_OLDEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12320919
     */
    LATEST = 'GLOBAL.LATEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447953801
     */
    LEARNING = 'GLOBAL.LEARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447137921
     */
    LEARN_MORE = 'GLOBAL.LEARN_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262924348
     */
    LEAST_RECENT_ACTIVITY = 'GLOBAL.LEAST_RECENT_ACTIVITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=180018894
     */
    LEAVE = 'GLOBAL.LEAVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179082028
     */
    LEAVE_WITHOUT_SAVING = 'GLOBAL.LEAVE_WITHOUT_SAVING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33285106
     */
    LESS = 'GLOBAL.LESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262602587
     */
    LESS_FILTERS = 'GLOBAL.LESS_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239888133
     */
    LESS_SETTINGS = 'GLOBAL.LESS_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660382
     */
    LIGHT = 'STYLE.FONT.LIGHT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453148
     */
    LIKE = 'LIKE_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=294297955
     */
    LIKE_PLURALIZE = 'GLOBAL.LIKE_PLURALIZE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453149
     */
    LIKES = 'LIKE_BUTTON_LABEL_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262284076
     */
    LINE_ERROR = 'GLOBAL.LINE_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12703174
     */
    LINK_COPIED = 'FRONT.MEDIA_PICKER.LINK_COPIED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=224949444
     */
    LINK_PREVIEW = 'GLOBAL.LINK_PREVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161106140
     */
    LINK = 'GLOBAL.LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=207408357
     */
    LIVE = 'GLOBAL.LIVE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107603519
     */
    LOAD_MORE = 'GLOBAL.LOAD_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=116037013
     */
    LOADING = 'GLOBAL.LOADING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97648163
     */
    LOCATION = 'FRONT.EVENT.DIALOG.LOCATION_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423077417
     */
    LOG_IN = 'GLOBAL.LOG_IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423252787
     */
    LOGGED_IN = 'GLOBAL.LOGGED_IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13556273
     */
    LUMAPPS = 'GLOBAL.LUMAPPS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453456
     */
    LUMWORK = 'SEARCH_PLUS_LUMWORK_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107782158
     */
    MANAGER = 'GLOBAL.MANAGER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203088210
     */
    MANUAL = 'GLOBAL.MANUAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7382173
     */
    MEDIA = 'GLOBAL.MEDIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=554481912
     */
    MEDIA_LIBRARY = 'GLOBAL.MEDIA_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107783308
     */
    MEMBER = 'GLOBAL.MEMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172518375
     */
    MESSAGE = 'GLOBAL.MESSAGE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454228
     */
    METADATA = 'WIDGET_TYPE_CONTENT-LIST_METADATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384657001
     */
    MICRO_APP = 'MARKETPLACE.EXTENSION_TYPE_MICRO_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194353690
     */
    MICROSOFT = 'GLOBAL.MICROSOFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58084361
     */
    MOBILE_NAVIGATION = 'ADMIN.FEATURES.MOBILE_NAVIGATION.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327399314
     */
    MOBILE_PUSH = 'GLOBAL.MOBILE_PUSH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97670470
     */
    MODIFY = 'GLOBAL.MODIFY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=224631626
     */
    MODIFICATIONS_SAVED = 'GLOBAL.MODIFICATIONS_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13732725
     */
    MONTH = 'GLOBAL.MONTH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458808648
     */
    MONTHS = 'GLOBAL.MONTHS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10481827
     */
    MORE = 'GLOBAL.MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186868670
     */
    MOVE = 'GLOBAL.MOVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115559781
     */
    MOVE_HERE = 'GLOBAL.MOVE_HERE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115559775
     */
    MOVE_SOMETHING = 'GLOBAL.MOVE_SOMETHING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15644046
     */
    MORE_ACTIONS = 'GLOBAL.MORE_ACTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220500248
     */
    MORE_FILTERS = 'GLOBAL.MORE_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90782480
     */
    MORE_INFO = 'GLOBAL.MORE_INFO',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108266632
     */
    MORE_OPTIONS = 'GLOBAL.MORE_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=18986411
     */
    MORE_RESULTS = 'GLOBAL.MORE_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239888076
     */
    MORE_SETTINGS = 'GLOBAL.MORE_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=424691635
     */
    MOSAIC = 'GLOBAL.MOSAIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49475998
     */
    MOST_RECENT = 'GLOBAL.MOST_RECENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=257252577
     */
    MOST_RECENT_ACTIVITY = 'GLOBAL.MOST_RECENT_ACTIVITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108558081
     */
    MOST_RELEVANT = 'GLOBAL.MOST_RELEVANT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114121625
     */
    MOVE_DOWN = 'GLOBAL.MOVE_DOWN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=114121533
     */
    MOVE_UP = 'GLOBAL.MOVE_UP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194204966
     */
    MOVE_TO_TOP = 'GLOBAL.MOVE_TO_TOP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194204986
     */
    MOVE_TO_BOTTOM = 'GLOBAL.MOVE_TO_BOTTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265093749
     */
    MOVED = 'GLOBAL.MOVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11191554
     */
    NAME = 'GLOBAL.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=139013184
     */
    NATIVE_SEARCH = 'GLOBAL.NATIVE_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493009262
     */
    NAVIGATE = 'GLOBAL.NAVIGATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500022
     */
    NAVIGATION = 'ADMIN.MOBILE.NAVIGATION.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186537776
     */
    NB_PEOPLE = 'GLOBAL.NB_PEOPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194638259
     */
    NB_VIDEOS = 'GLOBAL.NB_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=381175847
     */
    NB_OPTIONS_AVAILABLE = 'GLOBAL.NB_OPTIONS_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=455387137
     */
    NB_RESULTS_DISPLAYED = 'GLOBAL.NB_RESULTS_DISPLAYED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=455830690
     */
    NB_RESULTS_DISPLAYED_FOR_QUERY = 'GLOBAL.NB_RESULTS_DISPLAYED_FOR_QUERY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453270
     */
    NEW = 'NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=483698149
     * */
    NEW_MESSAGE = 'GLOBAL.NEW_MESSAGE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451590
     */
    NEWSLETTER = 'ACTION_TYPE_NEWSLETTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244388
     */
    NEWSLETTERS = 'ADMIN.NAV.NEWSLETTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72120078
     */
    NEXT = 'GLOBAL.NEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72107194
     */
    NEXT_MONTH = 'GLOBAL.NEXT_MONTH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11607532
     */
    NO = 'GLOBAL.NO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=490582199
     */
    NO_DESCRIPTION = 'GLOBAL.NO_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=221571969
     */
    NOTIFICATION = 'NOTIFICATION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453355
     */
    NOTIFICATIONS = 'NOTIFICATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73036638
     */
    NOT_ALLOWED = 'GLOBAL.NOT_ALLOWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=67227374
     */
    NOT_AUTHORIZED = 'ADMIN.NOT_AUTHORIZED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227925045
     */
    NOT_DELIVERED = 'GLOBAL.NOT_DELIVERED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=48361593
     */
    NOT_ENOUGH_DATA = 'GLOBAL.NOT_ENOUGH_DATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=539738843
     */
    NOT_HELPFUL = 'GLOBAL.NOT_HELPFUL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=323626845
     */
    NOT_READY = 'GLOBAL.NOT_READY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182592599
     */
    NOT_SAVED = 'GLOBAL.NOT_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227924573
     */
    NOT_SENT = 'GLOBAL.NOT_SENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=330234671
     */
    NOTE = 'GLOBAL.NOTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=197756158
     */
    THERE_ARE_NO_INSIGHTS = 'GLOBAL.THERE_ARE_NO_INSIGHTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=477849348
     */
    NAVIGATED_TO_PAGE = 'GLOBAL.NAVIGATED_TO_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262825818
     */
    NO_MORE_THAN_X_LINES = 'GLOBAL.NO_MORE_THAN_X_LINES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=18986412
     */
    NO_RESULTS = 'GLOBAL.NO_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175606823
     */
    NO_RESULTS_FOUND = 'GLOBAL.NO_RESULTS_FOUND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=47049338
     */
    NO_RESULTS_FOR = 'GLOBAL.NO_RESULTS_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=51439985
     */
    NO_RESULT_FOR_THIS_FILTER = 'GLOBAL.NO_RESULT_FOR_THIS_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218236420
     */
    NO_FILTERED_RESULTS = 'GLOBAL.NO_FILTERED_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222639374
     */
    NOT_PLANNED = 'GLOBAL.NOT_PLANNED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194723408
     */
    NUMBER = 'GLOBAL.NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10842635
     */
    OK = 'GLOBAL.OK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11312661
     */
    OKTA = 'ADMIN.FEATURES.OKTA.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203088021
     */
    OLDEST_TO_LATEST = 'GLOBAL.OLDEST_TO_LATEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218703786
     */
    OLDEST = 'GLOBAL.OLDEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226042925
     */
    ON_DATE = 'GLOBAL.ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375823599
     */
    ON_DATE_TITLE = 'GLOBAL.ON_DATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13563777
     */
    ONE_DRIVE = 'GLOBAL.ONE_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193219242
     */
    ONGOING = 'GLOBAL.ONGOING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=226695909
     */
    ONLY_NUMBERS = 'GLOBAL.ONLY_NUMBERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107689375
     */
    OPEN = 'GLOBAL.OPEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10481815
     */
    OPEN_NEW_TAB = 'GLOBAL.OPEN_NEW_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=125757777
     */
    OPEN_APP = 'GLOBAL.OPEN_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458220746
     */
    OPEN_SOMETHING = 'GLOBAL.OPEN_SOMETHING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227925011
     */
    OPENED = 'GLOBAL.OPENED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227322747
     */
    OPERATOR = 'GLOBAL.OPERATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7253282
     */
    OPTIONS = 'GLOBAL.OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193985848
     */
    OR = 'GLOBAL.OR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556624
     */
    ORDER = 'FRONT.SETTINGS.ORDERING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556594
     */
    ORDER_BY = 'GLOBAL.ORDER_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119454013.
     */
    ORDER_BY_UPLOADED_DATE_ASC = 'GLOBAL.ORDER_BY_UPLOADED_DATE_ASC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119454165.
     */
    ORDER_BY_UPLOADED_DATE_DESC = 'GLOBAL.ORDER_BY_UPLOADED_DATE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830221
     */
    ORGANIZATION = 'GLOBAL.ORGANIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277786434
     */
    OTHER_SITES = 'GLOBAL.OTHER_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171170559
     */
    OUTDATED = 'REPORTS.POST_REPORT_DIALOG_OUTDATED_REASON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494506
     * @todo change to GLOBAL.OVERVIEW
     */
    OVERVIEW = 'ADMIN.ANALYTICS.DASHBOARD_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=173929172
     */
    PAGE_IS_PRIVATE = 'GLOBAL.PAGE_IS_PRIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422509297
     */
    PAGINATION_ON = 'GLOBAL.PAGINATION_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=454420789
     */
    PASTE_URL = 'GLOBAL.PASTE_URL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452373
     */
    PASSWORD = 'ADMIN_USER_DIRECTORY_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=297179655
     */
    PARAMETERS = 'GLOBAL.PARAMETERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265983375
     */
    PARTIAL_EDIT = 'ROLES.MENU_PARTIAL_EDIT_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158713320
     */
    PAUSE = 'GLOBAL.PAUSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227244939
     */
    PAUSE_VERB = 'GLOBAL.PAUSE_VERB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=216664727
     */
    PAUSED = 'GLOBAL.PAUSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227924593
     */
    PENDING = 'GLOBAL.PENDING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131672756
     */
    PEOPLE = 'GLOBAL.PEOPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211142009
     */
    PEOPLE_SINGULAR_PLURAL = 'GLOBAL.PEOPLE_SINGULAR_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13732737
     */
    PER = 'GLOBAL.PER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178426732
     */
    PERMISSIONS = 'GLOBAL.PERMISSIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133148899
     */
    PICK_DATE = 'GLOBAL.PICK_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178428561
     */
    PICK_GROUPS = 'GLOBAL.PICK_GROUPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10481813
     */
    PICK_ICON = 'GLOBAL.PICK_ICON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13683681
     */
    PLACE_ACTIVITY = 'GLOBAL.PLACE_ACTIVITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193219660
     */
    PLANNED = 'GLOBAL.PLANNED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158712969
     */
    PLAY = 'GLOBAL.PLAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199269359
     */
    PLAYLISTS = 'PLAY.PLAYLISTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19759872c
     */
    PLEASE_WAIT = 'GLOBAL.PLEASE_WAIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7786076
     */
    POST = 'GLOBAL.POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585144
     */
    PREVIEW = 'GLOBAL.PREVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72120079
     */
    PREVIOUS = 'GLOBAL.PREVIOUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72107200
     */
    PREVIOUS_MONTH = 'GLOBAL.PREVIOUS_MONTH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80261523
     */
    PRESS_ENTER_TO_ADD = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_MEMBERSHIPS.PRESS_ENTER_TO_ADD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107414891
     */
    PRIMARY_COLOR = 'MOBILE.CONFIGURATION_COLOR_THEME_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103144077
     */
    PRIORITY = 'GLOBAL.PRIORITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17812585
     */
    PRIVATE = 'GLOBAL.PRIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107581033
     */
    PROGRESS_TRACKER_LABEL = 'GLOBAL.PROGRESS_TRACKER_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556632
     */
    PROPERTIES = 'FRONT.SETTINGS.PROPERTIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464058477
     */
    PROCESSING = 'GLOBAL.PROCESSING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472280872
     */
    PROFILE = 'GLOBAL.PROFILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17812601
     */
    PUBLIC = 'GLOBAL.PUBLIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17813638
     */
    PUBLIC_LINK = 'GLOBAL.PUBLIC_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162602754
     */
    PUBLICATION_DATE = 'GLOBAL.PUBLICATION_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290146711
     */
    PUBLICATION_TYPES = 'GLOBAL.PUBLICATION_TYPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=69538841
     */
    PUBLISH = 'GLOBAL.PUBLISH',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452779
     */
    PUBLISHED = 'CONTENT_STATUS_LIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339883567
     */
    PUBLISHED_IN_LOCATION = 'GLOBAL.PUBLISHED_IN_LOCATION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453401
     */
    PUBLISHED_ON = 'PUBLISHED_ON',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=47267163213453401
     */
    PUBLISHED_ON_DATE = 'GLOBAL.PUBLISHED_ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10306915
     */
    RECEIVED_ON = 'GLOBAL.RECEIVED_ON',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451577
     */
    READ = 'ACTION_READ',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=306450436
     */
    PUBLISHED_SUCCESS = 'GLOBAL.PUBLISHED_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=180018843
     */
    RECENT_CHANGES_NOT_SAVED = 'GLOBAL.RECENT_CHANGES_NOT_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498496453
     */
    REFER_TO_DOCUMENTATION_LINK = 'GLOBAL.REFER_TO_DOCUMENTATION_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7781860
     */
    REFRESH = 'GLOBAL.REFRESH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28324947
     */
    REFUSE = 'GLOBAL.REFUSE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452780
     */
    REFUSED = 'CONTENT_STATUS_REFUSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109614991
     */
    RELATIVE_CREATED_AT = 'GLOBAL.RELATIVE_CREATED_AT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471056622
     */
    RELATIVE_PUBLISHED_DATE = 'GLOBAL.RELATIVE_PUBLISHED_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471056667
     */
    RELATIVE_LAST_MODIFIED_DATE = 'GLOBAL.RELATIVE_LAST_MODIFIED_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471056679
     */
    RELATIVE_RECEIVED_DATE = 'GLOBAL.RELATIVE_RECEIVED_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19495169
     */
    RELEVANCE = 'GLOBAL.RELEVANCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46667897
     */
    RELOAD = 'GLOBAL.RELOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40255108
     */
    RELOAD_PAGE = 'GLOBAL.RELOAD_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158033269
     */
    REMOVED = 'GLOBAL.REMOVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11541304
     */
    REMOVE = 'GLOBAL.REMOVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179596455
     */
    REMOVE_ALL = 'GLOBAL.REMOVE_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=14945552
     */
    RENAME = 'GLOBAL.RENAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=138360519
     */
    REPLACE = 'GLOBAL.REPLACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12685746
     */
    REPLY = 'GLOBAL.REPLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95252488
     */
    REQUESTS_COUNT = 'GLOBAL.REQUESTS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=414200979
     */
    RESEND = 'GLOBAL.RESEND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441907463
     */
    RESULT_GENERATED_BY_AI = 'GLOBAL.RESULT_GENERATED_BY_AI',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453423
     */
    RESET = 'RESET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62188310
     */
    RESOURCE_CENTER = 'GLOBAL.RESOURCE_CENTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7646101
     */
    RESTORE = 'GLOBAL.RESTORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178427333
     */
    RESTRICT_ACCESS = 'GLOBAL.RESTRICT_ACCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=522931075
     */
    RESTRICTED_ACCESS = 'GLOBAL.RESTRICTED_ACCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292336992
     */
    RESTRICTED_TO = 'GLOBAL.RESTRICTED_TO',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453877
     */
    RESTRICTIONS = 'WIDGET_SETTINGS_RESTRICTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=212717604
     */
    BACK_TO_CURRENT_VERSION = 'GLOBAL.BACK_TO_CURRENT_VERSION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453430
     */
    RIGHT_NOW = 'RIGHT_NOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9844100
     */
    SAVE = 'GLOBAL.SAVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290712528
     */
    RESOLVE = 'GLOBAL.RESOLVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9844177
     */
    SAVED = 'GLOBAL.SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546403775
     */
    SAVED_ON = 'GLOBAL.SAVED_ON',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453448
     */
    SAVE_AS_DRAFT = 'SAVE_AS_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182570700
     */
    SAVED_AS_DRAFT_ON = 'GLOBAL.SAVED_AS_DRAFT_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46470071
     */
    SAVE_CHANGES = 'GLOBAL.SAVE_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182557528
     */
    SAVING = 'GLOBAL.SAVING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182558966
     */
    SCHEDULED_ON = 'BROADCASTS.SCHEDULED_ON',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451950
     */
    SCOPES = 'ADMIN_GAMIFICATION_POINTS_MANAGEMENT_SCOPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=387719013
     */
    SECONDARY_COLOR = 'GLOBAL.SECONDARY_COLOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=188761075
     */
    SELECT = 'GLOBAL.SELECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=387867648
     */
    SELECT_ALL = 'GLOBAL.SELECT_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68078825
     */
    SELECT_ALL_DESELECT_ALL = 'NOTIFICATION.PREFERENCES.TABLE.CHECK_ALL.ROW.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=400539571
     */
    SELECT_DESELECT = 'GLOBAL.SELECT_DESELECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389686005
     */
    SELECT_AUTHOR = 'GLOBAL.SELECT_AUTHOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=365868586
     * */
    SELECT_FOLDER = 'GLOBAL.SELECT_FOLDER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453473
     */
    SELECT_IMAGE = 'SELECT_IMAGE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453051
     */
    SELECT_SITES = 'INSTANCE_PICKER_SUBHEADER_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493287742
     */
    SELECT_USER = 'GLOBAL.SELECT_USER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=494145706
     */
    SELECT_USERS = 'GLOBAL.SELECT_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248740093
     */
    SELECT_AN_ICON_TO_MAKE_THIS_OPTION_AVAILABLE = 'GLOBAL.SELECT_AN_ICON_TO_MAKE_THIS_OPTION_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=16645391
     */
    SCORE = 'GLOBAL.SCORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182553336
     */
    SCHEDULE = 'GLOBAL.SCHEDULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182505775
     */
    SCHEDULED = 'GLOBAL.SCHEDULED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452921
     */
    SEARCH = 'FRONT.SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=126132286
     */
    SEARCH_BY_NAME = 'GLOBAL.SEARCH_BY_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286164812
     */
    SEARCH_BY_TITLE = 'GLOBAL.SEARCH_BY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=293768756
     */
    SEARCH_FOR_SOMEONE = 'GLOBAL.SEARCH_FOR_SOMEONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=461243292
     */
    SEARCH_RESULTS = 'GLOBAL.SEARCH_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33867154
     */
    SEE_ALL = 'GLOBAL.SEE_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50285814
     */
    SEE_LESS = 'GLOBAL.SEE_LESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50284537
     */
    SEE_MORE = 'GLOBAL.SEE_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96189225
     */
    SEE_MORE_NB_ITEMS = 'FRONT.SEARCH.SEE_MORE_NB_ITEMS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453719
     */
    SEE_TRANSLATION = 'TRANSLATE_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179646603
     */
    SELECTED_ITEMS_COUNT = 'GLOBAL.SELECTED_ITEMS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9761464
     */
    SEND = 'GLOBAL.SEND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183204004
     */
    SEND_TO = 'GLOBAL.SEND_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=166150242
     */
    SENT = 'GLOBAL.SENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182552988
     */
    SENT_ON = 'GLOBAL.SENT_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10285881
     */
    SENT_DATE = 'ADMIN.NEWSLETTER.SENT_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183205244
     */
    SENT_TO = 'GLOBAL.SENT_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15926571
     */
    SERVER_ERROR_NOT_AUTHORIZED = 'SERVER_ERROR_NOT_AUTHORIZED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50478959
     */
    SERVICE_UNAVAILABLE = 'GLOBAL.SERVICE_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7253276
     */
    SETTINGS = 'GLOBAL.SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29303710
     */
    SET_UP = 'GLOBAL.SET_UP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107378097
     */
    SEVERAL_MANDATORY_FIELDS_MISSING = 'GLOBAL.SEVERAL_MANDATORY_FIELDS_MISSING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27892824
     */
    SIMPLE_DATE_FORMAT_COMPLETE = 'SIMPLE_DATE_FORMAT_COMPLETE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169581595
     */
    SIZE = 'GLOBAL.SIZE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451930
     */
    SITE = 'ADMIN_GAMIFICATION_LEADERBOARD_SCOPE_INSTANCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505778310
     */
    SITES = 'GLOBAL.SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=16248462
     */
    SHARE = 'GLOBAL.SHARE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17811504
     */
    SHARED = 'GLOBAL.SHARED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453630
     */
    SHOW = 'SHOW',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453631
     */
    SHOW_ALL = 'SHOW_All',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=207556397
     */
    SHOW_ARCHIVED = 'GLOBAL.SHOW_ARCHIVED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=221242759
     */
    SHOW_AS = 'GLOBAL.SHOW_AS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96182011
     */
    SHOW_FILTERS = 'FRONT.SEARCH.SHOW_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441618397
     */
    SHOW_ISSUE = 'GLOBAL.SHOW_ISSUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294792669
     */
    SHOW_LESS = 'GLOBAL.SHOW_LESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=254905475
     */
    SHOW_MESSAGE = 'GLOBAL.SHOW_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294792425
     */
    SHOW_MORE = 'GLOBAL.SHOW_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247843756
     */
    SHOW_ONLY_VISIBLE_ITEMS = 'GLOBAL.SHOW_ONLY_VISIBLE_ITEMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=381220670
     */
    SHOW_SUGGESTIONS = 'GLOBAL.SHOW_SUGGESTIONS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453154
     */
    SIGN_IN = 'LOGIN_CLASSIC_SIGN_IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66037726
     * @deprecated use GLOBAL.SKIP_TO_CONTENT instead
     */
    SKIP_NAVIGATION = 'FRONT.HEADER.SKIP_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=463316864
     */
    SKIP_TO_NAVIGATION = 'GLOBAL.SKIP_TO_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=463316866
     */
    SKIP_TO_CONTENT = 'GLOBAL.SKIP_TO_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=463583961
     */
    SKIP_LINKS = 'GLOBAL.SKIP_LINKS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218715100
     */
    SLIDE_NB = 'GLOBAL.SLIDE_NB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218716348
     */
    SLIDE_NB_OF_NB_TOTAL = 'GLOBAL.SLIDE_NB_OF_NB_TOTAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19039044
     */
    SLIDESHOW = 'FRONT.SETTINGS.VIEW_MODE.SLIDESHOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=352461775
     */
    SOCIAL = 'GLOBAL.SOCIAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109637
     */
    SORT_BY_ALPHABETIC_ORDER = 'GLOBAL.SORT_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46665752
     */
    SORTED_BY = 'GLOBAL.SORTED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78329549
     */
    SORT_ALPHABETICALLY_ASC = 'GLOBAL.SORT_ALPHABETICALLY_ASC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78329560
     */
    SORT_ALPHABETICALLY_DESC = 'GLOBAL.SORT_ALPHABETICALLY_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19495141
     */
    SORT_BY = 'GLOBAL.SORT_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78329506
     */
    SORT_NEWEST = 'GLOBAL.SORT_NEWEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78329503
     */
    SORT_OLDEST = 'GLOBAL.SORT_OLDEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203150392
     */
    SORTING = 'GLOBAL.SORTING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7423751
     */
    SOURCE = 'GLOBAL.SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457516993
     */
    SOURCES = 'GLOBAL.SOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=397883606
     */
    SPACE = 'GLOBAL.SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240368490
     */
    SPACES = 'GLOBAL.FEATURE_SPACES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523621227
     */
    SPECIFIC_FILE_ENCODING = 'GLOBAL.SPECIFIC_FILE_ENCODING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21181459
     */
    START = 'GLOBAL.START',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453675
     */
    START_DATE = 'START_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453677
     */
    START_TIME = 'START_TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11132971
     */
    STATUS = 'GLOBAL.STATUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227233983
     */
    STATUS_CHANGED_FOR = 'GLOBAL.STATUS_CHANGED_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=467874162
     */
    STYLES = 'GLOBAL.STYLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172342675
     */
    SUBJECT = 'GLOBAL.SUBJECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585126
     */
    SUBMIT = 'GLOBAL.SUBMIT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453667
     */
    SUBSCRIPTIONS = 'SOCIAL_PROFILE_SUBSCRIPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7574144
     */
    SUBTITLE = 'FRONT.SETTINGS.SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472755104
     */
    SUCCESS = 'MICRO_APPS_RUNS.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501615546
     */
    SUGGESTION = 'GLOBAL.SUGGESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28503364
     */
    SUMMARY = 'COMMUNITY_DETAILS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7263677
     */
    TAGS = 'GLOBAL.TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27848363
     */
    TAGZ = 'GLOBAL.TAGZ',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=345541519
     */
    TASKS = 'GLOBAL.TASKS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=89032641
     */
    TEMPLATE = 'GLOBAL.TEMPLATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28321898
     */
    TERMS_AND_CONDITIONS = 'GLOBAL.TERMS_AND_CONDITIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94501084
     */
    TEXT = 'GLOBAL.TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=64588162
     */
    TEXT_COLOR = 'FRONT.TEXT_EDITOR.HEADINGS.COLOR_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540073243
     */
    THIS_LINK = 'GLOBAL.THIS_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7255991
     */
    THUMBNAIL = 'GLOBAL.THUMBNAIL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453715
     */
    TIME = 'TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194564011
     */
    TIME_APPROXIMATELY = 'GLOBAL.TIME_APPROXIMATELY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13756911
     */
    TIME_FORMAT_SHORT = 'GLOBAL.TIME_FORMAT_SHORT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121555966
     */
    TIMEZONE = 'GLOBAL.TIMEZONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10122042
     */
    TITLE = 'GLOBAL.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185359044
     */
    TITLE_CONTAINS_ONLY_SPACES = 'GLOBAL.TITLE_CONTAINS_ONLY_SPACES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190400068
     */
    TITLE_MANDATORY = 'GLOBAL.TITLE_MANDATORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185887066
     */
    TO = 'GLOBAL.TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343895595
     */
    TO_ACKNOWLEDGE = 'GLOBAL.TO_ACKNOWLEDGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206220152
     */
    TO_COMPLETE = 'GLOBAL.TO_COMPLETE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488000880
     */
    TO_DO = 'GLOBAL.TO_DO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=185886990
     */
    TO_NB_SUBJECT = 'GLOBAL.TO_NB_SUBJECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=352660977
     */
    TO_OPEN = 'GLOBAL.TO_OPEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=445211962
     */
    TO_PERSON = 'GLOBAL.TO_PERSON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=259229889
     */
    TO_VIEW = 'GLOBAL.TO_VIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=156955155
     */
    TODAY = 'GLOBAL.TODAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72121227
     */
    TOGGLE = 'GLOBAL.TOGGLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453718
     */
    TOGGLE_ALL = 'TOGGLE_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=212717940
     */
    TRY_BETA = 'GLOBAL.TRY_BETA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=173355092
     */
    TOTAL_VIEWS = 'GLOBAL.TOTAL_VIEWS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=469133555
     */
    TRAINING = 'GLOBAL.TRAINING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134176769
     */
    TRANSLATE = 'GLOBAL.TRANSLATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63098465
     */
    TRY_AGAIN = 'GLOBAL.TRY_AGAIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=249650009
     */
    TRY_ANOTHER_FILTER = 'GLOBAL.TRY_ANOTHER_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193985446
     */
    TRY_ANOTHER_QUERY = 'GLOBAL.TRY_ANOTHER_QUERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=175599594
     */
    TRY_ANOTHER_QUERY_OR_FILTER = 'GLOBAL.TRY_ANOTHER_QUERY_OR_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97445781
     */
    TRY_RELOAD = 'GLOBAL.TRY_RELOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19432518
     */
    TYPE = 'GLOBAL.TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=243439900
     */
    TYPES = 'MS_TYPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8929882
     */
    TUTORIAL = 'GLOBAL.TUTORIAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453725
     */
    TUTORIALS = 'TUTORIAL_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421804473
     */
    UNABLE_TO_SAVE = 'GLOBAL.UNABLE_TO_SAVE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452695
     */
    UNARCHIVE = 'CONTENT_SET_TO_UNARCHIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=216239894
     */
    UNAVAILABLE_COMMUNITY = 'GLOBAL.UNAVAILABLE_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=216239904
     */
    UNAVAILABLE_CONTENT = 'GLOBAL.UNAVAILABLE_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86666408
     */
    UNAVAILABLE_FEATURE_IE = 'FRONT.WARNING.UNAVAILABLE_FEATURE_IE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=348888450
     */
    UNAVAILABLE_GROUP = 'GLOBAL.UNAVAILABLE_GROUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301324547
     */
    UNDATED = 'GLOBAL.UNDATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194723410
     */
    UNIT = 'GLOBAL.UNIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28646618
     */
    UNFOLLOW = 'GLOBAL.UNFOLLOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=25219156
     */
    UNKNOWN = 'FRONT.COMMUNITY.POST_EVENT.ATTENDEES_UNKNOWN',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453727
     */
    UNKNOWN_USER = 'UNKNOWN_USER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=374185938
     */
    UNNAMED = 'GLOBAL.UNNAMED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28345264
     */
    UNPUBLISHED = 'CONTENT.STATUS_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79125442
     */
    UNSAVED_MODIFICATIONS = 'GLOBAL.DISCARD_UNSAVED_CHANGES_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79125450
     */
    UNSAVED_MODIFICATIONS_DESCRIPTION = 'GLOBAL.DISCARD_UNSAVED_CHANGES_DIALOG.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182558602
     */
    UNTITLED = 'GLOBAL.UNTITLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80884116
     */
    UPDATE = 'GLOBAL.UPDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220504595
     */
    UPDATED = 'GLOBAL.UPDATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=220507665
     */
    UPDATED_ON = 'GLOBAL.UPDATED_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=132529904
     */
    UPDATES = 'GLOBAL.UPDATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66344036
     */
    UPDATED_BY = 'GLOBAL.UPDATED_BY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453736
     */
    UPDATED_DATE = 'UPDATED_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=380793703
     */
    UPGRADE = 'GLOBAL.UPGRADE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=180226597
     */
    UPLOAD_AN_IMAGE = 'GLOBAL.UPLOAD_AN_IMAGE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452108
     */
    UPLOAD_LOGO = 'ADMIN_INSTANCE_SETTINGS_LOGO_UPLOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423497900
     */
    UPLOAD_FILE_RESTRICTION = 'GLOBAL.UPLOAD_FILE_RESTRICTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262511971
     */
    UPLOADING_FILE = 'GLOBAL.UPLOADING_FILE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453735
     */
    UPDATED_AT = 'UPDATED_AT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102477539
     */
    UPLOAD_FAILED = 'GLOBAL.ERROR.UPLOAD_FAILED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38049227
     */
    UPLOAD_FILE = 'ADMIN.NANNY.UPLOAD_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158527168
     */
    URL = 'GLOBAL.URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421397802
     */
    URL_ERROR = 'GLOBAL.URL_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421396894
     */
    URL_HELPER = 'GLOBAL.URL_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453738
     */
    USE_DEFAULT_VALUES = 'USE_DEFAULT_VALUES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375150983
     */
    USED_BY = 'GLOBAL.USED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13646794
     */
    USERS = 'GLOBAL.USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=116861250
     */
    USER = 'GLOBAL.USER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468981706
     */
    USERNAME = 'GLOBAL.USERNAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8929877
     */
    USER_DIRECTORY = 'GLOBAL.USER_DIRECTORY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453761
     */
    USER_DIRECTORIES = 'USER_DIRECTORY_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95252094
     */
    USERS_COUNT = 'GLOBAL.USERS_COUNT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453743
     */
    USER_ADDED_SUCCESS_MESSAGE = 'USER_DIRECTORY_ADD_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13706982
     */
    USER_AVATAR_ALT_TEXT = 'FRONT.USER_AVATAR_ALT_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13783527
     */
    USER_ENGAGEMENT = 'GLOBAL.USER_ENGAGEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29451882
     */
    USER_PROFILE = 'GLOBAL.USER_PROFIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268685693
     */
    VALUE = 'GLOBAL.VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=525449489
     */
    VALUES = 'GLOBAL.VALUES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205167874
     */
    VIDEO = 'GLOBAL.VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179593132
     */
    VIDEOS = 'GLOBAL.VIDEOS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453818
     */
    VIEW = 'VIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40758114
     */
    VIEW_USER_PROFILE = 'GLOBAL.VIEW_USER_PROFILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227925033
     */
    VIEWED = 'GLOBAL.VIEWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162602495
     */
    VIEWS = 'GLOBAL.VIEWS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453823
     */
    VISIBLE_BY = 'VISIBLE_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78329489
     */
    VISIBILITY = 'GLOBAL.VISIBILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412462635
     */
    WARNING_UNSAVED_CHANGES = 'GLOBAL.WARNING_UNSAVED_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379948
     */
    WEBSITE = 'GLOBAL.WEBSITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194717165
     */
    WEEKS = 'GLOBAL.WEEKS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193219118
     */
    WIP = 'GLOBAL.WORK_IN_PROGRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109120760
     */
    WORKFLOW = 'GLOBAL.WORKFLOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107592211
     */
    WRONG_FORMAT_FILE = 'GLOBAL.WRONG_FORMAT_FILE',
    /**
     *https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=262825937
     */
    X_FILES_ONLY = 'GLOBAL.X_FILES_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13732720
     */
    YEAR = 'GLOBAL.YEAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=458808650
     */
    YEARS = 'GLOBAL.YEARS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=156955146
     */
    YESTERDAY = 'GLOBAL.YESTERDAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11607531
     */
    YES = 'GLOBAL.YES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488770273
     */
    WARNING_DELETE = 'WREX_CORE.WARNING_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453461
     */
    ZENDESK = 'SEARCH_PLUS_ZENDESK_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=272243510
     */
    ZOOM_IN = 'GLOBAL.ZOOM_IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=272253190
     */
    ZOOM_OUT = 'GLOBAL.ZOOM_OUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260111189
     */
    SMALL = 'GLOBAL.SMALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260111365
     */
    COVER = 'GLOBAL.COVER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=291253618
     */
    PROVISIONING = 'GLOBAL.PROVISIONING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=291048667
     */
    EVENT = 'GLOBAL.EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=304278098
     */
    NOT_CREATED = 'GLOBAL.NOT_CREATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=309173294
     */
    USE_VALUE = 'GLOBAL.USE_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=309173421
     */
    ALREADY_USED_VALUE = 'GLOBAL.ALREADY_USED_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290419943
     */
    SELECT_GROUPS = 'GLOBAL.SELECT_GROUPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=329629430
     */
    NEW_ITEMS_LOADED_MESSAGE = 'GLOBAL.A11Y_NEW_ITEMS_LOADED_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367469745
     */
    PLATFORM = 'GLOBAL.PLATFORM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=417268513
     */
    WITHDRAW = 'GLOBAL.WITHDRAW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=425524313
     */
    MOBILE = 'GLOBAL.MOBILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=425524863
     */
    WEB_AND_MOBILE_APP = 'GLOBAL.WEB_AND_MOBILE_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428137694
     */
    HIDE_ADVANCED_SETTINGS = 'GLOBAL.HIDE_ADVANCED_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96182003
     */
    HIDE_FILTERS = 'FRONT.SEARCH.HIDE_FILTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428137800
     */
    SHOW_ADVANCED_SETTINGS = 'GLOBAL.SHOW_ADVANCED_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108583677
     */
    RESULT_IS_RECOMMENDED = 'FRONT.SEARCH.RESULT_IS_RECOMMENDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=434901572
     * */
    COURSE = 'GLOBAL.COURSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=434905251
     * */
    SUB_CATEGORY = 'GLOBAL.SUB_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438117954
     */
    DISPLAY_UNAVAILABLE_STATE_TITLE = 'GLOBAL.DISPLAY_UNAVAILABLE_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438117942
     */
    DISPLAY_UNAVAILABLE_STATE_DESCRIPTION = 'GLOBAL.DISPLAY_UNAVAILABLE_STATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=440285371
     */
    CONNECTION_REQUIRED = 'GLOBAL.CONNECTION_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=453379184
     */
    TOPICS = 'GLOBAL.TOPICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471019972
     */
    TEST_OK = 'GLOBAL.TEST_OK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471548626
     */
    TEST_KO = 'GLOBAL.TEST_KO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481733498
     */
    GIVEN_NAME = 'GLOBAL.GIVEN_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481733503
     */
    FAMILY_NAME = 'GLOBAL.FAMILY_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=479918904
     */
    CONTENT_HIDDEN = 'GLOBAL.CONTENT_HIDDEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487716301
     */
    HIDDEN = 'GLOBAL.HIDDEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86667895
     */
    WRITE_YOUR_TEXT_HERE = 'FRONT.ARTICLES.DIALOG.CONTENT_PLACEHOLDER',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487070612
     */
    ABORTED = 'GLOBAL.ABORTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487070676
     */
    IN_PROGRESS = 'GLOBAL.IN_PROGRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487070604
     */
    STARTING = 'GLOBAL.STARTING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=492331979
     */
    NOTIF_MAX_VALUES_REACHED = 'GLOBAL.NOTIF_MAX_VALUES_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498800917
     */
    THEN = 'GLOBAL.THEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497986896
     */
    CONDITIONS = 'GLOBAL.CONDITIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497986843
     */
    AUDIENCE = 'GLOBAL.AUDIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502663804
     */
    ANSWERS_GENERATED_BY_AI = 'GLOBAL.ANSWERS_GENERATED_BY_AI',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=507253145
     */
    START_WRITING = 'GLOBAL.START_WRITING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183589929
     */
    EMOJI_PICKER_ACTIVITY_CATEGORY = 'LUMX.EMOJI_PICKER_ACTIVITY_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590014
     */
    EMOJI_PICKER_ANIMAL_CATEGORY = 'LUMX.EMOJI_PICKER_ANIMAL_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590149
     */
    EMOJI_PICKER_FLAG_CATEGORY = 'LUMX.EMOJI_PICKER_FLAG_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590155
     */
    EMOJI_PICKER_FOOD_CATEGORY = 'LUMX.EMOJI_PICKER_FOOD_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590234
     */
    EMOJI_PICKER_OBJECT_CATEGORY = 'LUMX.EMOJI_PICKER_OBJECT_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590250
     */
    EMOJI_PICKER_RECENT_CATEGORY = 'LUMX.EMOJI_PICKER_RECENT_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590262
     */
    EMOJI_PICKER_SMILEY_CATEGORY = 'LUMX.EMOJI_PICKER_SMILEY_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590265
     */
    EMOJI_PICKER_SYMBOL_CATEGORY = 'LUMX.EMOJI_PICKER_SYMBOL_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183590273
     */
    EMOJI_PICKER_TRAVEL_CATEGORY = 'LUMX.EMOJI_PICKER_TRAVEL_CATEGORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=400739727
     */
    NEXT_SLIDE = 'LUMX.NEXT_SLIDE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=401240896
     */
    PREVIOUS_SLIDE = 'LUMX.PREVIOUS_SLIDE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=41134593
     */
    VIEW_HOMEPAGE = 'ADMIN.VIEW_HOMEPAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130157
     */
    URL_FIELD_LABEL = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.URL_FIELD.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130166
     */
    URL_FIELD_ERROR_MESSAGE = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.URL_FIELD.ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428217013
     */
    DRAG_STARTING = 'LUMX.DRAGGABLE.DRAG_STARTING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428218332
     */
    DRAG_UPDATES = 'LUMX.DRAGGABLE.DRAG_UPDATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428218351
     */
    DRAG_CANCELED = 'LUMX.DRAGGABLE.DRAG_CANCELED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428218442
     */
    DRAG_ENDED = 'LUMX.DRAGGABLE.DRAG_ENDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=514748841
     */
    INCORRECT_TIME_START_AFTER_END = 'GLOBAL.INCORRECT_TIME_START_AFTER_END',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261239140
     */
    INCORRECT_TIME_START_EQUAL_END = 'EVENTS.INCORRECT_TIME_START_EQUAL_END',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523776240
     */
    SCHEDULED_ON_DATE = 'CONTENT_ADMIN.SCHEDULED_ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523833536
     */
    PUBLISHED_ON_DATE_COLON = 'CONTENT_ADMIN.PUBLISHED_ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523830517
     */
    EXPIRED_ON_DATE = 'CONTENT_ADMIN.EXPIRED_ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523834117
     */
    UPDATED_ON_DATE = 'CONTENT_ADMIN.UPDATE_ON_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9844102
     */
    DATA_SOURCES = 'GLOBAL.DATA_SOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=552644649
     */
    ADD_USERS = 'GLOBAL.ADD_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=552007548
     */
    TOGGLE_FILTER_OPTIONS = 'GLOBAL.TOGGLE_FILTER_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124745451
     */
    ROLE_ADMINISTRATOR = 'PLAY.ROLE_ADMINISTRATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=251762919
     */
    MANAGERS = 'SPACES.MANAGERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=554699273
     */
    CATALOG = 'GLOBAL.CATALOG',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=555005781
     */
    OWNERSHIP = 'GLOBAL.OWNERSHIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199463914
     */
    TEMPORALITY = 'EVENTS.PICKER_TEMPORALITY_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=35826567
     */
    TEMPORALITY_FUTURE = 'FRONT.WIDGET_POST_LIST.SETTINGS.FUTURE_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=35826077
     */
    TEMPORALITY_ONGOING = 'FRONT.WIDGET_POST_LIST.SETTINGS.ONGOING_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36513523
     */
    TEMPORALITY_PAST = 'FRONT.WIDGET_POST_LIST.SETTINGS.PAST_EVENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=552160959
     */
    TRAINING_COURSES = 'LEARNING.TRAINING_COURSES',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=558483767
     */
    OTHER = 'GLOBAL.OTHER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=555499744
     */
    OPEN_IMAGE_IN_FULLSCREEN = 'GLOBAL.OPEN_IMAGE_IN_FULLSCREEN',
}

/**
 * Retrieves the key to use when translating a language given its id.
 * @param languageId - language id (en, fr, es)
 * @returns LANG_EN, LANG_FR, LANG_FR
 */
export const getKeyForLanguage = (languageId: string) => `LANG_${toCompatibleLanguage(languageId).toUpperCase()}`;

export const getServerError = (serverError: string) => `SERVER_ERROR_${serverError}`;
