import React from 'react';

import { HtmlAttributesWithData } from '@lumapps/data-attributes/types';
import { mdiArrowLeft } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, AlertDialog, Kind, ButtonProps, Button, Text, Typography } from '@lumapps/lumx/react';
import { useRouter, Route } from '@lumapps/router';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { useBackButton } from '../../hooks/useBackButton';

export interface GoBackButtonProps extends ButtonProps, Omit<HtmlAttributesWithData, 'color'> {
    /**
     * Whether a confirmation dialog should be displayed before redirecting.
     * */
    shouldConfirm?: boolean;
    /**
     * Action made after confirming redirection.
     * */
    onConfirmation?: () => void;
    /**
     * Route to redirect to when clicking on the button.
     * If undefined, the button will redirect to the previous entry of the browser's history
     * */
    goTo?: Route;
    /**
     * Array of routes to redirect to if it was the previous route.
     * If undefined or none matches, uses the goTo route.
     * */
    goToIfPrevious?: Route[];
    /**
     * If we want to display an alternative text instead of an icon
     * */
    label?: string;
    /**
     * Whether the confirmation dialog should be displayed before redirecting only if the form is diry
     */
    shouldConfirmOnlyWhenFormIsDirty?: boolean;
}

/**
 * Component that displays a "Go back" button with an optional confirmation dialog.
 * The route to redirect to is determined by the following rules:
 * - By default, go back to the previous entry of the browser's history.
 * - If the `goTo` is provided, go back to the provided route.
 * - If the `goToIfPrevious` is provided, go back to the first route in the array that matches
 *   the previous route stored in the react router state.
 *
 * @family Buttons
 * @param GoBackButtonProps
 * @returns GoBackButton
 */
export const GoBackButton: React.FC<GoBackButtonProps> = ({
    shouldConfirm = false,
    goTo,
    goToIfPrevious,
    label,
    onConfirmation,
    shouldConfirmOnlyWhenFormIsDirty = false,
    isDirty,
    ...forwardedProps
}) => {
    const { translateKey } = useTranslate();
    const { redirect } = useRouter();

    const { onClick, to, linkAs } = useBackButton({
        defaultRoute: goTo,
        goToIfPrevious,
    });

    const buttonRef = React.useRef(null);
    const [isLeaveModalOpen, setIsLeaveModalOpen] = React.useState(false);
    const shouldConfirmBeforeLeaving =
        shouldConfirm && (!shouldConfirmOnlyWhenFormIsDirty || (shouldConfirmOnlyWhenFormIsDirty && isDirty));

    /**
     * When confirming that the user wants to leave the page, if there was an on click
     * defined on the goBackRouteProps, we need to execute that once, so that we send the user
     * to the correct page. If there was no onClick defined, we either use the `to` object
     * or by default we use the given route.
     */
    const onLeaveConfirmed = () => {
        if (onConfirmation) {
            onConfirmation();
        }
        if (onClick) {
            onClick();
        } else {
            /**
             * force ignoring the block redirection since the user has confirmed that
             * they want to navigate out of this page on this component.
             */
            redirect(to || goTo, undefined, false, false, false, true);
        }
        setIsLeaveModalOpen(false);
    };
    /**
     * If a confirmation is requested before going back,
     * clicking on the button will open a confirmation modal instead of redirecting.
     */
    const handleOnClick = (ev: React.SyntheticEvent) => {
        if (shouldConfirmBeforeLeaving) {
            ev.preventDefault();
            setIsLeaveModalOpen(true);
        } else if (onClick) {
            onClick();
        }
    };

    /* defaultProps for either IconButton or Button with text */
    const defaultProps = {
        emphasis: Emphasis.low,
        linkAs: !shouldConfirmBeforeLeaving && linkAs ? linkAs : undefined,
        to: !shouldConfirmBeforeLeaving && linkAs && to ? to : undefined,
        onClick: handleOnClick,
        ref: buttonRef,
    };

    return (
        <>
            {label ? (
                <Button {...forwardedProps} {...defaultProps}>
                    {label}
                </Button>
            ) : (
                <IconButton
                    {...forwardedProps}
                    {...defaultProps}
                    label={translateKey(GLOBAL.BACK)}
                    icon={mdiArrowLeft}
                />
            )}
            <AlertDialog
                isOpen={isLeaveModalOpen}
                confirmProps={{
                    label: translateKey(GLOBAL.DISCARD),
                    onClick: onLeaveConfirmed,
                }}
                cancelProps={{
                    label: translateKey(GLOBAL.KEEP_EDITING),
                    onClick: () => setIsLeaveModalOpen(false),
                }}
                title={translateKey(GLOBAL.DISCARD_CHANGES_CONFIRM)}
                kind={Kind.warning}
                parentElement={buttonRef}
                onClose={() => setIsLeaveModalOpen(false)}
            >
                <Text as="p" typography={Typography.body1}>
                    {translateKey(GLOBAL.WARNING_UNSAVED_CHANGES)}
                </Text>
            </AlertDialog>
        </>
    );
};
