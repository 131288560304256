import React from 'react';

import uniqBy from 'lodash/uniqBy';

import { Attributes, DataAttributesOptions, useDataAttributes } from '@lumapps/data-attributes';
import { ContextMenu, ContextMenuProps } from '@lumapps/lumx-menu/components/ContextMenu';
import { MenuOption, MenuOptionBasic, MenuOptionType } from '@lumapps/lumx-menu/components/ContextMenu/types';
import { MenuProps } from '@lumapps/lumx-menu/components/Menu/Menu';
import { MAX_INLINE_ACTIONS } from '@lumapps/lumx/constants';
import {
    mdiDelete,
    mdiPencil,
    mdiEye,
    mdiContentCopy,
    mdiChartLine,
    mdiInformationOutline,
    mdiArchiveOutline,
} from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, FlexBoxProps, IconButton, IconButtonProps, Placement } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export type MenuOptionKey = MenuOption & { key: string; children?: MenuOptionKey[] };
export type IconButtonKey = IconButtonProps & { key: string; children?: IconButtonKey[]; dynamicIcon?: string };
export type MenuKeyProps = Omit<ContextMenuProps, 'menuOptions'> & {
    menuOptions: Array<MenuOption> | Array<Array<MenuOption>>;
};

export interface BaseActionsProps {
    /** items to be displayed before the actions */
    before?: React.ReactNode;
    /** items to be displayed after the actions */
    after?: React.ReactNode;
    /**
     * Callback to be executed once the Preview entity button is clicked
     */
    onPreview?: () => void;
    /**
     * Callback to be executed once the Archive entity button is clicked
     */
    onArchive?: () => void;
    /**
     * Callback to be executed once the unArchive entity button is clicked
     */
    onUnarchive?: () => void;
    /**
     * Callback to be executed once the Copy entity button is clicked
     */
    onCopy?: () => void;
    /**
     * Callback to be executed once the Delete entity button is clicked
     */
    onDelete?: () => void;
    /**
     * Callback to be executed once the Edit entity button is clicked
     */
    onEdit?: () => void;
    /**
     * Callback to be executed once the Analytics button is clicked
     */
    onAnalytics?: () => void;
    /**
     * Callback to be executed once the More info button is clicked
     */
    onMoreInformation?: () => void;
    /**
     * label to be used for the edit button
     */
    editLabel?: string;
    /**
     * label to be used for the delete button
     */
    deleteLabel?: string;
    /**
     * label to be used for the preview button
     */
    previewLabel?: string;
    /**
     * label to be used for the copy button
     */
    copyLabel?: string;
    /**
     * label to be used for the analytics button
     */
    analyticsLabel?: string;
    /**
     * label to be used for the more info button
     */
    moreInformationLabel?: string;
    /**
     * label to be used for the archive button
     */
    archiveLabel?: string;
    /**
     * label to be used for the unarchive button
     */
    unarchiveLabel?: string;
    /**
     * scope to be used for tracking purposes
     */
    scope?: string;
    /**
     * additional props for the Edit button
     */
    editButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the Archive button
     */
    archiveButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the Unarchive button
     */
    unarchiveButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the Edit button
     */
    previewButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the analytics button
     */
    analyticsButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the Edit button
     */
    copyButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the delete button
     */
    deleteButtonProps?: Partial<IconButtonProps>;
    /**
     * additional props for the More info button
     */
    moreInformationButtonProps?: Partial<IconButtonProps>;
    /**
     * Whether the buttons are disabled or not
     */
    isDisabled?: boolean;
    /**
     * additional props for the flexbox wrapper
     */
    wrapperProps?: Partial<FlexBoxProps>;
    /**
     * whether the actions are inline on a table or not
     */
    isInline?: boolean;
    /**
     * additional elements to be displayed the base actions. The order in which they
     * are displayed will be determined by the `order` props.
     */
    additionalActions?: IconButtonKey[];
    /**
     * additional option to be displayed the menu options.
     */
    menuOptions?: Partial<MenuProps>;
    /**
     * How many actions are to be displayed before displaying a dropdown menu
     */
    maximumAmountOfActionsToDisplay?: number;
    /** order on which the actions will be displayed */
    order?: string[];
    /** list of icons that should not be added to the context menu no matter what. */
    excludeFromMenu?: string[];
    /** before each action defined on this array, a divider will be added on the context menu */
    addDividersBefore?: string[];
    /**
     * additional menu props
     */
    menuProps?: Partial<ContextMenuProps>;
    /** string used to set the `aria-describedby` attribute for each action */
    describedBy?: string;
    /** whether the new action is disabled or not. */
    isNewDisabled?: boolean;
}

export const DEFAULT_ORDER = [
    'edit',
    'more-information',
    'preview',
    'analytics',
    'copy',
    'archive',
    'unarchive',
    'delete',
];

export const convertIconButtonPropsToContextMenu = (
    icon: IconButtonKey,
    getDataAttributes: (options: DataAttributesOptions) => Attributes,
): MenuOptionKey => {
    const otherProps: MenuOptionBasic['otherProps'] = {
        color: icon.color,
        ...getDataAttributes({ element: icon.key }),
    };

    // Only set trigger props if option has children
    if (icon.children) {
        otherProps.triggerProps = {
            popoverProps: {
                placement: Placement.RIGHT_END,
            },
        };
    }

    return {
        ...icon,
        labelKey: icon.label,
        type: MenuOptionType.option,
        iconStatic: icon.icon,
        icon: icon.dynamicIcon,
        onSelect: icon.onClick,
        key: icon.key,
        isDisabled: icon.isDisabled,
        children: icon.children
            ? icon.children.map((icon) => convertIconButtonPropsToContextMenu(icon, getDataAttributes))
            : undefined,
        otherProps,
    };
};

/**
 * Component that displays a list of predefined actions for a given entity,
 * display as a list of icon buttons.
 *
 * @family Buttons
 * @param BaseActionsProps
 * @returns BaseActions
 */
export const BaseActions: React.FC<BaseActionsProps> = ({
    onPreview,
    onCopy,
    onDelete,
    onEdit,
    onAnalytics,
    onMoreInformation,
    onArchive,
    onUnarchive,
    editLabel = GLOBAL.EDIT,
    deleteLabel = GLOBAL.DELETE,
    previewLabel = GLOBAL.PREVIEW,
    copyLabel = GLOBAL.DUPLICATE,
    analyticsLabel = GLOBAL.ANALYTICS,
    moreInformationLabel = GLOBAL.MORE_INFO,
    archiveLabel = GLOBAL.ARCHIVE,
    unarchiveLabel = GLOBAL.UNARCHIVE,
    scope = 'actions',
    editButtonProps,
    copyButtonProps,
    previewButtonProps,
    deleteButtonProps,
    analyticsButtonProps,
    moreInformationButtonProps,
    before,
    after,
    archiveButtonProps,
    unarchiveButtonProps,
    isDisabled = false,
    wrapperProps = {},
    isInline = false,
    additionalActions,
    maximumAmountOfActionsToDisplay = MAX_INLINE_ACTIONS,
    order = DEFAULT_ORDER,
    addDividersBefore = ['delete'],
    excludeFromMenu = [],
    menuProps,
    describedBy,
}) => {
    const { translateKey } = useTranslate();
    const { get: getDataAttributes } = useDataAttributes(scope);

    const onClick = (callback: (e?: React.SyntheticEvent) => void) => (e: React.SyntheticEvent) => {
        if (isInline) {
            e.preventDefault();
            e.stopPropagation();
        }

        callback(e);
    };

    let iconButtonProps: IconButtonKey[] = [];

    if (additionalActions) {
        iconButtonProps.push(...additionalActions);
    }

    if (onEdit) {
        iconButtonProps.push({
            key: 'edit',
            icon: mdiPencil,
            onClick: onEdit,
            label: editLabel,
            ...editButtonProps,
        });
    }

    if (onMoreInformation) {
        iconButtonProps.push({
            key: 'more-information',
            icon: mdiInformationOutline,
            label: moreInformationLabel,
            onClick: onMoreInformation,
            ...moreInformationButtonProps,
        });
    }

    if (onPreview) {
        iconButtonProps.push({
            key: 'preview',
            icon: mdiEye,
            label: previewLabel,
            onClick: onPreview,
            ...previewButtonProps,
        });
    }

    if (onAnalytics) {
        iconButtonProps.push({
            key: 'analytics',
            icon: mdiChartLine,
            label: analyticsLabel,
            onClick: onAnalytics,
            ...analyticsButtonProps,
        });
    }

    if (onCopy) {
        iconButtonProps.push({
            key: 'copy',
            icon: mdiContentCopy,
            label: copyLabel,
            onClick: onCopy,
            ...copyButtonProps,
        });
    }

    if (onArchive) {
        iconButtonProps.push({
            key: 'archive',
            icon: mdiArchiveOutline,
            label: archiveLabel,
            onClick: onArchive,
            ...archiveButtonProps,
        });
    }

    if (onUnarchive) {
        iconButtonProps.push({
            key: 'unarchive',
            icon: mdiArchiveOutline,
            label: unarchiveLabel,
            onClick: onUnarchive,
            ...unarchiveButtonProps,
        });
    }

    if (onDelete) {
        iconButtonProps.push({
            key: 'delete',
            color: 'red',
            icon: mdiDelete,
            label: deleteLabel,
            onClick: onDelete,
            ...deleteButtonProps,
        });
    }

    iconButtonProps = uniqBy(
        iconButtonProps.sort((a, b) => {
            const keyA = a.key;
            const keyB = b.key;

            // Find the index of each key in the order
            const indexA = order.indexOf(keyA);
            const indexB = order.indexOf(keyB);

            // Compare the indices to determine the order
            return indexA - indexB;
        }),
        (icon) => icon.key,
    );

    const contextMenuActions: MenuOptionKey[] = (menuProps?.menuOptions as MenuOptionKey[]) || [];
    const excludedFromMenuOptions: IconButtonProps[] = [];

    /**
     * If we are in an inline context (for example, actions displayed on a table for each item)
     * there is a limit of actions to be displayed. If the limit is passed, we display the remaining
     * actions in a context menu.
     */
    if (iconButtonProps.length > maximumAmountOfActionsToDisplay && isInline) {
        const iconsForContextMenu = iconButtonProps.slice(maximumAmountOfActionsToDisplay);
        iconButtonProps = iconButtonProps.slice(0, maximumAmountOfActionsToDisplay);

        iconsForContextMenu.forEach((icon) => {
            /**
             * If the icon is flagged as excludable from menu, it means that the icon needs to be specifically
             * displayed outside of the context menu. This is the case for the close icon on the Drawer for example.
             * In that case, we add it to another list of icons that will be displayed after the menu.
             */
            if (!excludeFromMenu.includes(icon.key)) {
                if (addDividersBefore.indexOf(icon.key) >= 0 && contextMenuActions.length > 0) {
                    contextMenuActions.push({
                        type: MenuOptionType.divider,
                        key: `${icon.key}-divider`,
                    });
                }

                contextMenuActions.push(convertIconButtonPropsToContextMenu(icon, getDataAttributes));
            } else {
                excludedFromMenuOptions.push(icon);
            }
        });
    }

    const getBaseActionButtonProps = (props: IconButtonProps): IconButtonProps => {
        return {
            color: 'dark',
            emphasis: Emphasis.low,
            ...getDataAttributes({
                element: isInline ? `inline-${props.key}` : props.key,
            }),
            ...props,
            onClick: onClick(props.onClick),
            isDisabled: isDisabled || props.isDisabled,
            label: translateKey(props.label),
            'aria-describedby': describedBy,
        };
    };

    return (
        <FlexBox orientation="horizontal" {...wrapperProps}>
            {before}

            {iconButtonProps.map((props) => {
                if (props.children) {
                    return (
                        <ContextMenu
                            {...getBaseActionButtonProps(props)}
                            scope={scope}
                            menuOptions={props.children.map((icon) =>
                                convertIconButtonPropsToContextMenu(icon, getDataAttributes),
                            )}
                        />
                    );
                }

                return (
                    <IconButton key={props.key} {...getBaseActionButtonProps(props)} aria-describedby={describedBy} />
                );
            })}

            {contextMenuActions.length > 0 ? (
                <ContextMenu
                    label={translateKey(GLOBAL.MORE_ACTIONS)}
                    {...menuProps}
                    scope={scope}
                    menuOptions={contextMenuActions}
                />
            ) : null}

            {excludedFromMenuOptions.map((props) => (
                <IconButton key={props.key} {...getBaseActionButtonProps(props)} />
            ))}

            {after}
        </FlexBox>
    );
};
