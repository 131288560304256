import React from 'react';

import castArray from 'lodash/castArray';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { type TextFieldProps } from '@lumapps/lumx/react';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { useFocusLastChipOnBackspace } from '../../hooks/useFocusLastChipOnBackspace';
import { BaseSelectProps, ComboboxProps, MultipleSelection, SingleSelection } from '../../types';
import { getWithSelector } from '../../utils/getWithSelector';
import { renderSelectOptions } from '../../utils/renderSelectOptions';
import { Combobox } from '../Combobox';
import { SelectionChipGroup } from './SelectionChipGroup';

type InheritComboboxProps = Pick<ComboboxProps, 'onOpen' | 'status' | 'manualFilter'>;
type InheritTextFieldProps = Pick<
    TextFieldProps,
    | 'className'
    | 'icon'
    | 'label'
    | 'helper'
    | 'placeholder'
    | 'error'
    | 'hasError'
    | 'isDisabled'
    | 'isRequired'
    | 'onBlur'
    | 'onFocus'
    | 'id'
    | 'name'
    | 'inputRef'
>;

type BaseSelectTextFieldProps<O = any> = InheritComboboxProps &
    InheritTextFieldProps &
    BaseSelectProps<O> & { scope?: string };
export type SingleSelectTextFieldProps<O = any> = BaseSelectTextFieldProps<O> & SingleSelection<O>;
export type MultipleSelectTextFieldProps<O = any> = BaseSelectTextFieldProps<O> & MultipleSelection<O>;
export type SelectTextFieldProps<O = any> = SingleSelectTextFieldProps<O> | MultipleSelectTextFieldProps<O>;

const CLASSNAME = 'lumx-select-text-field';
/**
 * Select text field
 *
 * An accessible text field with a list box showing a list of options,
 * allowing the user to search and pick an item from this list.
 * It follows the combobox pattern.
 *
 * @family Combobox
 */
export const SelectTextField = <O,>(props: SelectTextFieldProps<O>) => {
    const {
        options,
        getOptionId,
        getOptionName = getOptionId,
        getOptionDescription,
        getSectionId,
        renderOption,
        value,
        onChange,
        onOpen,
        onBlur,
        onSearch,
        onLoadMore,
        status,
        manualFilter,
        hasSectionDividers,
        selectionType,
        hasClearButton = true,
        inputRef: forwardedInputRef,
        name,
        chipProps,
        listProps,
        scope = CLASSNAME,
        ...forwardedProps
    } = props;
    const [listElement, setListElement] = React.useState<HTMLElement | null>(null);
    const isValueDefined = value !== undefined;
    const { block } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope);

    // Use option id
    const selectedIds = isValueDefined
        ? castArray(value)
              .map((v) => getWithSelector(getOptionId, v))
              .filter((v) => v !== undefined && v !== null)
        : [];
    const defaultInputValue = isValueDefined && selectionType === 'single' ? getWithSelector(getOptionName, value) : '';
    const [searchInputValue, setSearchInputValue] = React.useState('');

    const onInputChange: ComboboxProps['onInputChange'] = React.useCallback(
        (searchText, _, event) => {
            setSearchInputValue(searchText);
            if (event) {
                // Only when actually typing (not when selecting a value)
                onSearch?.(searchText);
            }
        },
        [onSearch],
    );

    // Map option id selection to option object selection
    const onSelect: ComboboxProps['onSelect'] = React.useCallback(
        (selectedOption) => {
            const selectedOptionId = selectedOption?.id;
            const newOption = selectedOptionId
                ? options?.find((option) => {
                      const optionId = getWithSelector(getOptionId, option);
                      return optionId === selectedOptionId;
                  })
                : undefined;

            if (selectionType === 'single') {
                onChange?.(newOption);
            } else if (selectionType === 'multiple' && newOption) {
                const newValue = isValueDefined ? [...value] : [];

                // Check if new option is already in value
                const existingIndex = newValue.findIndex((v) => getWithSelector(getOptionId, v) === selectedOptionId);

                // Toggle selected option in new value
                if (existingIndex === -1) {
                    newValue.push(newOption);
                } else {
                    newValue.splice(existingIndex, 1);
                }

                onChange?.(newValue);
            }
            // Reset search input value (defaults to selected option value)
            setSearchInputValue('');
        },
        [options, selectionType, getOptionId, onChange, isValueDefined, value],
    );

    const handleBlur = React.useCallback(
        (event: React.FocusEvent) => {
            onBlur?.(event);
            // Reset search input value (defaults to selected option value)
            setSearchInputValue('');
        },
        [onBlur],
    );

    // Focus last chip on backspace pressed at the first position of the input
    const inputRef = React.useRef<HTMLInputElement>(null);
    const lastChipRef = React.useRef<HTMLElement>(null);
    useFocusLastChipOnBackspace(lastChipRef, inputRef);

    return (
        <Combobox
            selectedIds={selectedIds}
            onSelect={onSelect}
            onOpen={onOpen}
            onInputChange={onInputChange}
            inputValue={searchInputValue || defaultInputValue}
            openOnClick
            status={status}
            manualFilter={onSearch ? false : manualFilter}
            selectionType={selectionType}
        >
            <Combobox.Input
                {...get({ element: 'input' })}
                {...forwardedProps}
                className={block([forwardedProps.className])}
                name={name}
                inputRef={mergeRefs([inputRef, forwardedInputRef])}
                onBlur={handleBlur}
                // Activate clear button on single select & only when we have a value
                hasClearButton={selectionType === 'single' && isValueDefined && hasClearButton}
                // Display multi selection
                chips={
                    selectionType === 'multiple' ? (
                        <SelectionChipGroup
                            chipProps={chipProps}
                            value={value}
                            getOptionName={getOptionName}
                            getOptionId={getOptionId}
                            onChange={onChange}
                            lastChipRef={lastChipRef}
                            inputRef={inputRef}
                            inputLabel={forwardedProps.label}
                        />
                    ) : null
                }
            />
            <Combobox.List
                aria-multiselectable={selectionType === 'multiple'}
                listRef={setListElement}
                {...get({ element: 'list' })}
                {...listProps}
            >
                {renderSelectOptions({
                    options,
                    getSectionId,
                    renderOption,
                    getOptionId,
                    getOptionName,
                    getOptionDescription,
                    hasSectionDividers,
                })}

                {onLoadMore ? (
                    <InfiniteScroll callback={onLoadMore} options={{ root: listElement, rootMargin: '100px' }} />
                ) : null}
            </Combobox.List>
        </Combobox>
    );
};
