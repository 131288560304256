/* istanbul ignore file */
import { withEmbeddedMap } from '@lumapps/wrex-embedded-map/plugin/withEmbeddedMap';
import { withEmbeddedVideo } from '@lumapps/wrex-embedded-video/plugin/withEmbeddedVideo';
import { withEmoji } from '@lumapps/wrex-emoji/plugin/withEmoji';
import { ALIGNED_IMAGE_WRAPPER, IMAGE_FEATURES, IMAGE_GROUP } from '@lumapps/wrex-enhanced-image/constants';
import { ELEMENTS as IMAGE_ELEMENTS } from '@lumapps/wrex-enhanced-image/plugin/html/deserialize/elements';
import { withEnhancedImage } from '@lumapps/wrex-enhanced-image/plugin/withEnhancedImage';
import { ImageEditor, ImageEditorOptions } from '@lumapps/wrex-enhanced-image/types';
import { isAlignedImageWrapper } from '@lumapps/wrex-enhanced-image/utils/isAlignedImageWrapper';
import { withFile } from '@lumapps/wrex-file/plugin/withFile';
import { withHTMLPaste } from '@lumapps/wrex-html-paste/plugin/withHTMLPaste';
import { HTMLPasteOptions } from '@lumapps/wrex-html-paste/types';
import { withInlineAutocomplete } from '@lumapps/wrex-inline-autocomplete/plugin/withInlineAutocomplete';
import type { InlineAutocompleteEditor, InlineAutocompleteOptions } from '@lumapps/wrex-inline-autocomplete/types';
import { ELEMENTS as LINK_PREVIEW_ELEMENTS } from '@lumapps/wrex-link-preview/plugin/html/deserialize/elements';
import { withLinkPreview } from '@lumapps/wrex-link-preview/plugin/withLinkPreview';
import { Link } from '@lumapps/wrex-link/components/blocks/Link';
import { ELEMENTS as LINK_ELEMENTS } from '@lumapps/wrex-link/plugin/html/deserialize/elements';
import { withLink } from '@lumapps/wrex-link/plugin/withLink';
import { LinkEditor } from '@lumapps/wrex-link/types';
import { ELEMENTS as LIST_ELEMENTS } from '@lumapps/wrex-list/plugin/html/deserialize/elements';
import { withList } from '@lumapps/wrex-list/plugin/withList';
import { ListEditor } from '@lumapps/wrex-list/types';
import { withPlayVideo } from '@lumapps/wrex-play-video/plugin/withPlayVideo';
import { QUOTE_BLOCK } from '@lumapps/wrex-quote/constants';
import { ELEMENTS as QUOTE_ELEMENTS } from '@lumapps/wrex-quote/plugin/html/deserialize/elements';
import { withQuote } from '@lumapps/wrex-quote/plugin/withQuote';
import { TABLE } from '@lumapps/wrex-table/constants';
import { ELEMENTS as TABLE_ELEMENTS } from '@lumapps/wrex-table/plugin/html/deserialize/elements';
import { withTable } from '@lumapps/wrex-table/plugin/withTable';
import {
    BOLD,
    CODE_BLOCK,
    COLORED,
    HEADLINE,
    INLINE_CODE,
    ITALIC,
    PARAGRAPH,
    STRIKETHROUGH,
    SUBTITLE,
    TITLE,
    UNDERLINE,
} from '@lumapps/wrex-typography/constants';
import { getElementConvertOptions } from '@lumapps/wrex-typography/plugin/html/deserialize/elements';
import { getMarkConvertOptions, MarkTypes } from '@lumapps/wrex-typography/plugin/html/deserialize/marks';
import { withTypography } from '@lumapps/wrex-typography/plugin/withTypography';
import { TypographyEditor } from '@lumapps/wrex-typography/types';
import { useFetchUserMention } from '@lumapps/wrex-user-mention/hooks/useFetchUserMention';
import { ELEMENTS as USER_MENTION_ELEMENTS } from '@lumapps/wrex-user-mention/plugin/html/deserialize/elements';
import { withUserMention } from '@lumapps/wrex-user-mention/plugin/withUserMention';
import { UserMentionEditor, type UserMentionElement } from '@lumapps/wrex-user-mention/types';
import { createUserMention } from '@lumapps/wrex-user-mention/utils/createUserMention';
import { ReactEditor, withHistory, withReact } from '@lumapps/wrex/slate';
import { createEditor as create } from '@lumapps/wrex/slate/createEditor';

import { structuredContentEditorEnabledFeatures } from './constants';

export type SlateStructuredContentEditor = ReactEditor &
    TypographyEditor &
    ImageEditor &
    UserMentionEditor &
    InlineAutocompleteEditor &
    ListEditor &
    LinkEditor;

export const createStructuredContentEditor = ({
    uploadFunction,
    placeholder,
    htmlPasteOptions,
    isContributionAlphaEnabled,
    inlineAutoCompleteParams,
}: {
    uploadFunction: ImageEditorOptions['uploadFunction'];
    placeholder: string;
    htmlPasteOptions?: Partial<HTMLPasteOptions>;
    isContributionAlphaEnabled?: boolean;
    inlineAutoCompleteParams?: any;
}) => {
    const markOptions: MarkTypes[] = [BOLD, ITALIC, COLORED, UNDERLINE, STRIKETHROUGH];
    const config = create<SlateStructuredContentEditor>({
        placeholder,
        plugins: [
            withReact,
            withHistory,
            withHTMLPaste({
                elements: [
                    ...getElementConvertOptions([PARAGRAPH, HEADLINE, TITLE, CODE_BLOCK, INLINE_CODE, SUBTITLE]),
                    ...Object.values(LIST_ELEMENTS),
                    ...Object.values(IMAGE_ELEMENTS),
                    ...Object.values(USER_MENTION_ELEMENTS),
                    ...Object.values(LINK_PREVIEW_ELEMENTS),
                    ...Object.values(LINK_ELEMENTS),
                    ...Object.values(TABLE_ELEMENTS),
                    ...Object.values(QUOTE_ELEMENTS),
                ],
                marks: getMarkConvertOptions(markOptions),
                allowedAlignments: [PARAGRAPH, HEADLINE, TITLE, SUBTITLE],
                defaultBlockType: PARAGRAPH,
                ...htmlPasteOptions,
            }),
            withTypography({
                blockTypeParentMatch: {
                    [CODE_BLOCK]: isAlignedImageWrapper,
                    [PARAGRAPH]: isAlignedImageWrapper,
                },
                allowedMarkParents: [PARAGRAPH, Link.displayName, HEADLINE, TITLE, SUBTITLE],
                allowedAlignmentParents: [
                    PARAGRAPH,
                    HEADLINE,
                    TITLE,
                    SUBTITLE,
                    TABLE,
                    QUOTE_BLOCK,
                    IMAGE_GROUP,
                    ALIGNED_IMAGE_WRAPPER,
                ],
                allowInlinesOnlyInParagraph: true,
                enabledFeatures: structuredContentEditorEnabledFeatures.typography,
            }),
            withList({
                defaultBlockType: PARAGRAPH,
                enabledFeatures: structuredContentEditorEnabledFeatures.list,
            }),
            withLink({ allowedParentBlock: PARAGRAPH }),
            withEnhancedImage({
                uploadFunction,
                enabledFeatures: [
                    ...structuredContentEditorEnabledFeatures.enhancedImage,
                    ...(isContributionAlphaEnabled ? [IMAGE_FEATURES.link] : []),
                ],
            }),
            withEmoji(),
            withFile({ uploadFunction }),
            withQuote({ parentMatch: isAlignedImageWrapper }),
            withLinkPreview(),
            withTable(),
            withEmbeddedMap(),
            withEmbeddedVideo(),
            withPlayVideo(),
            withInlineAutocomplete([
                {
                    triggerCharacter: '@',
                    elementCreator: createUserMention,
                    getEntityId: (user) => user?.id,
                    getEntityLabel: (user) => user?.fullName,
                    getListItemProps: inlineAutoCompleteParams,
                    fetchHook: useFetchUserMention,
                } as InlineAutocompleteOptions<UserMentionElement, UserMentionElement['user']>,
            ]),
            withUserMention(),
        ],
    });
    return config;
};
