import React from 'react';

import {
    isNewContributionExperienceEnabled,
    isNewSimpleTemplateFeatureEnabled,
} from '@lumapps/contents/ducks/selectors';
import { Content, ContentStatus } from '@lumapps/contents/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import { designerContentPreview } from '@lumapps/designer-preview/routes';
import { mdiEye } from '@lumapps/lumx/icons';
import { AlertDialog, Button, IconButton, Kind, Text } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isLayoutEnabled } from '@lumapps/widget-layout/ducks/selectors';

import { SCOPE } from '../../constants';
import { CONTENT_PREVIEW } from '../../keys';

export interface ContentPreviewButtonProps {
    /**
     * Content to preview
     */
    content?: Content;
    /**
     * Save content as draft (needed before previewing)
     */
    saveContentDraft?: () => Promise<Content>;
}

/**
 * Is preview unavailable alert dialog open.
 * We need it as a global state because angularjs breaks our internal state on rerender...
 */
let isPreviewUnavailableAlertDialogOpen = false;

const usePreviewUnavailableAlert = () => {
    const [isOpen, setOpen] = React.useState(isPreviewUnavailableAlertDialogOpen);
    const open = React.useCallback(() => {
        isPreviewUnavailableAlertDialogOpen = true;
        setOpen(isPreviewUnavailableAlertDialogOpen);
    }, []);
    const close = React.useCallback(() => {
        isPreviewUnavailableAlertDialogOpen = false;
        setOpen(isPreviewUnavailableAlertDialogOpen);
    }, []);
    return { isOpen, open, close };
};

/**
 * Content preview button
 */
export const ContentPreviewButton: React.FC<ContentPreviewButtonProps> = ({ saveContentDraft, content }) => {
    const { redirect } = useRouter();
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    const isLayoutFFEnabled = useSelector(isLayoutEnabled);
    const isNewContributionExperienceFFEnabled = useSelector(isNewContributionExperienceEnabled);
    const isSimpleTemplateMode = !!content?.template?.basicMode || isNewContributionExperienceFFEnabled;
    const isNewSimpleTemplateEnabled = useSelector(isNewSimpleTemplateFeatureEnabled);
    const isEnabled = isLayoutFFEnabled && (!isSimpleTemplateMode || isNewSimpleTemplateEnabled);

    const previewUnavailable = usePreviewUnavailableAlert();
    const onClick = React.useCallback(async () => {
        let contentToPreview = content;

        // Save content as draft (if not "to validate")
        if (saveContentDraft && content?.status !== ContentStatus.toValidate) {
            contentToPreview = await saveContentDraft();
        }

        if (!contentToPreview?.template?.isV2Compatible) {
            previewUnavailable.open();
        } else if (contentToPreview) {
            redirect(
                designerContentPreview(contentToPreview.id),
                undefined,
                // in a new tab
                true,
            );
        }
    }, [content, saveContentDraft, previewUnavailable, redirect]);

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            {isSimpleTemplateMode ? (
                <Button emphasis="low" leftIcon={mdiEye} onClick={onClick} {...get({ element: 'designer-button' })}>
                    {translateKey(GLOBAL.PREVIEW)}
                </Button>
            ) : (
                <IconButton
                    emphasis="low"
                    theme="dark"
                    icon={mdiEye}
                    label={translateKey(GLOBAL.PREVIEW)}
                    onClick={onClick}
                    {...get({ element: 'designer-button' })}
                />
            )}
            <AlertDialog
                kind={Kind.warning}
                isOpen={previewUnavailable.isOpen}
                title={translateKey(CONTENT_PREVIEW.PREVIEW_UNAVAILABLE)}
                confirmProps={{
                    onClick: previewUnavailable.close,
                    label: translateKey(GLOBAL.CLOSE),
                }}
            >
                <Text as="p" typography="body1">
                    {translateKey(CONTENT_PREVIEW.PREVIEW_UNAVAILABLE_DESCRIPTION)}
                </Text>
            </AlertDialog>
        </>
    );
};
