import React, { forwardRef, KeyboardEventHandler } from 'react';

import camelCase from 'lodash/camelCase';
import concat from 'lodash/concat';
import flatMap from 'lodash/flatMap';
import flow from 'lodash/flow';
import fromPairs from 'lodash/fromPairs';
import upperFirst from 'lodash/upperFirst';
import scrollIntoView from 'scroll-into-view-if-needed';
import { createEditor as createSlateEditor, Editor } from 'slate';
import { Editable, Slate } from 'slate-react';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL } from '@lumapps/translations';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';

import { normalizeForSlate } from '../utils/normalizeForSlate';
import { EditorOptions, Output, EditorContext } from './createEditor.types';
import { renderElements, renderLeafs } from './render';

const indexRenderers = (renderers: Array<React.FC & { displayName: string }>) =>
    fromPairs(renderers.map((render) => [render.displayName, render]));
/**
 * Creates a slate editor with plugins, render functions and key handlers.
 */
export const createEditor = <E extends Editor = Editor>(options: EditorOptions = {}): Output<E> => {
    const {
        defaultElement = Paragraph,
        plugins = [],
        marks = [],
        elements = [],
        extraKeyHandlers = [],
        placeholder,
    } = options;

    // Build editor with all plugins.
    const editor = flow([createSlateEditor, ...plugins])();

    // Use key handlers from both plugins and the options.
    const keyHandlers: KeyboardEventHandler[] = concat(
        extraKeyHandlers,
        flatMap(plugins, ({ getKeyHandler }) => {
            if (getKeyHandler) {
                return getKeyHandler(editor);
            }
            return undefined;
        }).filter(Boolean) as KeyboardEventHandler[],
    );

    // Use element renderers from both plugins and the options.
    const elementArray = concat(elements, flatMap(plugins, 'elements')).filter(Boolean);
    const renderElement = renderElements(defaultElement, indexRenderers(elementArray));

    // Use mark renderers from both plugins and the options.
    const markArray = concat(marks, flatMap(plugins, 'marks')).filter(Boolean);
    const renderLeaf = renderLeafs(indexRenderers(markArray));

    // Base editable component
    const BaseEditable: typeof Editable = forwardRef(({ onKeyDown, ...props }, editableRef) => (
        <Editable
            {...props}
            ref={editableRef}
            scrollSelectionIntoView={(_, domRange) => {
                const leafEl = domRange.startContainer.parentElement;

                if (leafEl) {
                    scrollIntoView(leafEl, {
                        scrollMode: 'if-needed',
                        block: 'nearest',
                    });
                }
            }}
            onKeyDown={React.useCallback<React.KeyboardEventHandler>(
                (event) => {
                    for (const keyHandler of [onKeyDown as React.KeyboardEventHandler].concat(keyHandlers)) {
                        if (event.isDefaultPrevented()) {
                            return;
                        }
                        keyHandler?.(event);
                    }
                },
                [onKeyDown],
            )}
            renderElement={(renderElementProps) => renderElement({ ...renderElementProps, isReadOnly: props.readOnly })}
            renderLeaf={renderLeaf}
            translate="no"
            placeholder={placeholder}
            aria-label={placeholder}
            disableDefaultStyles
        />
    ));

    // Build editable component combining all wrappers from all plugins
    const CustomEditable = plugins.reduce((Editable, plugin) => {
        const wrapper = plugin.editableWrapper;
        if (!wrapper) {
            return Editable;
        }
        const wrapped: any = wrapper(Editable);
        // Pretty displayName (ex: EditableWithUserMention)
        wrapped.displayName = upperFirst(camelCase(`Editable ${plugin.name}`));
        return wrapped;
    }, BaseEditable);
    (CustomEditable as any).displayName = 'Editable';

    // Slate editor context component
    const EditorContext: EditorContext = ({ initialValue: propInitialValue, onChange, children }) => {
        const { error } = useNotification();
        const initialValue = React.useMemo(
            () => {
                // Validate/Normalize input value on init
                return normalizeForSlate(propInitialValue);
            },
            // No need to update since slate value cannot be controlled
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        );

        return (
            <Slate editor={editor} initialValue={initialValue} onChange={onChange as any}>
                <ErrorBoundary
                    onError={() => {
                        if ('undo' in editor) {
                            editor.undo();
                            // Remove from history
                            editor.history.redos.pop();
                        }
                        error({
                            translate: GLOBAL.GENERIC_ERROR,
                        });
                        return true;
                    }}
                >
                    {children}
                </ErrorBoundary>
            </Slate>
        );
    };

    return {
        editor,
        EditorContext,
        Editable: CustomEditable,
    };
};
