import React, { ReactNode } from 'react';

import uniqueId from 'lodash/uniqueId';

import { classnames, separator, useClassnames } from '@lumapps/classnames';
import {
    FlexBox,
    FlexBoxProps,
    Heading,
    HeadingProps,
    Orientation,
    Size,
    Text,
    TextProps,
    Typography,
} from '@lumapps/lumx/react';

import { FORM_SUBSECTION_VERTICAL_ORIENTATION_SIZES } from '../../constants';

import './index.scss';

export interface FormSubSectionProps {
    /** a className to override existing style */
    className?: string;
    /** some props to override the wrapper containing children */
    contentWrapperProps?: FlexBoxProps;
    /** ref of the top container to handle orientation/display change */
    containerRef: any; // any needed to match useDimension type
    /** text to be displayed under the title, in the left part */
    description?: string | ReactNode;
    /** helper text or component to be displayed below the description */
    helper?: string | ReactNode;
    /** HTML id of the sub-section, can be used for tracking if needed */
    id?: string;
    /** title to be displayed on top in the left part */
    title?: string;
    /** some props to override the top wrapper */
    topWrapperProps?: FlexBoxProps;
    /** whether the sub-section has a separator below or not */
    withSeparator?: boolean;
    /** whether the form is displayed horizontally (title on the left, fields on the right) or vertically */
    orientation?: Orientation;
    /** current size for the form  */
    size?: Size;
    /** additional props for the title */
    titleProps?: Partial<HeadingProps>;
    /** additional props for the description */
    descriptionProps?: Partial<TextProps>;
}

const CLASSNAME = 'form-sub-section';

/**
 * A simple component to be used in a form as a sub section, with a title and description on the left side
 * and given children (mostly form inputs) on the right side
 *
 * @family Forms
 * @param FormSubSectionProps
 * @returns FormSubSection
 */
export const FormSubSection: React.FC<FormSubSectionProps> = ({
    children,
    className,
    containerRef,
    contentWrapperProps = {},
    description,
    helper,
    id,
    title,
    topWrapperProps = {},
    descriptionProps = {},
    titleProps = {
        as: 'h3',
    },
    withSeparator = true,
    orientation = Orientation.horizontal,
    size,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const isCompact = size
        ? FORM_SUBSECTION_VERTICAL_ORIENTATION_SIZES.includes(size) && orientation === Orientation.vertical
        : false;
    const titleId = id ? `${id}-${uniqueId()}` : `${CLASSNAME}-${uniqueId()}`;

    return (
        <FlexBox ref={containerRef}>
            <FlexBox
                id={id}
                gap={Size.huge}
                orientation={orientation}
                {...topWrapperProps}
                className={classnames(
                    block({
                        [`${size}`]: Boolean(size),
                        [`${orientation}`]: Boolean(orientation),
                        compact: isCompact,
                    }),
                    className,
                    {
                        [separator()]: withSeparator,
                    },
                )}
            >
                {/* Left part */}
                {title ? (
                    <FlexBox
                        orientation={Orientation.vertical}
                        gap={orientation === Orientation.vertical ? Size.tiny : Size.regular}
                        className={orientation === Orientation.horizontal ? element('detail', `${size}`) : undefined}
                    >
                        <Heading
                            className={element('title')}
                            typography={Typography.title}
                            id={titleId}
                            {...titleProps}
                        >
                            {title}
                        </Heading>

                        {description ? (
                            <Text
                                className={element('description')}
                                typography={Typography.body1}
                                as="p"
                                {...descriptionProps}
                            >
                                {description}
                            </Text>
                        ) : null}

                        {helper}
                    </FlexBox>
                ) : null}

                {/* Right part */}
                <FlexBox
                    as="fieldset"
                    {...contentWrapperProps}
                    aria-labelledby={titleId}
                    gap={contentWrapperProps.gap || Size.big}
                    orientation={contentWrapperProps.orientation || 'vertical'}
                    className={element('content', [contentWrapperProps.className])}
                >
                    {children}
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};
