import React, { RefObject, useMemo } from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { inputLanguageSelector } from '@lumapps/languages';
import { Avatar, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { getInstanceColorPalette } from '@lumapps/style/ducks/selectors';
import { getAvatarUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { ImageEditorOptions } from '@lumapps/wrex-enhanced-image/types';
import { WrexFileContextProvider } from '@lumapps/wrex-file/context';
import { GenerativeAIToolbar } from '@lumapps/wrex-generative-content/components/GenerativeAIToolbar/GenerativeAIToolbar';
import { GENERATIVE_AI_FEATURE_TOKEN } from '@lumapps/wrex-generative-content/constants';
import type { Wrex } from '@lumapps/wrex/types';

import { STRUCTURED_CONTENT_WREX_EDITOR_CLASSNAME } from '../../constants';
import { createStructuredContentEditor } from './createStructuredContentEditor';
import { StructuredContentToolbar } from './StructuredContentToolbar/StructuredContentToolbar';

import './index.scss';

export interface StructuredContentEditorProps {
    wrapperRef?: RefObject<HTMLDivElement>;
    className?: string;
    placeholder: string;
    isRequired?: boolean;
    value: Wrex.Nodes;
    onChange: (content: Wrex.Nodes) => void;
    uploadFunction: ImageEditorOptions['uploadFunction'];
    theme?: Theme;
    parentDialogRef?: RefObject<HTMLDivElement>;
    isReadOnly?: boolean;
}

const CLASSNAME = 'structured-content-editor';

export const StructuredContentEditor: React.FC<StructuredContentEditorProps> = ({
    className,
    placeholder,
    value,
    onChange,
    uploadFunction,
    isRequired,
    wrapperRef,
    theme = Theme.light,
    parentDialogRef,
    isReadOnly,
    ...props
}) => {
    const colorPalette = useSelector(getInstanceColorPalette);
    const customerId = useSelector(customerIdSelector);
    const isGenerativeAiEnabled = useSelector(isFeatureEnabled(GENERATIVE_AI_FEATURE_TOKEN));
    const isContributionAlphaEnabled = useSelector(isFeatureEnabled('contribution-alpha'));
    const inputLanguage = useSelector(inputLanguageSelector);
    const [language, setLanguage] = React.useState<string>(inputLanguage);

    const { EditorContext, Editable } = useMemo(
        () =>
            createStructuredContentEditor({
                // File upload function
                uploadFunction,
                placeholder,
                inlineAutoCompleteParams: (user: { id: string; fullName: string }) => ({
                    before: <Avatar image={getAvatarUrl(user.id, customerId)} size="s" alt="" />,
                }),
                htmlPasteOptions: { colorPalette },
                isContributionAlphaEnabled,
            }),
        [colorPalette, customerId, isContributionAlphaEnabled, placeholder, uploadFunction],
    );

    /**
     * We set an unchanged value to avoid rerendering the WrexFileContextProvider
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialValue = React.useMemo(() => value, []);

    /**
     * We make it for refresh editor in designer simple mode on change the language
     */
    React.useEffect(() => {
        setLanguage(inputLanguage);
    }, [inputLanguage]);

    return (
        <EditorContext initialValue={value} onChange={onChange} key={language}>
            <div
                {...props}
                className={classnames(
                    CLASSNAME,
                    className,
                    padding('all', 'big'),
                    // eslint-disable-next-line lumapps/no-manual-bems
                    isRequired ? `${CLASSNAME}--is-required` : '',
                )}
                ref={wrapperRef}
            >
                <StructuredContentToolbar parentDialogRef={parentDialogRef} theme={theme} isReadOnly={isReadOnly} />

                <WrexFileContextProvider content={initialValue}>
                    <Editable
                        className={classnames(
                            STRUCTURED_CONTENT_WREX_EDITOR_CLASSNAME,
                            `${STRUCTURED_CONTENT_WREX_EDITOR_CLASSNAME}-editor--theme-${theme}`,
                            className,
                        )}
                        readOnly={isReadOnly}
                        aria-readonly={isReadOnly}
                    />
                </WrexFileContextProvider>

                {isGenerativeAiEnabled && !isReadOnly && (
                    <GenerativeAIToolbar theme={theme} parentDialogRef={parentDialogRef} />
                )}
            </div>
        </EditorContext>
    );
};
StructuredContentEditor.displayName = 'StructuredContentEditor';
