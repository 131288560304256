import set from 'lodash/set';

/////////////////////////////

function WidgetIntroController($element, $injector, $scope, $timeout, Content, Features, Translation, Utils, Widget) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay before showing the app filter.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _APP_FILTER_SHOW_DELAY = 100;

    /**
     * A DOM element that darkens the rest of the page when the HTML widget is in edit mode.
     *
     * @type {Element}
     */
    const _appFilter = angular.element('<div/>', {
        class: 'app-filter app-filter--no-transition',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if we are currently editing the content of the HTML widget.
     *
     * @type {boolean}
     */
    vm.editingContent = false;

    /**
     * Contains the options of the Froala editor for the Intro widget in edit mode.
     *
     * @type {Object}
     */
    vm.froalaOptions = {
        charCounterCount: Content.getViewMode() !== 'basic',
        events: {},
        htmlAllowedTags: [],
        pastePlain: true,
        toolbarButtons: [],
        placeholderText: Translation.translate('WIDGET_TYPE_INTRO'),
    };

    /**
     * Indicates if we want to recompile the Froala WYSIWYG editor.
     *
     * @type {boolean}
     */
    vm.recompileFroala = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Translation = Translation;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Toggle drag and drop allowed for the HTML widget.
     * When it is in edit mode, disable drag'n'drop.
     */
    function _toggleDragAndDrop() {
        if (Widget.isDragDisabled()) {
            Widget.isDragDisabled(false);

            $element.parents('.component-cell').removeClass('component-cell--hover-disabled');
        } else {
            Widget.isDragDisabled(true);

            $element.parents('.component-cell').addClass('component-cell--hover-disabled');
        }
    }

    /**
     * Read the content of the editor, save it in the widget and close the WYSIWYG.
     */
    function _readContent() {
        const { inputLanguage } = Translation;

        if (
            angular.isDefined(vm.widget.properties.content) &&
            Translation.hasTranslation(vm.widget.properties.content, inputLanguage)
        ) {
            vm.widget.properties.content[inputLanguage] = Utils.stripTags(vm.widget.properties.content[inputLanguage]);
        }

        vm.editingContent = false;

        _appFilter.removeClass('app-filter--is-shown');

        $timeout(function delayEndEdition() {
             _appFilter.remove();

            _toggleDragAndDrop();
        });
    }

    /**
     * Edit the content of the Intro widget.
     */
    function _editContent() {
        $element
            .find('.widget-content__transclude')
            .css('height', $element.find('.widget-content__transclude').outerHeight());

        _toggleDragAndDrop();

        vm.editingContent = true;

        if (!Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
            _appFilter.appendTo('.app-content').bind('click', function onAppContentClick(evt) {
                evt.stopPropagation();

                $scope.$apply(function applyReadContentOnClick() {
                    _readContent();
                });
            });
        }

        $timeout(function delayDisplayFilter() {
            _appFilter.addClass('app-filter--is-shown');

            $element.find('.widget-content__transclude').removeAttr('style');
        }, _APP_FILTER_SHOW_DELAY);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Edit the content of the HTML widget in basic mode.
     */
    function editContentBasic() {
        if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
            return;
        }

        if ((Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE') || Content.isCurrentDesignerMode('SIMPLE_LEGACY')) && !vm.editingContent) {
            _editContent();
        }
    }

    /**
     * Edit the content of the HTML widget in default or simple mode.
     */
    function editContentDefaultOrSimple() {
        if (
            Content.getAction() !== 'get' &&
            (Content.getViewMode() === 'default' || Content.getViewMode() === 'simple') &&
            !vm.editingContent
        ) {
            _editContent();
        }
    }

    /**
     * Get the widget classes.
     *
     * @return {Array} The widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        widgetClass.push('widget-editable');

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        if (vm.editingContent) {
            widgetClass.push('widget-intro--is-editing');
            widgetClass.push('widget-editable--is-editing');
        }

        return widgetClass;
    }

    /**
     * Init Froala.
     */
    function initFroala() {
        vm.widget.properties = vm.widget.properties || {};
        const { properties } = vm.widget;

        const { inputLanguage } = Translation;

        properties.content = properties.content || {};
        properties.content[inputLanguage] = properties.content[inputLanguage] || '';

        set(vm.FroalaService, 'widgetUuid', vm.widget.uuid);
    }

    /**
     * Check if the widget is empty.
     * This is used in the designer.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        return (
            !vm.editingContent &&
            (angular.isUndefined(vm.widget.properties.content) ||
                !vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content))
        );
    }

    /**
     * Check if the widget is hidden.
     * This is used in read mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        if (
            !vm.parentCtrl.designerMode() &&
            (angular.isUndefined(vm.widget.properties.content) ||
                !vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content))
        ) {
            vm.parentCtrl.isHidden = true;
        } else {
            vm.parentCtrl.isHidden = false;
        }

        return vm.parentCtrl.isHidden;
    }

    /////////////////////////////

    vm.editContentBasic = editContentBasic;
    vm.editContentDefaultOrSimple = editContentDefaultOrSimple;
    vm.getWidgetClass = getWidgetClass;
    vm.initFroala = initFroala;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $scope.$on('contribution-mode', (evt, contributionMode) => {
        if (contributionMode === 'writer') {
            _editContent();
        } else {
            _readContent();
        }
    });

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * When the view mode is changed, enable/disable the char counter of Froala.
     *
     * @param {string} newViewMode Indicates the new view mode.
     */
    $scope.$watch(
        Content.getViewMode.bind(Content),
        function contentViewModeWatcher(newViewMode) {
            const previousValue = vm.froalaOptions.charCounterCount;
            vm.froalaOptions.charCounterCount = newViewMode !== 'basic';

            if (previousValue !== vm.froalaOptions.charCounterCount) {
                vm.recompileFroala = true;
                $timeout(function stopRecompilingFroala() {
                    vm.recompileFroala = false;
                });
            }
        },
        true,
    );

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        if (Utils.isDesignerMode()) {
            const FroalaService = $injector.get('FroalaService');
            vm.FroalaService = FroalaService;

            vm.froalaOptions.events = {
                'froalaEditor.image.beforePasteUpload': FroalaService.beforePasteUpload,
                'froalaEditor.image.beforeUpload': FroalaService.beforeUpload,
                'froalaEditor.initialized': FroalaService.focus,
            };
        } else {
            vm.FroalaService = {
                openContent: Utils.openContent,
                openPopin: Utils.openPopin,
            };
        }

        if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
            _editContent();
        }
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        init();
    };
}

/////////////////////////////

function WidgetIntroDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetIntroController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetIntro', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-intro/views/widget-intro.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetIntro', WidgetIntroDirective);

/////////////////////////////

export { WidgetIntroController, WidgetIntroDirective };
