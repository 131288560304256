import React, { RefObject } from 'react';

import { padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Group } from '@lumapps/groups/types';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { mdiAccountMultiple, mdiEarth } from '@lumapps/lumx/icons';
import {
    AutocompleteMultipleProps,
    FlexBox,
    Icon,
    List,
    ListItem,
    ListSubheader,
    Orientation,
    Size,
    Text,
    TextProps,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { USER } from '@lumapps/user/keys';

import { SearchGroupsStatus } from '../../ducks/slice';
import { GroupPickerFieldSkeletons } from './GroupPickerFieldSkeletons';

import './index.scss';

export interface GroupPickerFieldSearchResultsProps {
    inputRef: RefObject<HTMLDivElement> | HTMLDivElement;
    groups: Group[];
    searchGroupsStatus: SearchGroupsStatus;
    searchTerm: string;
    displayAllGroup?: boolean;
    displayPublicGroup?: boolean;
    searchInPlatform: boolean;
    onSearchRetry(): void;
    onSearchClear(): void;
    handleGroupSelected(group: Group): void;
    getGroupAfter?(group: Group): React.ReactElement | null;
    values?: AutocompleteMultipleProps['values'];
    isMultiple?: boolean;
    listClassName?: string;
    isGroupDisabled?: (group: Group) => boolean;
    groupAll?: Group;
    groupPublic?: Group;
    resultsTextProps?: Partial<TextProps>;
}

export const LIST_CLASSNAME = 'group-picker-list';

export const GroupPickerFieldSearchResults: React.FC<GroupPickerFieldSearchResultsProps> = ({
    inputRef,
    groups,
    searchGroupsStatus,
    searchTerm,
    displayAllGroup,
    displayPublicGroup,
    searchInPlatform,
    onSearchRetry,
    onSearchClear,
    handleGroupSelected,
    isGroupDisabled,
    getGroupAfter,
    values,
    isMultiple,
    listClassName = LIST_CLASSNAME,
    groupAll,
    groupPublic,
    resultsTextProps,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(listClassName);
    const valuesId = React.useMemo(() => (values ? values.map((group) => group.id) : undefined), [values]);
    const { get } = useDataAttributes('group');

    // Show loading if we are fetching backend, OR if the filtered result is empty while there is still more from backend
    const showLoading =
        searchGroupsStatus === SearchGroupsStatus.loading ||
        (groups?.length === 0 && searchGroupsStatus === SearchGroupsStatus.loadingMore);

    const displayAllAndPublicGroups = displayAllGroup || displayPublicGroup;

    /**
     * Here we make the difference between `All` and `Public` groups from the rest. These are two special groups
     * within LumApps that need to be treated differently. Props `displayPublicGroup` and `displayAllGroup` allow
     * us to hide each of them separately, in order to avoid displaying them on the picker.
     */
    const allAndPublicGroups: Group[] = [];

    if (displayPublicGroup && groupPublic) {
        allAndPublicGroups.push(groupPublic);
    }

    if (displayAllGroup && groupAll) {
        allAndPublicGroups.push(groupAll);
    }

    /**
     * We retrieve the ids for groups `public` and `all` and filter out the other groups so that they do not appear
     * on the picker twice.
     */
    const allAndPublicGroupsIds = [groupPublic, groupAll].map((group) => {
        if (group) {
            return group.uid || group.id;
        }

        return undefined;
    });

    const otherGroups = groups?.filter((group: Group) => {
        if (group.uid) {
            return !allAndPublicGroupsIds.includes(group.uid);
        }

        return true;
    });

    // Depending on the presence of All and/or Public group, we need to adjust index value to avoid
    // a de-sync between the highlighted value and the selected value when using keyboard nav
    const indexAdjustValue = allAndPublicGroups ? allAndPublicGroups.length : 0;

    // Allows the item to navigate the list using keyboard
    const { activeItemIndex, setActiveItemIndex } = List.useKeyboardListNavigation(
        displayAllAndPublicGroups ? [...allAndPublicGroups, ...otherGroups] : otherGroups,
        inputRef as any,
        handleGroupSelected,
        undefined, // onListItemNavigated
        undefined, // onEnterPressed
        undefined, // onBackspacePressed
        undefined, // keepFocusAfterSelection
        0, // initialIndex
    );

    // Render a group
    const renderGroup = (group: Group, index: number) => {
        const isSelected = isMultiple && valuesId ? valuesId.includes(group.id) : false;

        // Calculate highlight position depending on the presence of ALL and Public groups
        const highlightPosition = displayAllAndPublicGroups
            ? index === activeItemIndex
            : index === activeItemIndex + indexAdjustValue;

        return (
            <ListItem
                size={Size.tiny}
                key={group.id}
                isHighlighted={highlightPosition}
                onItemSelected={() => handleGroupSelected(group)}
                after={getGroupAfter?.(group)}
                onMouseOver={() => setActiveItemIndex(index)}
                isSelected={isSelected}
                isDisabled={isGroupDisabled ? isGroupDisabled(group) : false}
                className={element('list-item')}
                {...get({ element: 'button', action: 'select-group' })}
            >
                <FlexBox orientation={Orientation.horizontal} hAlign="center">
                    <Text as="span" {...resultsTextProps}>
                        {translateKey(group.name)}
                    </Text>
                </FlexBox>
            </ListItem>
        );
    };

    // Custom className used to allow external modification
    return (
        <List className={listClassName}>
            {/* loading Skeletons */}
            {showLoading && <GroupPickerFieldSkeletons number={3} />}
            {!showLoading && (
                <>
                    {/* Error */}
                    {searchGroupsStatus === SearchGroupsStatus.error && (
                        <ServiceNotAvailableState className={padding('vertical', 'huge')} onRetry={onSearchRetry} />
                    )}
                    {searchGroupsStatus !== SearchGroupsStatus.error && (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                            {groups?.length === 0 ? (
                                // No Results
                                <NoResultsState searchValue={searchTerm} onClear={onSearchClear} />
                            ) : (
                                <>
                                    {/* ALL and PUBLIC groups if fetched and displayed */}
                                    {displayAllAndPublicGroups && allAndPublicGroups?.length > 0 && (
                                        <>
                                            <ListSubheader>
                                                <Icon icon={mdiEarth} size={Size.xs} />
                                                {translateKey(USER.FEED_GROUP_TECHNICAL)}
                                            </ListSubheader>
                                            {allAndPublicGroups?.map((group: Group, index: number) =>
                                                renderGroup(group, index),
                                            )}
                                        </>
                                    )}
                                    <ListSubheader>
                                        <Icon icon={mdiAccountMultiple} size={Size.xs} />
                                        {translateKey(searchInPlatform ? USER.CUSTOMER_FEEDS : GLOBAL.FEEDS)}
                                    </ListSubheader>
                                    {/* site or platform groups */}
                                    {otherGroups?.map((group: Group, index: number) =>
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        renderGroup(group, index + allAndPublicGroups?.length),
                                    )}
                                    {searchGroupsStatus === SearchGroupsStatus.loadingMore && (
                                        <GroupPickerFieldSkeletons />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </List>
    );
};
