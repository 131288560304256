import React from 'react';

import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import { classnames } from '@lumapps/classnames';
import { mdiChevronDown, mdiChevronUp, mdiMenuRight } from '@lumapps/lumx/icons';
import { Icon, Offset, Popover, PopoverProps } from '@lumapps/lumx/react';
import { MovingFocusProvider } from '@lumapps/moving-focus';
import { MovingFocusOptions } from '@lumapps/moving-focus/types';
import { useResponsive } from '@lumapps/responsive';
import { TreeOpenStateContext } from '@lumapps/utils/components/TreeOpenStateContext';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { isComponentType } from '@lumapps/utils/types/isComponentType';

import { BASE_CLASSNAME, SUBMENU_OFFSET } from '../constants';
import { MenuContext, MenuContextOptions } from '../context';
import { Menu } from '../Menu';
import { MenuItem, MenuItemProps } from '../MenuItem';
import { MenuTriggerButton, MenuTriggerButtonProps } from '../MenuTriggerButton';
import { MenuSkeleton } from '../Skeleton/MenuSkeleton';
import { useSubmenuOpenState } from '../state';
import { useMenuTrigger } from './useMenuTrigger';

export interface MenuTriggerProps {
    /**
     * `Menu.TriggerButton` & `Menu` children
     */
    children: React.ReactElement[];
    /**
     * Additional popover props
     */
    popoverProps?: Partial<PopoverProps>;
    /**
     * The width the popovers must take.
     */
    popoverWidth?: {
        width?: React.CSSProperties['width'];
        minWidth?: React.CSSProperties['minWidth'];
        maxWidth?: React.CSSProperties['maxWidth'];
    };
    /**
     * Loading state. Puts the trigger button in loading mode and blocks the opening of the menu.
     */
    isLoading?: boolean;
    /**
     * Loading message (required when isLoading is `true`).
     */
    loadingMessage?: string;
    /**
     * Callback on open/close toggle.
     */
    onToggle?(isOpen: boolean): void;
    /**
     *
     */
    movingFocusOptions?: Partial<MovingFocusOptions>;
    /**
     * Override the menu open state
     * **Warning**: You need to update this with the `onToggle` to keep the close on blur, close on escape, etc.
     */
    isOpen?: boolean;
}

/**
 * Menu trigger attaching the trigger button with the menu popup.
 *
 * @family Menus
 */
export const MenuTrigger = (props: MenuTriggerProps) => {
    const {
        children,
        popoverProps,
        onToggle,
        loadingMessage,
        isLoading: propIsLoading,
        popoverWidth,
        movingFocusOptions = {},
        isOpen,
    } = props;

    const { direction = 'vertical' } = movingFocusOptions;
    const [triggerChild, menuChild] = React.Children.toArray(children);

    const triggerRef = React.useRef<HTMLElement>(null) as React.MutableRefObject<HTMLElement>;
    const menuRef = React.useRef<HTMLElement>(null) as React.MutableRefObject<HTMLElement>;

    const hasMenu = isComponentType(Menu, menuChild);
    const hasMenuSkeleton = isComponentType(MenuSkeleton, menuChild);
    const menu = hasMenu || hasMenuSkeleton ? menuChild : undefined;
    const originalMenuId = menu?.props?.id;
    const menuId = React.useMemo(() => originalMenuId || uniqueId(BASE_CLASSNAME), [originalMenuId]);

    const trigger = isComponentType(MenuTriggerButton, triggerChild)
        ? (triggerChild as React.ReactElement<MenuTriggerButtonProps>)
        : undefined;
    const originalTriggerId = trigger?.props?.id;
    const triggerId = React.useMemo(
        () => originalTriggerId || uniqueId(`${BASE_CLASSNAME}-trigger`),
        [originalTriggerId],
    );
    const isSubMenu = trigger?.props.as === MenuItem;

    const context = React.useContext(MenuContext);
    const isHorizontal = context?.orientation === 'horizontal';

    // Mode replacing popup menu for submenus on small screens
    const { isSmall } = useResponsive();
    const isDisclosureSubMenu = !isHorizontal && isSubMenu && isSmall;

    // Menu is loading if `isLoading` prop is used or if the menu children is a `Menu.Skeleton`.
    const isLoading = propIsLoading || hasMenuSkeleton;

    const openState = useSubmenuOpenState(menuId, onToggle);

    React.useEffect(() => {
        if (isOpen === true) {
            openState.open();
        }
        if (isOpen === false) {
            openState.close();
        }
        // Do nothing when isOpen is undefined (keep the local state)
    }, [openState, isOpen]);

    const closeRoot: MenuContextOptions['closeRoot'] = React.useMemo(() => {
        if (context?.closeRoot) {
            return context?.closeRoot;
        }
        return (focusTrigger) => {
            openState.close();
            if (focusTrigger) {
                triggerRef.current?.focus();
            }
        };
    }, [context?.closeRoot, openState]);

    const subContext = React.useMemo(
        (): MenuContextOptions => ({
            ...context,
            // Loading state
            isLoading,
            // Submenus are always vertical
            orientation: 'vertical',
            // Define callback to close the root/topmost menu
            closeRoot,
            // Not root menu
            isRoot: context?.isRoot === undefined,
            // Reset menubarVariant
            menubarVariant: undefined,
        }),
        [closeRoot, context, isLoading],
    );

    // Adapt offset on submenu to align menu items across menus
    const subMenuOffset = isHorizontal
        ? popoverProps?.offset
        : {
              along: (popoverProps?.offset?.along || 0) + SUBMENU_OFFSET.along,
              away: (popoverProps?.offset?.away || 0) + SUBMENU_OFFSET.away,
          };
    const offset: Offset | undefined = isSubMenu ? subMenuOffset : popoverProps?.offset;

    const menuPopupWidth = React.useMemo(() => {
        let popupWidthStyle = {};
        if (popoverWidth?.minWidth || popoverWidth?.maxWidth) {
            popupWidthStyle = { minWidth: popoverWidth?.minWidth, maxWidth: popoverWidth?.maxWidth };
        }
        if (popoverWidth?.width) {
            popupWidthStyle = { width: popoverWidth?.width };
        }
        return popupWidthStyle;
    }, [popoverWidth?.maxWidth, popoverWidth?.minWidth, popoverWidth?.width]);

    const { autofocus, triggerEventProps, menuEventProps, submenuOpenState } = useMenuTrigger(
        menuId,
        triggerRef,
        trigger?.props,
        menuRef,
        openState,
        context,
        subContext,
    );

    const subMenu =
        (hasMenu || hasMenuSkeleton) && openState.isOpen ? (
            <TreeOpenStateContext.Provider value={submenuOpenState}>
                <MenuContext.Provider value={subContext}>
                    {isLoading ? (
                        <MenuSkeleton
                            loadingMessage={loadingMessage}
                            {...(hasMenuSkeleton ? menu?.props : undefined)}
                        />
                    ) : (
                        <>
                            {!isDisclosureSubMenu && menu && (
                                <MovingFocusProvider options={{ direction, loopAround: true, autofocus }}>
                                    <Menu
                                        {...menu?.props}
                                        ref={mergeRefs([(menu as any).ref, menuRef])}
                                        aria-labelledby={triggerId}
                                        id={menuId}
                                        {...menuEventProps}
                                    />
                                </MovingFocusProvider>
                            )}
                            {isDisclosureSubMenu && menu && (
                                // TODO: animate open/close
                                // eslint-disable-next-line lumapps/no-classname-strings
                                <ul role="none" className={`${BASE_CLASSNAME}-disclosure`}>
                                    {menu?.props.children}
                                </ul>
                            )}
                        </>
                    )}
                </MenuContext.Provider>
            </TreeOpenStateContext.Provider>
        ) : null;

    const fitToAnchorWidth = (!isSubMenu || isHorizontal) && isEmpty(menuPopupWidth);
    const subMenuPopup = subMenu && openState.isOpen && (
        <Popover
            role="none"
            fitToAnchorWidth={fitToAnchorWidth}
            {...popoverProps}
            style={menuPopupWidth}
            className={classnames(popoverProps?.className, `${BASE_CLASSNAME}-popover`, {
                [`${BASE_CLASSNAME}-popover--default-width`]: isEmpty(menuPopupWidth),
            })}
            offset={offset}
            placement={popoverProps?.placement || (isSubMenu && !isHorizontal ? 'right-start' : 'bottom-start')}
            isOpen={openState.isOpen}
            anchorRef={popoverProps?.anchorRef || triggerRef}
            fitWithinViewportHeight
            // Managed internally (see useMenuTrigger)
            focusAnchorOnClose={false}
            // Managed internally (see useMenuTrigger)
            closeOnClickAway={false}
            // Managed internally (see useMenuTrigger), needed to stop the propagation of the escape key event
            onClose={noop}
        >
            {subMenu}
        </Popover>
    );

    // Mark trigger button as busy and close menu on blur
    const loadingProps = isLoading ? { 'aria-busy': true, onBlur: openState.close } : undefined;

    let subMenuProps: Partial<MenuItemProps> | undefined;
    if (isSubMenu) {
        const disclosureSubMenuIcon = openState.isOpen ? mdiChevronUp : mdiChevronDown;
        const subMenuIcon = isHorizontal ? mdiChevronDown : mdiMenuRight;

        subMenuProps = {
            after: (
                <>
                    <Icon icon={isDisclosureSubMenu ? disclosureSubMenuIcon : subMenuIcon} size="xs" />
                    {!isDisclosureSubMenu && subMenuPopup}
                </>
            ),
            afterItem: isDisclosureSubMenu && subMenu,
        };
    }

    if (!trigger) {
        return null;
    }

    const className = classnames(trigger.props.className, subMenuProps?.className);

    return (
        <>
            <MenuTriggerButton
                {...trigger.props}
                ref={mergeRefs([(trigger as any).ref, triggerRef])}
                id={triggerId}
                {...triggerEventProps}
                aria-haspopup="true"
                aria-expanded={(!isLoading && openState.isOpen) || undefined}
                aria-controls={openState.isOpen ? menuId : undefined}
                data-menu-id={menuId}
                {...loadingProps}
                {...subMenuProps}
                className={className}
            >
                {trigger.props.children}
            </MenuTriggerButton>
            {!isSubMenu && subMenuPopup}
        </>
    );
};
