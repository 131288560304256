import React from 'react';

import {
    FlexBox,
    FlexHorizontalAlignment,
    FlexVerticalAlignment,
    GlobalSize,
    Size,
    SkeletonCircle,
    SkeletonRectangle,
    SkeletonTypography,
    Theme,
} from '@lumapps/lumx/react';

import {
    THUMBNAIL_DEFAULT_SIZE,
    TITLE_TYPOGRAPHY,
    ATTRIBUTE_TYPOGRAPHY,
    EXCERPT_TYPOGRAPHY,
    IMAGE_SHAPE,
} from '../../constants';
import { GenericEntityBlock } from '../GenericEntityBlock';

export interface GenericEntityBlockSkeletonProps {
    /** theme to use */
    theme?: Theme;
    /** size of the thumbnail, should match the size of the block image */
    thumbnailSize?: GlobalSize;
    /** whether the block has an image or not */
    hasImage?: boolean;
    /** whether the block uses the metadata prop or not */
    hasMetadata?: boolean;
    /** whether the block uses the excerpt prop or not */
    hasExcerpt?: boolean;
    /** whether the block uses the additional information prop or not */
    hasAdditionalInformation?: boolean;
    /** total amount of inline actions displayed. */
    totalInlineActions?: number;
    /** whether the block uses the authorship prop or not */
    hasAuthorship?: boolean;
    /** custom classname */
    className?: string;
    /** Allow to select circle or rectangle shape for thumbnail image skeleton */
    imageShape?: IMAGE_SHAPE;
    /** Adjust vertical aligment of skeletons */
    hAlign?: FlexVerticalAlignment;
    /** Adjust horizontal aligment of skeletons */
    vAlign?: FlexHorizontalAlignment;
    /** width in percentage of the title's skeleton */
    titleSkeletonWidth?: string;
    /** widths in percentages of the attribute's skeleton widths */
    attributeSkeletonWidths?: string[];
    /** widths in percentages of the attribute's skeleton widths */
    excerptSkeletonWidths?: string[];
}

const TYPOGRAPHY_WIDTH = '60%';
const ATTRIBUTE_WIDTHS = ['35%', '45%', '35%'];
const EXCERPT_WIDTHS = ['30%', '40%'];

/**
 * Skeleton to be displayed for the Generic Entity Block.
 *
 * @family Blocks
 * @param GenericEntityBlockSkeletonProps
 * @returns GenericEntityBlockSkeleton
 */
export const GenericEntityBlockSkeleton: React.FC<GenericEntityBlockSkeletonProps> = ({
    theme = 'light',
    hasImage = false,
    hasMetadata = false,
    hasExcerpt = false,
    hasAuthorship = false,
    hasAdditionalInformation = false,
    totalInlineActions = 0,
    thumbnailSize = THUMBNAIL_DEFAULT_SIZE,
    imageShape = IMAGE_SHAPE.RECTANGLE,
    hAlign = undefined,
    vAlign = undefined,
    className,
    titleSkeletonWidth = TYPOGRAPHY_WIDTH,
    attributeSkeletonWidths = ATTRIBUTE_WIDTHS,
    excerptSkeletonWidths = EXCERPT_WIDTHS,
}) => {
    return (
        <GenericEntityBlock
            hAlign={hAlign}
            vAlign={vAlign}
            className={className}
            title={<SkeletonTypography theme={theme} typography={TITLE_TYPOGRAPHY} width={titleSkeletonWidth} />}
            titleProps={{
                style: {
                    width: '100%',
                },
            }}
            customImage={
                hasImage ? (
                    <>
                        {imageShape === IMAGE_SHAPE.RECTANGLE && (
                            <SkeletonRectangle
                                theme={theme}
                                width={thumbnailSize}
                                height={thumbnailSize}
                                variant="rounded"
                            />
                        )}
                        {imageShape === IMAGE_SHAPE.CIRCLE && <SkeletonCircle theme={theme} size={thumbnailSize} />}
                    </>
                ) : null
            }
            metadata={
                hasMetadata ? (
                    <SkeletonTypography
                        theme={theme}
                        typography={ATTRIBUTE_TYPOGRAPHY}
                        width={attributeSkeletonWidths[0]}
                    />
                ) : null
            }
            customExcerpt={
                hasExcerpt ? (
                    <>
                        <SkeletonTypography
                            theme={theme}
                            typography={EXCERPT_TYPOGRAPHY}
                            width={excerptSkeletonWidths[0]}
                        />
                        <SkeletonTypography
                            theme={theme}
                            typography={EXCERPT_TYPOGRAPHY}
                            width={excerptSkeletonWidths[1]}
                        />
                    </>
                ) : null
            }
            additionalInfo={
                hasAdditionalInformation ? (
                    <SkeletonTypography
                        theme={theme}
                        typography={ATTRIBUTE_TYPOGRAPHY}
                        width={attributeSkeletonWidths[1]}
                    />
                ) : null
            }
            authorship={
                hasAuthorship ? (
                    <SkeletonTypography
                        theme={theme}
                        typography={ATTRIBUTE_TYPOGRAPHY}
                        width={attributeSkeletonWidths[2]}
                    />
                ) : null
            }
            customActions={
                totalInlineActions > 0 ? (
                    <FlexBox orientation="horizontal" hAlign="center" gap="big">
                        {Array.from(
                            {
                                length: totalInlineActions,
                            },
                            // eslint-disable-next-line react/no-unstable-nested-components
                            (v, i) => (
                                <SkeletonCircle theme={theme} key={i} size={Size.s} />
                            ),
                        )}
                    </FlexBox>
                ) : null
            }
        />
    );
};

export { IMAGE_SHAPE };
