import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { mdiCellphone, mdiLaptop } from '@lumapps/lumx/icons';
import { useUploadVideo } from '@lumapps/play-video-library/hooks/useUploadVideo';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { VideoUploadDialog } from '../../components/VideoUploadDialog';
import { isPlayCommunitiesFeatureEnabled } from '../../ducks/selectors';
import { COMMUNITIES } from '../../keys';
import type { CommunityVideo } from '../../types';

export interface UseVideoUploadButtonProps {
    onInsert(video: CommunityVideo): void;
    uploadType?: 'employee' | 'corporate';
}

export const useVideoUploadButton = ({ onInsert, uploadType }: UseVideoUploadButtonProps) => {
    const { isMobile } = useResponsive();

    const isPlayCommunitiesEnabled = useSelector(isPlayCommunitiesFeatureEnabled);
    const isPlayEnabled = useSelector(isPlayVideoEnabled);

    const [isUploadDialogOpen, , closeUploadDialog, openUploadDialog] = useBooleanState(false);
    const [videoFile, setVideoFile] = React.useState<File>();

    // ⚠️ It is mandatory to memoized this function otherwise we will get abusive rerender
    const onUpload = React.useCallback(
        (file) => {
            setVideoFile(file);
            openUploadDialog();
        },
        [openUploadDialog],
    );

    const { openPicker, hiddenInput: UploadInput } = useUploadVideo({ onUpload, inputId: uniqueId('video') });

    const uploadButtonProps = React.useMemo(
        () => ({
            icon: isMobile ? mdiCellphone : mdiLaptop,
            label: isMobile ? COMMUNITIES.UPLOAD_VIDEO_FROM_DEVICE : COMMUNITIES.UPLOAD_VIDEO_FROM_COMPUTER,
            onSelected: openPicker,
        }),
        [isMobile, openPicker],
    );

    const handleSubmit = React.useCallback(
        (video: CommunityVideo) => {
            onInsert(video);
            closeUploadDialog();
        },
        [closeUploadDialog, onInsert],
    );

    const UploadDialog = React.useMemo(
        () =>
            videoFile ? (
                <VideoUploadDialog
                    isOpen={isUploadDialogOpen}
                    videoFile={videoFile}
                    onCancel={closeUploadDialog}
                    onSubmit={handleSubmit}
                    uploadType={uploadType}
                />
            ) : null,
        [closeUploadDialog, handleSubmit, isUploadDialogOpen, uploadType, videoFile],
    );

    return {
        ...(isPlayEnabled &&
            isPlayCommunitiesEnabled && { uploadButtonProps, UploadDialog, UploadInput, isUploadDialogOpen }),
    };
};
