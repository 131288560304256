// eslint-disable-next-line lumapps/do-not-import-classnames
import classnames from 'classnames';
import { ClassValue } from 'classnames/types';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import mapKeys from 'lodash/mapKeys';

import { ColorPalette, ColorVariant, Size, Typography } from '@lumapps/lumx/react';

import { Direction, Spacing } from './types';

/**
 * Returns a lumx classname for the given type, direction and size. For example, for
 * arguments type='padding', direction='right', size='regular' it returns lumx-spacing-padding-right-regular
 * @param type - margin or padding
 * @param direction - Direction
 * @param size - Size
 * @returns string
 */
export const spacing = (type: Spacing, direction?: Direction, size?: Size | null) => {
    let baseClass = `lumx-spacing-${type}`;

    if (direction && direction !== 'all') {
        baseClass = `${baseClass}-${direction}`;
    }

    if (size) {
        baseClass = `${baseClass}-${size}`;
    } else if (size === null) {
        baseClass = `${baseClass}-none`;
    }

    return baseClass;
};

/**
 * Returns a list of lumx classnames for the given types, directions and sizes. For example, for
 * arguments [
 *  { type: 'padding', direction: 'right', size: 'regular'},
 *  { type: 'margin', direction: 'left', size: 'big'},
 * ]
 * it returns lumx-spacing-padding-right-regular lumx-spacing-margin-left-big
 * @param type - margin or padding
 * @param direction - Direction
 * @param size - Size
 * @returns string
 */
export const spacings = (spacings: { type: Spacing; direction?: Direction; size?: Size | null }[]) => {
    return classnames(spacings.map((spa) => spacing(spa.type, spa.direction, spa.size)));
};

/**
 * Returns a lumx margin classname for the given direction and size. For example, for
 * arguments direction='right', size='regular' it returns lumx-spacing-margin-right-regular
 * @param direction - Direction
 * @param size - Size
 * @returns string
 */
export const margin = (direction?: Direction, size?: Size | null) => spacing('margin', direction, size);

/**
 * Returns a list of lumx margin classnames for the given directions and sizes. For example, for
 * arguments [
 *  { direction: 'right', size: 'regular'},
 *  { direction: 'left', size: 'big'},
 * ]
 * it returns lumx-spacing-margin-right-regular lumx-spacing-margin-left-big
 * @param direction - Direction
 * @param size - Size
 * @returns string
 */
export const margins = (margins: { direction?: Direction; size?: Size | null }[]) =>
    spacings(margins.map((margin) => ({ ...margin, type: 'margin' })));

/**
 * Returns a lumx padding classname for the given direction and size. For example, for
 * arguments direction='right', size='regular' it returns lumx-spacing-padding-right-regular
 * @param direction - Direction
 * @param size - Size
 * @returns string
 */
export const padding = (direction?: Direction, size?: Size | null) => spacing('padding', direction, size);

/**
 * Returns a list of lumx margin classnames for the given directions and sizes. For example, for
 * arguments [
 *  { direction: 'right', size: 'regular'},
 *  { direction: 'left', size: 'big'},
 * ]
 * it returns lumx-spacing-padding-right-regular lumx-spacing-padding-left-big
 * @param direction - Direction
 * @param size - Size
 * @returns string
 */
export const paddings = (paddings: { direction?: Direction; size?: Size | null }[]) =>
    spacings(paddings.map((padding) => ({ ...padding, type: 'padding' })));

/**
 * Returns the classname associated to the given typography. For example, for Typography.title it returns
 * lumx-typography-title
 * @param typo - Typography
 * @returns string
 */
export const typography = (typo: Typography) => {
    return `lumx-typography-${typo}`;
};

/**
 * Returns the classname associated to the given size. For example, for Size.xs it returns
 * lumx-size-xs
 * @param size - Size
 * @returns string
 */
export const sizing = (size: Size) => {
    return `lumx-size-${size}`;
};

/**
 * Returns the classname associated to the given type, color and variant. For example, for 'font',
 * 'dark' and 'L2' it returns lumx-color-font-dark-l2
 * @param type - font or background
 * @param col - ColorPalette
 * @param variant - ColorVariant
 * @returns string
 */
export const color = (type: 'font' | 'background', col: ColorPalette, variant: ColorVariant) => {
    // eslint-disable-next-line lumapps/no-ds-classes
    return `lumx-color-${type}-${col}-${variant}`;
};

/**
 * Returns the font classname associated to the given color and variant. For example, for
 * 'dark' and 'L2' it returns lumx-color-font-dark-l2
 * @param col - ColorPalette
 * @param variant - ColorVariant
 * @returns string
 */
export const font = (col: ColorPalette, variant: ColorVariant) => {
    return color('font', col, variant);
};

/**
 * Returns the classname in order to visually hide an element.
 * @returns string
 */
export const visuallyHidden = () => {
    return 'visually-hidden';
};

/**
 * Returns the classname in order to add a separator to an element
 * @returns string
 */
export const separator = () => {
    return 'lumx-separator';
};

/**
 * Returns the background classname associated to the given color and variant. For example, for
 * 'dark' and 'L2' it returns lumx-color-background-dark-l2
 * @param col - ColorPalette
 * @param variant - ColorVariant
 * @returns string
 */
export const background = (col: ColorPalette, variant: ColorVariant) => {
    return color('background', col, variant);
};

type Modifier = string | Record<string, boolean> | ClassValue[];

const parseModifier = (baseClass: string, modifier: Modifier) => {
    const classes = [];

    if (isString(modifier)) {
        classes.push(`${baseClass}--${modifier}`);
    } else if (isArray(modifier)) {
        return modifier;
    } else if (isObject(modifier)) {
        classes.push(
            mapKeys(modifier, (_value, key) => {
                return `${baseClass}--${key}`;
            }),
        );
    }

    return classes;
};

const generateBEMClass = (
    baseClass: string,
    modifier?: Modifier,
    additionalClasses?: ClassValue | ClassValue[],
    modifierLast?: boolean,
) => {
    if (!modifier && !additionalClasses) {
        return baseClass;
    }

    if (modifierLast) {
        return classnames(baseClass, additionalClasses, modifier && parseModifier(baseClass, modifier));
    }

    return classnames(baseClass, modifier && parseModifier(baseClass, modifier), additionalClasses);
};

/**
 * Returns the BEM class for the given block (and modifier if it is defined)
 * @param baseClass - string
 * @returns string
 */
export const block =
    (baseClass: string) =>
    (modifier?: Modifier, additionalClasses?: ClassValue | ClassValue[], modifierLast?: boolean) => {
        return generateBEMClass(baseClass, modifier, additionalClasses, modifierLast);
    };

/**
 * Returns the BEM class for the given element (and modifier if it is defined)
 * @param baseClass - string
 * @returns string
 */
export const element =
    (baseClass: string) =>
    (elem: string, modifier?: Modifier, additionalClasses?: ClassValue | ClassValue[], modifierLast?: boolean) => {
        const elementClassname = `${baseClass}__${elem}`;

        return generateBEMClass(elementClassname, modifier, additionalClasses, modifierLast);
    };
