import React from 'react';

import { BaseApiError } from '@lumapps/base-api';
import { Divider } from '@lumapps/lumx/react';
import { VideoPickerDialog } from '@lumapps/play-pickers/components/VideoPickerDialog';
import { UploadingVideoFormDialog } from '@lumapps/play-video-library/components/UploadingVideoFormDialog';
import { UploadVideoDialog } from '@lumapps/play-video-library/components/UploadVideoDialog';
import { useCreateVideo } from '@lumapps/play-video-library/hooks/useCreateVideo/useCreateVideo';
import { useGoogleDriveUpload } from '@lumapps/play-video-library/hooks/useGoogleDriveUpload';
import type { GetVideoResult, Video } from '@lumapps/play/api/types';
import {
    useFetchUniqueVideo,
    WIDGET_PLAY_SETTINGS_FIELDS,
} from '@lumapps/play/hooks/useFetchUniqueVideo/useFetchUniqueVideo';
import { PLAY } from '@lumapps/play/keys';
import { FileUploadFromDrive } from '@lumapps/play/types';
import { arrayFromObjectOfEnabledValues } from '@lumapps/play/utils';
import { GLOBAL } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import type { WidgetPlayPropertiesType } from '../../types';
import { getVideoId } from '../../utils';
import { WidgetPlayUploader } from '../WidgetPlayUploader';
import { MetadataSelection } from './MetadataSelection';

import './index.scss';

export interface WidgetPlaySettingsProps {
    properties?: WidgetPlayPropertiesType;
    onChange: (properties: WidgetPlayPropertiesType) => void;
}

const CLASSNAME = 'widget-play-settings';

export const WidgetPlaySettings: React.FC<WidgetPlaySettingsProps> = ({ properties, onChange }) => {
    const videoId = properties && getVideoId(properties);

    const [pickerDialogIsOpen, togglePickerDialog] = useBooleanState(false);
    const [isUploadingDialogOpen, , closeUploadingDialog, openUploadingDialog] = useBooleanState(false);
    const [isUploadDialogOpen, , closeUploadDialog, openUploadDialog] = useBooleanState(false);
    const [createVideoError, setCreateVideoError] = React.useState<string>();
    const [videoFile, setVideoFile] = React.useState<File>();
    const [videoFromDrive, setVideoFromDrive] = React.useState<FileUploadFromDrive>();

    const clearFileData = () => {
        setVideoFile(undefined);
        setVideoFromDrive(undefined);
    };

    const onCreateVideoSuccess = () => {
        closeUploadDialog();
        openUploadingDialog();
        setCreateVideoError(undefined);
    };

    const onCreateVideoError = (error: BaseApiError) => {
        if (error.response?.status === 429) {
            setCreateVideoError(PLAY.UPLOAD_DIALOG_CONSUMPTION_WARNING);
        } else {
            setCreateVideoError(GLOBAL.GENERIC_ERROR);
        }
    };

    const { createdVideo, upload } = useCreateVideo({
        videoFile,
        videoFromDrive,
        isHiddenIn: { content: false, gallery: true, search: false },
        defaultStatus: 'published',
        clearFileData,
        onSuccess: onCreateVideoSuccess,
        onError: onCreateVideoError,
    });

    /**
     * /!\ We need to keep the 'video.id' format to ensure compatibility
     * with the mobile team.
     * For now, we keep 'properties.videoId' as a fallback to ensure the availability
     * of the widgets created recently on the web, until the data format of all
     * widgets are manually corrected
     */

    const { videoLoading, video } = useFetchUniqueVideo({ videoId, fields: WIDGET_PLAY_SETTINGS_FIELDS });
    const [savedVideo, setSavedVideo] = React.useState<GetVideoResult>(video);

    const handleSelectionConfirm = (videos: Video[] | undefined) => {
        if (videos) {
            const [selectedVideo] = videos;
            onChange({
                video: {
                    id: selectedVideo?.id,
                },
            });

            togglePickerDialog();
        }
    };

    React.useEffect(() => {
        setSavedVideo(video);
    }, [video]);

    const resetVideo = () => {
        setSavedVideo(undefined);

        onChange({
            // reset the widget's videoId as well until we are sure that all widgets
            // were reverted back to video.id
            video: undefined,
            videoId: undefined,
            order: undefined,
        });
    };

    /**
     * If the widget was created before the metadata selection feature was shipped
     * we set each keys to true so all metadata are enabled by default
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const visibleMetadata = {
        title: properties?.order?.includes('title') ?? true,
        author: properties?.order?.includes('author') ?? true,
        publicationDate: properties?.order?.includes('publicationDate') ?? true,
        views: properties?.order?.includes('views') ?? true,
        description: properties?.order?.includes('description') ?? true,
    };

    const handleSaveUploadVideo = (videoId?: string) => {
        onChange({
            video: {
                id: videoId,
            },
        });
        setVideoFromDrive(undefined);
        closeUploadingDialog();
    };

    // Video upload with Google Drive
    const { isGoogleUser, openGoogleDrivePicker } = useGoogleDriveUpload({
        onFilesSelected: (files) => {
            setVideoFromDrive(files[0]);
        },
    });

    const handleDiscardUploadDialog = () => {
        closeUploadDialog();
        setVideoFromDrive(undefined);
    };

    React.useEffect(() => {
        /**
         * If the widget was created before the metadata selection feature was shipped,
         * when the user picks a video, we call the onChange function with all metadata enabled
         * so the backend can register them properly.
         */
        if (savedVideo && properties?.order === undefined) {
            onChange({ order: arrayFromObjectOfEnabledValues(visibleMetadata) });
        }
    }, [onChange, properties, savedVideo, visibleMetadata]);

    return (
        <>
            <div className={CLASSNAME}>
                <WidgetPlayUploader
                    isGoogleUser={isGoogleUser}
                    loading={videoLoading}
                    openDrivePickerDialog={openGoogleDrivePicker}
                    openPickerDialog={togglePickerDialog}
                    openUploadDialog={openUploadDialog}
                    resetVideo={resetVideo}
                    video={savedVideo}
                />
                <Divider />
                <MetadataSelection onChange={onChange} visibleMetadata={visibleMetadata} videoLoading={videoLoading} />
            </div>

            <VideoPickerDialog
                isOpen={pickerDialogIsOpen}
                onSelectionCancel={togglePickerDialog}
                onSelectionConfirm={handleSelectionConfirm}
                shouldPickScheduled
            />

            <UploadingVideoFormDialog
                isOpen={isUploadingDialogOpen}
                onClose={() => {
                    closeUploadingDialog();
                    setVideoFromDrive(undefined);
                    clearFileData();
                }}
                onSave={handleSaveUploadVideo}
                videoFile={videoFile}
                video={createdVideo}
                upload={upload}
            />

            <UploadVideoDialog
                isOpen={isUploadDialogOpen}
                onCancel={handleDiscardUploadDialog}
                onDrop={setVideoFile}
                createVideoError={createVideoError}
            />
        </>
    );
};
