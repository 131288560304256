import { Route } from '@lumapps/router';
import { AppId, PAGE_MIGRATION_URL_PREFIX } from '@lumapps/router/constants';

export const rolesRoutes: Route = {
    path: '/admin/role',
    legacyId: 'app.admin.role',
    appId: AppId.legacy,
};

export const rolesBaseRoutes: Route = {
    path: `/admin/${PAGE_MIGRATION_URL_PREFIX}/roles`,
    legacyId: 'app.admin.role-base',
    appId: AppId.backOffice,
};

export const rolesListRoutes: Route = {
    path: `${rolesBaseRoutes.path}`,
    legacyId: 'app.admin.role-list',
    appId: AppId.backOffice,
};

export const rolesEditRoutes: Route = {
    path: `${rolesBaseRoutes.path}/:id/edit`,
    legacyId: 'app.admin.role-edit',
    appId: AppId.backOffice,
};

export const getEditRoute = (id: string) => {
    return {
        ...rolesEditRoutes,
        params: {
            id,
        },
    };
};

export const rolesCreateRoutes: Route = {
    path: `${rolesBaseRoutes.path}/create`,
    legacyId: 'app.admin.role-create',
    appId: AppId.backOffice,
};

export const roles = (): Route => rolesRoutes;
